<template>
    <div>
        <vol-box v-model="showDeptPosition" :lazy="true" title="选择部门下对应职位" :height="640" :width="450" :padding="15">
            <SelectDeptPosition ref="selectDeptPosition" @DeptPositionCallBack="deptPositionCallBack">
            </SelectDeptPosition>
        </vol-box>

        <SelectLeaveApply v-model="showLeaveApply" ref="selectLeaveApply" @LeaveApplyCallBack="leaveApplyCallBack"
            @CloseLeveApplyBox="closeLeveApplyBox"></SelectLeaveApply>

        <SelectEmp v-model="showEmp" ref="selectEmp" @EmpCallBack="empCallBack" @CloseEmpBox="closeEmpBox"></SelectEmp>

        <SelectEmpPlus v-model="showEmpPlus" ref="selectEmpPlus" @EmpCallBack="empPlusCallBack" :="componentProps"
            @CloseEmpBox="closeEmpBoxPlus"></SelectEmpPlus>

        <SelectPositionLevel v-model="showPositionLevel" ref="selectPositionLevel"
            @PositionLevelCallBack="positionLevelCallBack" @ClosePositionLevelBox="closePositionLevelBox">
        </SelectPositionLevel>

        <SelectTacking v-model="showTacking" ref="SelectTacking" @TackingCallBack="TackingCallBack"
            @CloseTackingBox="closeTackingBox"></SelectTacking>

        <SelectMeetingRoom v-model="showMeetingRoom" ref="selectMeetingRoom" @MeetingRoomCallBack="meetingRoomCallBack"
            @CloseMeetingRoomBox="closeMeetingRoomBox"></SelectMeetingRoom>
        <vol-box v-model="showDepartment" :lazy="true" title="选择部门" :height="640" :width="450" :padding="15">
            <SelectDepartment ref="selectDepartment" @DepartmentCallBack="departmentCallBack"></SelectDepartment>
        </vol-box>
        <VolUploadFile v-model="showfileUpload" ref="fileUploadDetails" @fileUploadCallBack="fileUploadCallBack"
            @closefileUploadBox="closefileUploadBox"></VolUploadFile>

        <SelectAsset v-model="showAsset" ref="selectAsset" @AssetCallBack="assetCallBack"
            @CloseAssetBox="closeAssetBox"></SelectAsset>
        <SelectContract v-model="showContract" ref="SelectContract" @ContractCallBack="ContractCallBack"
            @CloseContractBox="closeContractBox"></SelectContract>
        <SelectContractComp v-model="showContractComp" ref="SelectContractComp"
            @ContractCompCallBack="ContractCompCallBack" @CloseContractCompBox="closeContractCompBox">
        </SelectContractComp>
        <SelectTrainCourse v-model="showTrainCourse" ref="SelectTrainCourse" @CourseCallBack="CourseCallBack"
            @CloseCourseBox="CloseCourseBox"></SelectTrainCourse>

        <SelectTrainPlanCourse v-model="showTrainPlanCourse" ref="SelectTrainPlanCourse"
            @PlanCourseCallBack="PlanCourseCallBack" @ClosePlanCourseBox="ClosePlanCourseBox"></SelectTrainPlanCourse>

        <SelectTrainPlan v-model="showTrainPlan" ref="SelectTrainPlan" @TrainPlanCallBack="TrainPlanCallBack"
            @CloseTrainPlanBox="CloseTrainPlanBox"></SelectTrainPlan>

        <SelectEmergencyPlan v-model="showEmergencyPlan" ref="SelectEmergencyPlan"
            @EmergencyPlanCallBack="EmergencyPlanCallBack" @CloseEmergencyPlanBox="CloseEmergencyPlanBox">
        </SelectEmergencyPlan>

        <vol-box :lazy="true" v-model="showUpload" title="附件上传" :height="200" :width="300" :padding="15"
            :onModelClose="fileModelClose">
            <VolUpload url="/api/OA_DocumentFile/upload" :upload-after="fileUploadAfter" :on-change="fileChange"
                :autoUpload="true" :multiple="true" :fileInfo="uploadFileInfo" :remove-before="fileRemoveBefore">
            </VolUpload>
        </vol-box>

        <SelectSpotCheckArea v-model="showSpotCheckArea" ref="SelectSpotCheckArea"
            @SpotCheckAreaCallBack="SpotCheckAreaCallBack" @CloseSpotCheckAreaBox="CloseSpotCheckAreaBox">>
        </SelectSpotCheckArea>

        <SelectDocumentFile v-model="showDocumentFile" ref="SelectDocumentFile"
            @DocumentFileCallBack="documentFileCallBack" @CloseDocumentFileBox="closeDocumentFileBox">
        </SelectDocumentFile>
        <SelectMatInfo v-model="showMatInfo" ref="SelectMatInfo" @MatInfoCallBack="MatInfoCallBack"
            @CloseMatInfoBox="closeMatInfoBox"></SelectMatInfo>
        <SelectSupplier v-model="showSupplier" ref="SelectSupplier" @SupplierCallBack="SupplierCallBack"
            @CloseSupplierBox="closeSupplierBox"></SelectSupplier>
        <SelectPurApply v-model="showPurApply" ref="SelectPurApply" @PurApplyCallBack="PurApplyCallBack"
            @ClosePurApplyBox="closePurApplyBox"></SelectPurApply>
        <SelectReEx v-model="showReEx" ref="SelectReEx" @ReExCallBack="ReExCallBack" @CloseReExBox="closeReExBox">
        </SelectReEx>
        <SelectStoInspection v-model="showStoInspection" ref="SelectStoInspection"
            @StoInspectionCallBack="StoInspectionCallBack" @CloseStoInspectionBox="closeStoInspectionBox">
        </SelectStoInspection>
        <SelectMatStock v-model="showMatStock" ref="SelectMatStock" @MatStockCallBack="MatStockCallBack"
            @CloseMatStockBox="closeMatStockBox" :width="900"></SelectMatStock>
        <SelectStockOut v-model="showStockOut" ref="SelectStockOut" @StockOutCallBack="StockOutCallBack"
            @CloseStockOutBox="closeStockOutBox"></SelectStockOut>
        <SelectMatStockDetail v-model="showMatStockDetail" ref="SelectMatStockDetail"
            @MatStockDetailCallBack="MatStockDetailCallBack" @CloseMatStockDetailBox="closeMatStockDetailBox"
            :width="900"></SelectMatStockDetail>
        <SelectStoInspectionDetail v-model="showStoInspectionDetail" ref="SelectStoInspectionDetail"
            @StoInspectionDetailCallBack="StoInspectionDetailCallBack"
            @CloseStoInspectionDetailBox="closeStoInspectionDetailBox"></SelectStoInspectionDetail>
        <SelectStockOutBorrow v-model="showStockOutBorrow" ref="SelectStockOutBorrow"
            @StockOutBorrowCallBack="StockOutBorrowCallBack" @CloseStockOutBorrowBox="closeStockOutBorrowBox">
        </SelectStockOutBorrow>
        <SelectReqForm v-model="showReqForm" ref="SelectReqForm" @ReqFormCallBack="ReqFormCallBack"
            @CloseReqFormBox="closeReqFormBox"></SelectReqForm>
        <SelectSeal v-model="showSeal" ref="selectSeal" @SealCallBack="sealCallBack" @CloseSealBox="closeSealBox">
        </SelectSeal>
        <SelectSupplierQuo v-model="showSupplierQuo" ref="SelectSupplierQuo" @SupplierQuoCallBack="SupplierQuoCallBack"
            @CloseSupplierQuoBox="closeSupplierQuoBox"></SelectSupplierQuo>
        <SelectQuaCheckItem v-model="showQuaCheckItem" ref="selectQuaCheckItem"
            @QuaCheckItemCallBack="quaCheckItemCallBack" @CloseQuaCheckItemBox="closeQuaCheckItemBox">
        </SelectQuaCheckItem>

        <SelectDevice v-model="showDevice" ref="selectDevice" @DeviceCallBack="deviceCallBack"
            @CloseDeviceBox="closeDeviceBox"></SelectDevice>

        <SelectMaintenanceCont v-model="showMaintenanceCont" ref="selectMaintenanceCont"
            @MaintenanceContCallBack="maintenanceContCallBack" @CloseMaintenanceContBox="closeMaintenanceContBox">
        </SelectMaintenanceCont>

        <SelectDetector v-model="showDetector" ref="selectDetector" @DetectorCallBack="DetectorCallBack"
            @CloseDetectorBox="closeDetectorBox"></SelectDetector>

        <DataPermission v-model="showDataPermission" ref="dataPermission"
            @DataPermissionCallBack="dataPermissionCallBack" @CloseDataPermissionBox="closeDataPermissionBox">
        </DataPermission>

        <CourseTrainVideoView v-model="showCourseTrainView" ref="courseTrainVideoView"
            @CloseCourseTrainBox="closeCourseTrainBox"></CourseTrainVideoView>

        <SelectInfomationBase v-model="showInfomationBase" ref="selectInfomationBase" @InfomationBaseCallBack="infomationBaseCallBack"
            @CloseInfomationBaseBox="closeInfomationBaseBox"></SelectInfomationBase>

        <SelectArea v-model="showAreas" ref="selectAreas" @AreasCallBack="AreasCallBack"
            @CloseAreasBox="closeAreasBox"></SelectArea>
    </div>
</template>
<script>
    import VolBox from "@/components/basic/VolBox.vue";
    import VolUpload from "@/components/basic/VolUpload.vue";
    import SelectEmp from '@/components/custom/SelectEmp.vue'
    import SelectEmpPlus from '@/components/custom/SelectEmpPlus.vue'
    import SelectDeptPosition from '@/components/custom/SelectDeptPosition.vue'
    import SelectLeaveApply from '@/components/custom/SelectLeaveApply.vue'
    import SelectMeetingRoom from '@/components/custom/SelectMeetingRoom.vue'
    import SelectPositionLevel from '@/components/custom/SelectPositionLevel.vue'
    import SelectTacking from '@/components/basic/SelectTrialPeriodTracking.vue'
    import SelectDepartment from '@/components/custom/SelectDepartment.vue'
    import VolUploadFile from '@/components/basic/fileUpload.vue'
    import SelectAsset from '@/components/custom/SelectAsset.vue'
    import SelectContract from '@/components/basic/SelectContract.vue'
    import SelectTrainCourse from '@/components/custom/SelectTrainCourse.vue'
    import SelectTrainPlan from '@/components/custom/SelectTrainPlan.vue'
    import SelectTrainPlanCourse from '@/components/custom/SelectTrainPlanCourse.vue'
    import SelectEmergencyPlan from '@/components/custom/SelectEmergencyPlan.vue'
    import SelectSpotCheckArea from '@/components/custom/SelectSpotCheckArea.vue'
    import SelectDocumentFile from '@/components/custom/SelectDocument.vue'
    import SelectContractComp from '@/components/custom/SelectContractComp.vue'
    import SelectMatInfo from "@/components/custom/SelectMatInfo.vue"
    import SelectSupplier from "@/components/custom/SelectSupplier.vue";
    import SelectPurApply from "@/components/custom/SelectPurApply.vue";
    import SelectReEx from "@/components/custom/SelectReturnExchange.vue";
    import SelectStoInspection from "@/components/custom/SelectStorageInspection.vue";
    import SelectMatStock from "@/components/custom/SelectMatStock.vue"
    import SelectStockOut from "@/components/custom/SelectStockOut.vue";
    import SelectMatStockDetail from "@/components/custom/SelectMatStockDetail.vue"
    import SelectStoInspectionDetail from "@/components/custom/SelectStorageInspectionDetail.vue";
    import SelectQuaCheckItem from "@/components/custom/SelectQuaCheckItem.vue";
    import SelectSeal from "@/components/custom/SelectSeal.vue";
    import SelectStockOutBorrow from "@/components/custom/SelectStockOutBorrow.vue";
    import SelectDevice from "@/components/custom/SelectDevice.vue";
    import SelectMaintenanceCont from "@/components/custom/SelectMaintenanceCont.vue";
    import SelectDetector from "@/components/custom/SelectDetector.vue";
    import DataPermission from "@/components/custom/DataPermission.vue";
    import SelectReqForm from "@/components/custom/SelectRequisitionForm.vue";
    import SelectSupplierQuo from "@/components/custom/SelectSupplierQuotation.vue";

    import CourseTrainVideoView from "@/extension/train/train/Course/CourseTrainVideoView.vue";

    import SelectInfomationBase from "@/components/custom/SelectInfomationBase.vue";
    import SelectArea from "@/components/custom/SelectLocation.vue"; 

    export default {
        components: {
            "vol-box": VolBox,
            VolUpload,
            SelectDeptPosition,
            SelectLeaveApply,
            SelectEmp,
            SelectEmpPlus,
            SelectMeetingRoom,
            SelectPositionLevel,
            SelectDepartment,
            SelectTacking,
            VolUploadFile,
            SelectAsset,
            SelectContract,
            SelectTrainCourse,
            SelectTrainPlan,
            SelectTrainPlanCourse,
            SelectEmergencyPlan,
            SelectSpotCheckArea,
            SelectDocumentFile,
            SelectContractComp,
            SelectMatInfo,
            SelectSupplier,
            SelectPurApply,
            SelectReEx,
            SelectStoInspection,
            SelectMatStock,
            SelectStockOut,
            SelectMatStockDetail,
            SelectStoInspectionDetail,
            SelectSeal,
            SelectQuaCheckItem,
            SelectStockOutBorrow,
            SelectDevice,
            SelectMaintenanceCont,
            SelectDetector,
            DataPermission,
            SelectReqForm,
            SelectSupplierQuo,
            CourseTrainVideoView,
            SelectInfomationBase,
            SelectArea,
        },
        data() {
            return {
                componentProps: {},
                showDeptPosition: false,
                showLeaveApply: false,
                showEmp: false,
                showEmpPlus: false,
                showMeetingRoom: false,
                showPositionLevel: false,
                showTacking: false,
                showDepartment: false,
                showfileUpload: false,
                showAsset: false,
                showContract: false,
                showTrainCourse: false,
                showTrainPlan: false,
                showTrainPlanCourse: false,
                showEmergencyPlan: false,
                showUpload: false,
                uploadFileInfo: [],
                showSpotCheckArea: false,
                showDocumentFile: false,
                showContractComp: false,
                showMatInfo: false,
                showSupplier: false,
                showPurApply: false,
                showReEx: false,
                showStoInspection: false,
                showMatStock: false,
                showStockOut: false,
                showMatStockDetail: false,
                showStoInspectionDetail: false,
                showQuaCheckItem: false,
                showSeal: false,
                showStockOutBorrow: false,
                showDevice: false,
                showMaintenanceCont: false,
                showDetector: false,
                showDataPermission: false,
                showReqForm: false,
                showSupplierQuo: false,
                showCourseTrainView: false,
                showInfomationBase: false,
                showAreas: false,
            }
        },
        methods: {
            openDeptPosition() {
                this.showDeptPosition = true;
                this.$nextTick(() => {
                    this.$refs.selectDeptPosition.load();
                })
            },
            deptPositionCallBack(row) {
                this.showDeptPosition = false;
                this.$emit("selectDeptPosition", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectDeptPosition(row)
                })
            },
            openLeaveApply() {
                this.showLeaveApply = true;
                this.$nextTick(() => {
                    this.$refs.selectLeaveApply.model = true;
                    this.$refs.selectLeaveApply.load();
                })
            },
            leaveApplyCallBack(row) {
                this.showLeaveApply = false;
                this.$emit("selectLeaveApply", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectLeaveApply(row)
                })
            },
            closeLeveApplyBox() {
                this.showLeaveApply = false;
            },
            openEmployee(sigle, status, JXEID) {
                if (sigle == null || sigle == undefined) {
                    sigle = true;
                }
                // if (status == null || status == undefined) {
                //     status = "1,2,3";
                // }
                this.showEmp = true;
                this.$nextTick(() => {
                    this.$refs.selectEmp.model = true;
                    this.$refs.selectEmp.sigle = sigle;
                    this.$refs.selectEmp.reset();
                    // this.$refs.selectEmp.formFields.empName = "";
                    // this.$refs.selectEmp.formFields.dept = "";
                    // this.$refs.selectEmp.formFields.position = position;
                    // this.$refs.selectEmp.formFields.level = "";
                    this.$refs.selectEmp.formFields.status = status;
                    this.$refs.selectEmp.load(JXEID);
                })
            },
            openEmployeePlus(isSigle, status, JXEID, selectedArray, expandedKeys) {
                if (isSigle == null || isSigle == undefined) {
                    isSigle = true;
                }
                // if (status == null || status == undefined) {
                //     status = "1,2,3";
                // }
                this.showEmpPlus = true;
                this.componentProps.is_sigle = isSigle;
                this.componentProps.default_checked_keys = selectedArray;
                this.componentProps.default_expanded_keys = expandedKeys;

                this.$nextTick(() => {

                    this.$refs.selectEmpPlus.model = true;

                    // this.$refs.selectEmpPlus.reset();
                    // this.$refs.selectEmp.formFields.empName = "";
                    // this.$refs.selectEmp.formFields.dept = "";
                    // this.$refs.selectEmp.formFields.position = position;
                    // this.$refs.selectEmp.formFields.level = "";
                    // this.$refs.selectEmpPlus.formFields.status = status;
                    // this.$refs.selectEmpPlus.load(JXEID);
                })
            },
            empCallBack(row) {
                this.showEmp = false;
                this.$emit("selectEmp", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectEmp(row)
                })
            },
            empPlusCallBack(rows) {
                this.$emit("selectEmpPlus", rows);
                this.$emit("parentCall", $vue => {
                    if ($vue.selectEmpPlus)
                        $vue.selectEmpPlus(rows)
                })
                this.showEmpPlus = false;
            },
            closeEmpBox() {
                this.showEmp = false;
            },
            closeEmpBoxPlus() {
                this.showEmpPlus = false;
            },
            openMeetingRoom() {
                this.showMeetingRoom = true;
                this.$nextTick(() => {
                    this.$refs.selectMeetingRoom.model = true;
                    this.$refs.selectMeetingRoom.load();
                })
            },
            meetingRoomCallBack(row) {
                this.showMeetingRoom = false;
                this.$emit("selectMeetingRoom", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectMeetingRoom(row)
                })
            },
            closeMeetingRoomBox() {
                this.showMeetingRoom = false;
            },
            openPositionLevel() {
                this.showPositionLevel = true;
                this.$nextTick(() => {
                    this.$refs.selectPositionLevel.model = true;
                    this.$refs.selectPositionLevel.load();
                })
            },
            positionLevelCallBack(row) {
                this.showPositionLevel = false;
                this.$emit("selectPositionLevel", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectPositionLevel(row)
                })
            },
            closePositionLevelBox() {
                this.showPositionLevel = false;
            },
            openTacking() {
                this.showTacking = true;
                this.$nextTick(() => {
                    this.$refs.SelectTacking.model = true;
                    this.$refs.SelectTacking.load();
                })
            },
            TackingCallBack(row) {
                this.showTacking = false;
                this.$emit("SelectTacking", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectTacking(row)
                })
            },
            closeTackingBox() {
                this.showTacking = false;
            },
            openDepartment() {
                this.showDepartment = true;
                this.$nextTick(() => {
                    this.$refs.selectDepartment.load();
                })
            },
            departmentCallBack(row) {
                this.showDepartment = false;
                this.$emit("selectDepartment", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectDepartment(row)
                })
            },
            openfileUpload() {
                this.showfileUpload = true;
            },
            fileUploadCallBack(img) {
                this.showfileUpload = false;
                this.$emit("fileUploadDetails", img);
                this.$emit("parentCall", $vue => {
                    $vue.fileUploadDetails(img)
                })
            },
            closefileUploadBox() {
                this.showfileUpload = false;
            },
            openAsset(EmpID, SearchType, deptID) {
                this.showAsset = true;
                this.$nextTick(() => {
                    this.$refs.selectAsset.model = true;
                    this.$refs.selectAsset.load(EmpID, SearchType, deptID);
                })
            },
            assetCallBack(row) {
                this.showAsset = false;
                this.$emit("selectAsset", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectAsset(row)
                })
            },
            closeAssetBox() {
                this.showAsset = false;
            },
            openContract() {
                this.showContract = true;
                this.$nextTick(() => {
                    this.$refs.SelectContract.model = true;
                    this.$refs.SelectContract.load();
                })
            },

            ContractCallBack(row) {
                this.showContract = false;
                this.$emit("SelectContract", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectContract(row)
                })
            },
            closeContractBox() {
                this.showContract = false;
            },
            openTrainCourse() {
                this.showTrainCourse = true;
                this.$nextTick(() => {
                    this.$refs.SelectTrainCourse.model = true;
                    this.$refs.SelectTrainCourse.load();
                })
            },
            CourseCallBack(row) {
                this.showTrainCourse = false;
                this.$emit("selectTrainCourse", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectTrainCourse(row)
                })
            },
            CloseCourseBox() {
                this.showTrainCourse = false;
            },
            openTrainPlanCourse(PlanID) {
                this.showTrainPlanCourse = true;
                this.$nextTick(() => {
                    this.$refs.SelectTrainPlanCourse.model = true;
                    this.$refs.SelectTrainPlanCourse.planID = PlanID;
                    this.$refs.SelectTrainPlanCourse.load();
                })
            },
            PlanCourseCallBack(row) {
                this.showTrainPlanCourse = false;
                this.$emit("selectTrainPlanCourse", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectTrainPlanCourse(row)
                })
            },
            ClosePlanCourseBox() {
                this.showTrainPlanCourse = false;
            },
            openTrainPlan() {
                this.showTrainPlan = true;
                this.$nextTick(() => {
                    this.$refs.SelectTrainPlan.model = true;
                    this.$refs.SelectTrainPlan.load();
                })
            },
            TrainPlanCallBack(row) {
                this.showTrainPlan = false;
                this.$emit("selectTrainPlan", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectTrainPlan(row)
                })
            },
            CloseTrainPlanBox() {
                this.showTrainPlan = false;
            },
            //应急预案方法--------开始----------
            openEmergencyPlan() {
                this.showEmergencyPlan = true;
                this.$nextTick(() => {
                    this.$refs.SelectEmergencyPlan.model = true;
                    this.$refs.SelectEmergencyPlan.load();
                })
            },
            EmergencyPlanCallBack(row) {
                this.showEmergencyPlan = false;
                this.$emit("selectEmergencyPlan", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectEmergencyPlan(row)
                })
            },
            CloseEmergencyPlanBox() {
                this.showEmergencyPlan = true;
            },
            //应急预案方法--------结束----------
            openUpload(files) {
                if (typeof (files) == 'string' && files != '' && files != null) {
                    files = files.split(',');
                }
                files = files == null || files.length <= 0 ? [] : files;
                this.uploadFileInfo = [];
                if (files != null && files.length > 0) {
                    files.forEach(x => {
                        this.uploadFileInfo.push({
                            name: x.split('/')[x.split('/').length - 1],
                            path: x
                        })
                    })
                }
                this.showUpload = true;
            },
            fileChange() {
                return true;
            },
            fileUploadAfter(result, files) {
                if (!result.status) return true;
                var fileInfos = [];
                // if (this.uploadFileInfo != null && this.uploadFileInfo.length > 0) {
                //     this.uploadFileInfo.forEach(x => {
                //         fileInfos.push(x.path)
                //     })
                // }
                files.forEach(x => {
                    fileInfos.push(result.data + x.name)
                })
                this.$emit("uploadFile", fileInfos.join(','));
                this.$emit("parentCall", $vue => {
                    $vue.uploadFile(fileInfos.join(','))
                })
                this.showUpload = false;
                return true;
            },
            fileRemoveBefore(index, removeFile, fileInfo) {
                this.uploadFileInfo = fileInfo;
                //console.log(index)
                //console.log(removeFile)
                //console.log(this.uploadFileInfo)
            },
            fileModelClose() {
                var fileInfos = [];
                if (this.uploadFileInfo != null && this.uploadFileInfo.length > 0) {
                    this.uploadFileInfo.forEach(x => {
                        fileInfos.push(x.path)
                    })
                }
                this.$emit("uploadFile", fileInfos.join(','));
                this.$emit("parentCall", $vue => {
                    $vue.uploadFile(fileInfos.join(','))
                })
            },
            //质量检查-区域选择
            openSpotCheckArea() {
                this.showSpotCheckArea = true;
                this.$nextTick(() => {
                    this.$refs.SelectSpotCheckArea.model = true;
                    this.$refs.SelectSpotCheckArea.load();
                })
            },
            SpotCheckAreaCallBack(row) {
                this.showSpotCheckArea = false;
                this.$emit("selectSpotCheckArea", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectSpotCheckArea(row)
                })
            },
            CloseSpotCheckAreaBox() {
                this.showSpotCheckArea = false;
            },

            openDocumentFile() {
                this.showDocumentFile = true;
                this.$nextTick(() => {
                    this.$refs.SelectDocumentFile.model = true;
                    this.$refs.SelectDocumentFile.load();
                })
            },
            documentFileCallBack(row) {
                this.showDocumentFile = false;
                this.$emit("selectDocumentFile", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectDocumentFile(row)
                })
            },
            closeDocumentFileBox() {
                this.showDocumentFile = false;
            },

            openContractComp() {
                this.showContractComp = true;
                this.$nextTick(() => {
                    this.$refs.SelectContractComp.model = true;
                    this.$refs.SelectContractComp.load();
                })
            },

            ContractCompCallBack(row) {
                this.showContractComp = false;
                this.$emit("SelectContractComp", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectContractComp(row)
                })
            },
            closeContractCompBox() {
                this.showContractComp = false;
            },

            openMatInfo(sigle) {
                this.showMatInfo = true;
                this.$nextTick(() => {
                    this.$refs.SelectMatInfo.model = true;
                    this.$refs.SelectMatInfo.sigle = sigle || false;
                    this.$refs.SelectMatInfo.load();
                })
            },

            MatInfoCallBack(row) {
                this.showMatInfo = false;
                this.$emit("SelectMatInfo", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectMatInfo(row)
                })
            },
            closeMatInfoBox() {
                this.showMatInfo = false;
            },

            openSupplier() {
                this.showSupplier = true;
                this.$nextTick(() => {
                    this.$refs.SelectSupplier.model = true;
                    this.$refs.SelectSupplier.load();
                })
            },

            SupplierCallBack(row) {
                this.showSupplier = false;
                this.$emit("SelectSupplier", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectSupplier(row)
                })
            },
            closeSupplierBox() {
                this.showSupplier = false;
            },

            openPurApply(auditStatus = '') {
                this.showPurApply = true;
                this.$nextTick(() => {
                    this.$refs.SelectPurApply.auditStatus = auditStatus
                    this.$refs.SelectPurApply.model = true;
                    this.$refs.SelectPurApply.load();
                })
            },

            PurApplyCallBack(row) {
                this.showPurApply = false;
                this.$emit("SelectPurApply", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectPurApply(row)
                })
            },
            closePurApplyBox() {
                this.showPurApply = false;
            },

            openReEx() {
                this.showReEx = true;
                this.$nextTick(() => {
                    this.$refs.SelectReEx.model = true;
                    this.$refs.SelectReEx.load();
                })
            },

            ReExCallBack(row) {
                this.showReEx = false;
                this.$emit("SelectReEx", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectReEx(row)
                })
            },
            closeReExBox() {
                this.showReEx = false;
            },

            openStoInspection() {
                this.showStoInspection = true;
                this.$nextTick(() => {
                    this.$refs.SelectStoInspection.model = true;
                    this.$refs.SelectStoInspection.load();
                })
            },

            StoInspectionCallBack(row) {
                this.showStoInspection = false;
                this.$emit("SelectStoInspection", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectStoInspection(row)
                })
            },
            closeStoInspectionBox() {
                this.showStoInspection = false;
            },

            openMatStock() {
                this.showMatStock = true;
                this.$nextTick(() => {
                    this.$refs.SelectMatStock.model = true;
                    this.$refs.SelectMatStock.load();
                })
            },

            MatStockCallBack(row) {
                this.showMatStock = false;
                this.$emit("SelectMatStock", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectMatStock(row)
                })
            },
            closeMatStockBox() {
                this.showMatStock = false;
            },

            openStockOut() {
                this.showStockOut = true;
                this.$nextTick(() => {
                    this.$refs.SelectStockOut.model = true;
                    this.$refs.SelectStockOut.load();
                })
            },

            StockOutCallBack(row) {
                this.showStockOut = false;
                this.$emit("SelectStockOut", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectStockOut(row)
                })
            },
            closeStockOutBox() {
                this.showStockOut = false;
            },

            openMatStockDetail() {
                this.showMatStockDetail = true;
                this.$nextTick(() => {
                    this.$refs.SelectMatStockDetail.model = true;
                    this.$refs.SelectMatStockDetail.load();
                })
            },

            MatStockDetailCallBack(row) {
                this.showMatStockDetail = false;
                this.$emit("SelectMatStockDetail", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectMatStockDetail(row)
                })
            },
            closeMatStockDetailBox() {
                this.showMatStockDetail = false;
            },

            openStoInspectionDetail() {
                this.showStoInspectionDetail = true;
                this.$nextTick(() => {
                    this.$refs.SelectStoInspectionDetail.model = true;
                    this.$refs.SelectStoInspectionDetail.load();
                })
            },

            StoInspectionDetailCallBack(row) {
                this.showStoInspectionDetail = false;
                this.$emit("SelectStoInspectionDetail", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectStoInspectionDetail(row)
                })
            },
            closeStoInspectionDetailBox() {
                this.showStoInspectionDetail = false;
            },
            openQuaCheckItem() {
                this.showQuaCheckItem = true;
                this.$nextTick(() => {
                    this.$refs.selectQuaCheckItem.model = true;
                    this.$refs.selectQuaCheckItem.load();
                })
            },

            QuaCheckItemCallBack(row) {
                this.showQuaCheckItem = false;
                this.$emit("selectQuaCheckItem", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectStoInspection(row)
                })
            },
            closeQuaCheckItem() {
                this.showQuaCheckItem = false;
            },
            //选择印章-start
            openSeal() {
                this.showSeal = true;
                this.$nextTick(() => {
                    this.$refs.selectSeal.model = true;
                    this.$refs.selectSeal.load();
                })
            },
            sealCallBack(row) {
                this.showSeal = false;
                this.$emit("selectSeal", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectSeal(row)
                })
            },
            closeSealBox() {
                this.showSeal = false;
            },
            //选择印章-end

            openStockOutBorrow() {
                this.showStockOutBorrow = true;
                this.$nextTick(() => {
                    this.$refs.SelectStockOutBorrow.model = true;
                    this.$refs.SelectStockOutBorrow.load();
                })
            },

            StockOutBorrowCallBack(row) {
                this.showStockOutBorrow = false;
                this.$emit("SelectStockOutBorrow", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectStockOutBorrow(row)
                })
            },
            closeStockOutBorrowBox() {
                this.showStockOutBorrow = false;
            },
            openDevice(t) {
                this.showDevice = true;
                this.$nextTick(() => {
                    this.$refs.selectDevice.model = true;
                    this.$refs.selectDevice.init({
                        Name: '',
                        DeviceType: t || -1
                    });
                    //this.$refs.selectDevice.load()
                })
            },
            deviceCallBack(row) {
                this.showDevice = false;
                this.$emit("selectDevice", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectDevice(row)
                })
            },
            closeDeviceBox() {
                this.showDevice = false;
            },
            openMaintenanceCont() {
                this.showMaintenanceCont = true;
                this.$nextTick(() => {
                    this.$refs.selectMaintenanceCont.model = true;
                    this.$refs.selectMaintenanceCont.init();
                })
            },
            maintenanceContCallBack(row) {
                this.showMaintenanceCont = false;
                this.$emit("selectMaintenanceCont", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectMaintenanceCont(row)
                })
            },
            closeMaintenanceContBox() {
                this.showMaintenanceCont = false;
            },
            openDetector() {
                this.showDetector = true;
                this.$nextTick(() => {
                    this.$refs.selectDetector.model = true;
                    this.$refs.selectDetector.load();
                })
            },
            DetectorCallBack(row) {
                this.showDetector = false;
                this.$emit("selectDetector", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectDetector(row)
                })
            },
            closeDetectorBox() {
                this.showDetector = false;
            },
            openDataPermission(row) {
                this.showDataPermission = true;
                this.$nextTick(() => {
                    this.$refs.dataPermission.open(row)
                })
            },
            dataPermissionCallBack(row) {
                this.showDataPermission = false;
                this.$emit("dataPermissionCallBack", row);
                this.$emit("parentCall", $vue => {
                    $vue.dataPermissionCallBack(row)
                })
            },
            closeDataPermissionBox() {
                this.showDataPermission = false;
            },
            openReqForm(auditStatus = '') {
                this.showReqForm = true;
                this.$nextTick(() => {
                    this.$refs.SelectReqForm.auditStatus = auditStatus
                    this.$refs.SelectReqForm.model = true;
                    this.$refs.SelectReqForm.load();
                })
            },

            ReqFormCallBack(row) {
                this.showReqForm = false;
                this.$emit("SelectReqForm", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectReqForm(row)
                })
            },
            closeReqFormBox() {
                this.showReqForm = false;
            },
            openSupplierQuo() {
                this.showSupplierQuo = true;
                this.$nextTick(() => {
                    this.$refs.SelectSupplierQuo.model = true;
                    this.$refs.SelectSupplierQuo.load();
                })
            },

            SupplierQuoCallBack(row) {
                this.showSupplierQuo = false;
                this.$emit("SelectSupplierQuo", row);
                this.$emit("parentCall", $vue => {
                    $vue.SelectSupplierQuo(row)
                })
            },
            closeSupplierQuoBox() {
                this.showSupplierQuo = false;
            },

            openCourseTrainView(row) {
                this.showCourseTrainView = true;
                this.$nextTick(() => {
                    this.$refs.courseTrainVideoView.openTopic(row);
                })
            },
            closeCourseTrainBox(){
                this.showCourseTrainView = false;
            },
            openInfomationBase() {
                this.showInfomationBase = true;
                this.$nextTick(() => {
                    this.$refs.selectInfomationBase.model = true;
                    this.$refs.selectInfomationBase.init();
                })
            },
            infomationBaseCallBack(row) {
                this.showInfomationBase = false;
                this.$emit("selectInfomationBase", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectInfomationBase(row)
                })
            },
            closeInfomationBaseBox() {
                this.showInfomationBase = false;
            },
            openAreas(row) {
                this.showAreas = true;
                this.$nextTick(() => {
                    this.$refs.selectAreas.selectRow = row
                    this.$refs.selectAreas.model = true;
                    this.$refs.selectAreas.init();
                })
            },
            AreasCallBack(row) {
                this.showAreas = false;
                this.$emit("selectAreas", row);
                this.$emit("parentCall", $vue => {
                    $vue.selectAreas(row)
                })
            },
            closeAreasBox() {
                this.showAreas = false;
            },
        }
    }
</script>