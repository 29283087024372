<template>
    <div class="com-el-tree">
        <div class="m-title"><i class="el-icon-menu"></i>部门列表</div>
        <!-- <el-scrollbar style="height: 100%; width: 430px" class="el-role-tree">
            <el-tree :data="tree" @node-click="handleClick" node-key="id" :default-expanded-keys="openKeys"
                :expand-on-click-node="false" style="padding: 5px 0">
                <template #default="{ node, data }">
                    <div class="action-group">
                        <div class="action-text" :class="{
                            actived: data.id == selectId,
                            'node-text': data.parentId !== 0,
                        }" :style="{ width: (4 - data.lv) * 18 + 150 + 'px' }">
                            {{ data.deptName }}
                        </div>
                    </div>
                </template>
            </el-tree>
        </el-scrollbar> -->
        <el-scrollbar style="height: 600px; width: 430px" class="el-role-tree">
            <el-tree :data="tree" @node-click="handleClick" node-key="id" :default-expanded-keys="openKeys" default-expand-all
                :expand-on-click-node="false" style="padding: 5px 0">
                <template #default="{ node, data }">
                    <div class="action-group">
                        <div class="action-text" :class="{
                            actived: data.id == selectId,
                            'node-text': data.parentId !== 0,
                        }" :style="{ width: (4 - data.lv) * 18 + 150 + 'px' }">
                        {{ data.deptName }}
                        </div>
                    </div>
                </template>
            </el-tree>
        </el-scrollbar>
    </div>
</template>
  
<script>
export default {
    props: {
        onChange: {
            type: Function,
            default: (treeId) => { },
        },
    },
    data() {
        return {
            selectId: -1,
            checked: false,
            openKeys: [],
            data: [],
            tree: [],
        };
    },
    methods: {
        load() {
            this.selectId = -1;
            this.checked = false;
            this.openKeys = [];
            this.data = [];
            this.tree = [];
            this.http.post("/api/department/getUserChildDepts", {}, true).then((result) => {
                if (!result.status) return this.$message.error(result.message);
                this.data.splice(0);
                this.data = result.data;
                this.data.forEach((x) => {
                    if (x.parentId == 0) {
                        x.lv = 1;
                        x.children = [];
                        this.tree.push(x);
                        this.getTree(x.id, x);
                    }
                });
                this.openKeys.push(this.tree[0].id);
                this.selectId = undefined;// this.openKeys[0];
                //console.log(this.data);
                //console.log(this.tree)
            });
        },
        getTree(id, data) {
            this.data.forEach((x) => {
                if (x.parentId == id) {
                    x.lv = data.lv + 1;
                    if (!data.children) data.children = [];
                    data.children.push(x);
                    this.getTree(x.id, x);
                }
            });
        },
    },
    emits: ['DepartmentCallBack'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('DepartmentCallBack', item)
        }
        return {
            handleClick
        }
    }
};
</script>
<style lang="less" scoped>
.menu-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 8px;
    background: #f7f7f7;
}

.menu-left {
    height: 100%;
    width: 201px;
    //border: 1px solid #eee;
    display: flex;
    background: white;
    flex-direction: column;
}

.module-name {
    border-radius: 0px;
    /* height: 5%; */
    line-height: 21px;
    margin-bottom: 0;
}

.menu-right {
    flex: 1;
    border-radius: 3px;
    border: 1px solid #eee;
    background: white;
    margin-left: 9px;
    margin-right: 3px;
}


.com-el-tree {
    display: inline-block;
    width: 430px;
    height: 600px;

    .el-role-tree {
        position: absolute;
        padding: 5px 0px;
        //top: 48px;
        bottom: 0;
        border-right: 1px solid #eee;
    }

    .actived {
        color: #5884ff;
        font-size: 15px;
    }

    .action-text {
        font-size: 14px;
    }
}

.role-list {
    color: white;
    line-height: 44px;
    background: #1a89ff;
    padding: 0 13px;
    font-size: 16px;
    position: absolute;
    top: 2px;
    width: 210px;
}

.m-title {
    line-height: 20px;
    font-size: 15px;
    //background: #66b1ff0f;
    //font-weight: bold;
    padding: 6px 16px;
    //border: 1px solid #ececec;
    position: absolute;
    width: inherit;
    color: rgb(45, 140, 240);
    i {
        padding-right: 5px;
    }
}

.com-el-tree ::v-deep(.el-tree-node) {
    padding: 3px 0;
}

.com-el-tree ::v-deep(.el-scrollbar .el-scrollbar__thumb) {
    width: 0 !important;
}
</style>
  