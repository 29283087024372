<template>
    <view>
        <VolBox v-model="model" :lazy="true" title="添加报价" :height="540" :width="800" :padding="15" :onModelClose="closeSupplierSingleBox">
        <div class="addModel" style="padding-right: 30px">
            <vol-form ref="form" :labelWidth="100" :load-key="true" :formFields="formFields" :formRules="formOptions">

            </vol-form>
        </div>
                <!-- <template #footer> -->
                <div style="text-align: right;">
                    <el-button size="medium" type="primary" @click="save()">保存</el-button>
                    <el-button size="medium" @click="closeSupplierSingleBox()"><i class="el-icon-close"></i>关闭</el-button>
                </div>
            <!-- </template> -->
            <!-- <Merge ref="Merge" @SelectSupplier="SelectSupplier"  @SelectMatInfo="SelectMatInfo">
            </Merge> -->
            <MatInfo v-model="showMatInfo" ref="SelectMatInfo" @MatInfoCallBack="MatInfoCallBack" @CloseMatInfoBox="closeMatInfoBox"></MatInfo>
            <Supplier v-model="showSupplier" ref="SelectSupplier" @SupplierCallBack="SupplierCallBack" @CloseSupplierBox="closeSupplierBox"></Supplier>
    </VolBox>
    </view>

</template>

<script>
// 使用方式：
// 1、新建一个vue页面，把此页面内容复制进去
// 2、router->index.js配置路由，页面上输入地址即可看到数据(也可以把菜单配置上)
// 3、或者参照表单设计页面做动态页面
//**表单设计器的table下载还在开发中
import VolTable from '@/components/basic/VolTable'
import VolForm from '@/components/basic/VolForm'
//import Merge from '@/components/custom/Merge.vue'
import SelectMatInfo from "@/components/custom/SelectMatInfo.vue"
import SelectSupplier from "@/components/custom/SelectSupplier.vue";

export default {
    components: { "vol-form": VolForm, 'vol-table': VolTable, //Merge: Merge 
    MatInfo:SelectMatInfo,
    Supplier:SelectSupplier,

    },
    data() {
        return {
            showMatInfo:false,
            showSupplier:false,
            model:false,
            formFields: {"MaterialsID":"","Price":"","Rate":"","SupplierID":"","CreateDate":"","CreateID":"","MatNo":"","MatName":"","Brand":"","Model":"","Unit":"","SupplierName":"","Remark":"","CreateName":""},
            formOptions: [
                [{ "title": "编码", "field": "MatNo", "type": "popup", popup: {}, "required": true },
                { "title": "名称", "field": "MatName", "type": "string" }],
                [{ "title": "品牌", "field": "Brand", "type": "string" },
                { "title": "规格型号", "field": "Model", "type": "string" }],
                [{ "title": "计量单位", "field": "Unit", "type": "string" },
                { "title": "供应商", "field": "SupplierName", "type": "popup", popup: {}, "required": true }],
                [{ "title": "报价", "field": "Price", "type": "decimal" ,"required": true },
                { "title": "税率", "field": "Rate", "type": "decimal" }],
                [{ "title": "备注", "field": "Remark", "type": "textarea", "colSize": 12 }],
                [{ "title": "报价时间", "field": "CreateDate", "type": "datetime",disabled:true },
                { "title": "创建人", "field": "CreateID", "type": "int", hidden: true },
                { "title": "创建人", "field": "CreateName", "type": "string" ,disabled:true}]
            ]
        };
    },
    created() {
        this.load();
    },
    mounted() {

    },
    methods: {
        load() {
           var _this=this;
            this.$nextTick(() => {
                this.formOptions.forEach(items => {
                        items.forEach(item => {
                        if (item.field == "MatNo") {
                            item.popup = {
                            idfield: "MaterialsID",
                            click: () => {
                                this.showMatInfo=true;
                                this.$nextTick(() => {
                                    //this.$refs.MatInfo.model = true;
                                    this.$refs.MatInfo.load();
                                })
                                    // this.$refs.MatInfo.openMatInfo();
                            }
                            }
                        }
                        if(item.field=="SupplierName"){
                            item.popup = {
                            idfield: "SupplierID",
                            click: () => {                              
                                this.showSupplier=true;
                                this.$nextTick(() => {
                                   // this.$refs.Supplier.model = true;
                                    this.$refs.Supplier.load();
                                })
                                //this.$refs.Supplier.openSupplier();
                            }
                            }
                        }
                        })
                    })
             });
             this.formFields.CreateID=this.$store.getters.getUserInfo().userId;
             this.formFields.CreateName=this.$store.getters.getUserInfo().userName;
             this.formFields.CreateDate=this.getDate(true);

            // this.parent = this.getParent()
            // var currentRow = this.parent.currentRow
            // var _this = this
            // $.each(this.formFields, function (key, val) {
            //     _this.formFields[key] = currentRow[key]
            // });
            // var disabled = false
            // if (currentRow.AuditStatus == null || currentRow.AuditStatus == 4) {
            //     disabled = true
            // }
            // this.formOptions.forEach((row) => {
            //     row.forEach((x) => {
            //         x.disabled = disabled;
            //     });
            // });
        },

        MatInfoCallBack(row) {
            console.log(row);
            this.formFields.MaterialsID = row[0].MaterialsID;
            this.formFields.MatNo = row[0].MatNo;
            this.formFields.MatName = row[0].MatName;
            this.formFields.Unit = row[0].Unit;
            this.formFields.Brand = row[0].Brand;
            this.formFields.Model = row[0].Model;

            this.showMatInfo = false;
            // this.$emit("SelectMatInfo", row);
            // this.$emit("parentCall", $vue => {
            //     //$vue.SelectMatInfo(row)
            //     this.SelectMatInfo(row)
            // })
        },
        closeMatInfoBox() {
            this.showMatInfo = false;
        },
        SupplierCallBack(row){
            console.log(row);
            this.formFields.SupplierID = row.SupplierID;
            this.formFields.SupplierName = row.SName;
            this.showSupplier = false;
        },
        closeSupplierBox(){
            this.showSupplier = false;
        },
        save(){

            if (this.formFields.MaterialsID == "" || this.formFields.MaterialsID == null) {
                this.$Message.error("请选择物料");
                return;
            }else if (this.formFields.SupplierID == "" || this.formFields.SupplierID == null) {
                this.$Message.error("请选择供应商");
                return;
            }else if (this.formFields.Price == "" || this.formFields.Price == 0) {
                this.$Message.error("请填写报价");
                return;
            }

            var params = {
                mainData: this.formFields
            }  
            this.http.post("/api/Pur_SupplierQuotation/Add", params, true)
                    .then(result => {
                        // console.log('editSave post', result)
                        if (result.status === false) {
                            this.$Message.error(result.message);
                        }else{
                            this.$Message.success("保存成功");
                            this.$store.getters.data().SupQuoButtonModel.load();
                            this.$store.getters.data().SupQuoButtonModel.onModelClose();

                        }
                    })
        },
        closeSupplierSingleBox(){
            this.$store.getters.data().SupQuoButtonModel.onModelClose();
           // this.model = false;
        },
        getDate(time) {
            let date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            let datetime =
            year +
            '-' +
            (month < 10 ? '0' + month : month) +
            '-' +
            (day < 10 ? '0' + day : day);

            if (!time) {
            return datetime;
            }

            let hour = date.getHours();
            let minutes = date.getMinutes();
            let second = date.getSeconds();

            return (
            datetime +
            '' +
            ' ' +
            (hour < 10 ? '0' + hour : hour) +
            ':' +
            (minutes < 10 ? '0' + minutes : minutes) +
            ':' +
            (second < 10 ? '0' + second : second)
            );
        },
    },
   
};
</script>
<style lang="less" scoped></style>