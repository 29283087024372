<template>
    <VolBox v-model="model" :lazy="true" title="选择维保内容" :height="740" :width="width" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <vol-form ref="myform" :labelWidth="80" :load-key="true" :formFields="formFileds" :formRules="formRules">
                <div style="text-align: right;">
                    <el-button type="primary" @click="load" size="mini"><i class="el-icon-search"></i>查询</el-button>
                    <el-button type="primary" @click="reset" plain size="mini"><i
                            class="el-icon-refresh-right"></i>重置</el-button>
                </div>
            </vol-form>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="true"
            :height="540" :url="url" :index="true" :single="sigle" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false; closeBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {

    },
    data() {
        return {
            width: document.body.clientWidth * 0.7,
            model: false,
            defaultLoadPage: false,
            formFileds: {
                "TypeID": "", "Cont": ""
            },
            formRules: [
                [
                    { dataKey: "维保设备种类", "data": [], "title": "设备种类", "field": "TypeID", "type": "select" },
                    { "title": "维保内容", "field": "Cont", "type": "like" },
                ]
            ],
            url: "api/Sys_DeviceMaintenanceTypeDetail/GetPageData",//加载数据的接口
            columns: [
                { field: 'DetailID', title: 'DetailID', type: 'int', width: 110, hidden: true, readonly: true, require: true, align: 'left' },
                { field: 'TypeID', title: 'TypeID', type: 'int', width: 110, hidden: true, require: true, align: 'left' },
                { field: 'Cont', title: '维保内容', type: 'string', width: 180, require: true, align: 'left', sort: true },
                { field: 'Standard', title: '维保标准', type: 'string', width: 180, require: true, align: 'left' },
                { field: 'Tool', title: '工具', type: 'string', width: 120, align: 'left' },
                { field: 'WorkHours', title: '标准工时（min）', type: 'int', width: 110, require: true, align: 'left' },
                { field: 'Frequency', title: '维保频率', type: 'int', bind: { key: '维保频率', data: [] }, width: 110, require: true, align: 'left' },
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            sigle: false,
        };
    },
    methods: {
        init(obj) {
            this.model = true;
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytable.reset();
            });
        },
        load() {
            this.$refs.mytable.load();
        },
        reset() {
            this.$refs.myform.reset()
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },

        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.handleClick(rows);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            params.page = 1;
            params.rows = 50000
            for (var i in this.formFileds) {
                if (!this.base.checkEmpty(this.formFileds[i])) {
                    params.wheres.push({ name: i, value: this.formFileds[i], displayType: i == "Cont" ? "like" : "" });
                }
            }
            return true;
        },
    },
    emits: ['MaintenanceContCallBack', 'CloseMaintenanceContBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('MaintenanceContCallBack', item)
        }
        const closeBox = (item) => {
            emit('CloseMaintenanceContBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
};
</script>