<template>
    <VolBox v-model="model" :lazy="true" title="选择物料" :height="540" :width="900" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="关键字" style="width: 250px" v-model="MatInfoName" @keyup.enter="search()"/>
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i class="el-icon-search" ></i>搜索</el-button>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :max-height="380" :url="url" :index="true"  :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter" :single="sigle"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false;closeMatInfoBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {
        selectRow: Object
    },
    data() {
        
        return {
            model: false,           
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            MatInfoName: "", //查询条件字段
            url: "api/Pur_MaterialsInfo/GetSelectData",//加载数据的接口
            columns: [
                {field: "MaterialsID", title: "物料id", width: 90, hidden: true },
                {field:'MatNo',title:'物料编码',width:150},
                {field:'MatName',title:'物料名称',width:100},              
                {field: "MatTypeID", title: "物料类别", width: 120,bind: { key: 'mattype', data: [] }},
                {field: "Unit", title: "计量单位", width: 120},
                {field: "Model", title: "规格型号", width: 120},
                {field: "Brand", title: "品牌", width: 120},
                {field:'UseType',title:'物料属性',width:100,bind: { key: 'UseType', data: [] }},
                // {field:'AvgPrice',title:'平均价',width:100},
                {field: "WhseID", title: "当前仓库", width: 100,bind: { key: 'whse', data: [] }},
                {field: "Stock", title: "库存", width: 120},
                {field: "MGNum", title: "年度额定用量", width: 120},
                { field: "ConfigTypeID", title: "标配资产类型", width: 80,bind:{ key:'物料配置类型',data:[]}},
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.SelectMatInfo(row);
                                    })
                                    //this.selectCompCall(row)
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            pagination: {size:120}, //分页配置，见voltable组件api
            sigle: false            
        };
    },
    methods: {
        load(){
            this.$refs.mytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.mytable.load();
        },
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                //$parent.SelectMatInfo(rows[0]);
                $parent.SelectMatInfo(rows);
            })
           // this.handleClick(rows[0]);
            this.handleClick(rows);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
          
            //查询前，设置查询条件
            if (this.MatInfoName) {
                params.wheres = [{ name: "value", value: this.MatInfoName },{name: "Forbidden", value: 0 }];
            }
            console.log(this.$store.getters.data().WhseID);
            var WhseID=this.$store.getters.data().WhseID;
            var DeptID=this.$store.getters.data().DeptID;
            if (WhseID !== undefined) {
                params.wheres.push({name:'WhseID',value:WhseID});
            }
            if(DeptID !== undefined){
                params.wheres.push({name:'DeptID',value:DeptID});
            }
             
            return true;
        },
        // selectEmpCall(row){
        //     this.$emit("selectEmpCall", row);
        // }
    },
    emits: ['MatInfoCallBack','CloseMatInfoBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('MatInfoCallBack', item)
        }
        const closeMatInfoBox = (item) => {
            emit('CloseMatInfoBox', item)
        }
        return {
            handleClick,
            closeMatInfoBox
        }
    }
};
</script>