<template>
    <VolBox v-model="model" :lazy="true" title="选择适用位置" :height="740" :width="width" :padding="15">
        <!-- 设置查询条件 -->

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <div class="container">
            <div class="table-wrapper">
                <!-- <div style="padding-bottom: 100px"></div> -->
                <el-alert title="已选择位置" type="success" :closable="false" />
                <vol-table ref="mytableRight" :loadKey="true" :columns="columns" :pagination="pagination"
                    :pagination-hide="false" :height="640" :tableData="leftTableData" :index="true"
                    :defaultLoadPage="false"></vol-table>
                <div style="text-align: right;"><el-button size="medium" type="danger" @click="delRow()">删除</el-button>
                </div>
            </div>
            <div class="table-wrapper">
                <el-alert title="选择位置" type="warning" :closable="false" />
                <div style="padding-bottom: 10px">
                    <!-- <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="请输入编码、描述、备注、层级" style="width:500px" v-model="Name" @keyup.enter="load()" />
            <el-button size="medium" type="primary" style="margin-left:10px" @click="load"><i
                    class="el-icon-search"></i>搜索</el-button> -->
                    <vol-form ref="myform" :labelWidth="120" :load-key="true" :formFields="formFileds"
                        :formRules="formRules">
                        <div style="text-align: right;">
                            <el-button type="primary" @click="load" size="mini"><i
                                    class="el-icon-search"></i>查询</el-button>
                            <el-button type="primary" @click="reset" plain size="mini"><i
                                    class="el-icon-refresh-right"></i>重置</el-button>
                        </div>
                    </vol-form>
                </div>

                <vol-table ref="mytableLeft" :loadKey="true" :columns="columns" :pagination="pagination"
                    :pagination-hide="false" :height="540" :url="url" :index="true" :defaultLoadPage="defaultLoadPage"
                    @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
                <div style="text-align: right;"><el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                </div>
            </div>

        </div>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" @click="save" type="primary"><i class="el-icon-plus"></i>保存</el-button>
                <el-button size="medium" @click="model = false; closeBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {

    },
    data() {
        return {
            width: document.body.clientWidth * 0.7,
            model: false,
            defaultLoadPage: false,
            formFileds: {
                "DeptID": "", "AreaPosName": ""
            },
            formRules: [
                [
                    { "dataKey": "dept-tree", "data": [], "title": "选择部门", "field": "DeptID", "type": "cascader", onChange: this.AreaChange },
                    { "title": "选择区域", "field": "AreaPosName", "type": "select", showAllLevels: false, data:[] }
                ]
            ],
            url: "api/Qua_Position/GetPageData",//加载数据的接口
            url1: "api/Qua_CheckContItemArea/GetPageData",
            columns: [
                { field: 'PositionID', title: 'PositionID', type: 'int', hidden: true, width: 110, align: 'left', sort: true, },
                { field: 'Name', title: '位置名称', type: 'int', width: 120, align: 'left', showAllLevels: true },
                { field: 'Code', title: '位置编码', type: 'int', width: 120, align: 'left' },
                { field: 'LatitudeLongitude', title: '经纬度', type: 'string', width: 100, align: 'left', link: true },
                { field: 'IsEnable', title: '必查项', type: 'int', bind: { key: 'enable', data: [] }, width: 80, align: 'left' },
                { field: 'Remarks', title: '备注', type: 'string', width: 110, align: 'left' },
            ],
            sigle: true,
            selectRow: {},
            leftTableData: []
        };
    },
    setup() {
        return {}
    },
    methods: {
        init() {
            this.model = true;
            // this.formRules.forEach(item => {
            //     if(item.field == "DeptID"){
            //         debugger
            //         item.onChange = (val)=>{
            //             this.http.post(`/api/OA_Department/getArea?id=${val}`,null,true).then(res=>{
            //                 console.log(res)
            //                 var areaList = source.map(it => ({ key: it.id, value: it.name }));;
            //                 this.formRules[1].data = areaList
            //                 console.log(this.formRules)
            //             })
            //         }
            //     }
            // });
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytableLeft.reset();
                this.$refs.mytableLeft.load();
                //this.$refs.mytableRight.reset();
                this.leftTableData = this.selectRow.Qua_CheckContItemArea || []
            });
        },
        openDemo() {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytableLeft.reset();
                this.$refs.mytableLeft.load();
                // this.$refs.mytableRight.reset();
                //this.$refs.mytableRight.load();
            });
        },
        load() {
            this.$refs.mytableLeft.load();
        },
        reset() {
            this.$refs.myform.reset();
            this.$refs.mytableLeft.reset();
        },
        addRow() {
            var rows = this.$refs.mytableLeft.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            var addIds = rows.map(x => x.PositionID)
            var filter = this.leftTableData.filter(x => addIds.includes(x.PositionID))
            if (filter.length > 0) {
                return this.$message.error("已添加，请勿重复添加");
            }
            rows.forEach(row => {
                this.$refs.mytableRight.addRow({
                    "PositionID": row.PositionID, "Name": row.Name, "Code": row.Code,
                    "LatitudeLongitude": row.LatitudeLongitude, "IsEnable": row.IsEnable, "Remarks": row.Remarks
                });
            })
        },
        delRow() {
            var rows = this.$refs.mytableRight.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$refs.mytableRight.delRow(rows[0].PositionID);
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            params.rows = 100
            if (!this.base.checkEmpty(this.formFileds.DeptID)) {
                params.wheres.push({ name: "DeptID", value: this.formFileds.DeptID[this.formFileds.DeptID.length - 1] });
            }
            if (!this.base.checkEmpty(this.formFileds.AreaPosName)) {
                params.wheres.push({ name: "AreaID", value: this.formFileds.AreaPosName });
            }
            // params.wheres.push({ name: "IsRegulation", value: 1 });
            return true;
        },
        save() {
            this.handleClick(this.leftTableData)
        },
        AreaChange(val) {
            var value = val[val.length - 1];
            this.http.post(`/api/Sys_Department/getArea?id=${value}`, [{"headers": {'Content-Type': 'application/json'}}] , true).then(res => {
                var areaList = res.map(it => ({ key: it.areaID, value: it.name }));;
                this.formRules[0][1].data = areaList;
            })
        }
    },
    emits: ['AreasCallBack', 'CloseAreasBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('AreasCallBack', item)
        }
        const closeBox = (item) => {
            emit('CloseAreasBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
    justify-content: space-between;
    /* 在子元素之间分配空间 */
    width: 100%;
    /* 确保容器占据整个可用宽度 */
}

.table-wrapper {
    flex: 1;
    /* 使每个表格占据相等的空间，或者您可以根据需要设置具体的宽度 */
    /* 其他样式，如边距、内边距等 */
}
</style>