<template>
    <VolBox v-model="model" :lazy="true" title="选择资产" :height="540" :width="900" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请选择数据</span>
            <el-input placeholder="关键字查询" style="width: 200px" v-model="searchKey" />
            <el-button type="primary" style="margin-left:10px" size="medium" @click="search"><i
                    class="el-icon-zoom-out"></i>搜索</el-button>
        </div>

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :max-height="380" :url="url" :index="true"  :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="mini" type="primary" @click="addRow()"><i class="el-icon-plus"></i>添加选择的数据</el-button>
                <el-button size="mini" @click="model = false; closeAssetBox()"><i
                        class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
    import VolBox from "@/components/basic/VolBox.vue";
    import VolTable from "@/components/basic/VolTable.vue";

    export default {
        components: {
            VolBox: VolBox,
            VolTable: VolTable,
        },
        props: {
            selectRow: Object,
        },
        data() {
            return {
                searchKey: "",
                model: false,
                defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
                EmpID: '', //查询条件字段
                depID: '',//查询条件
                SearchType: 0,// 筛选条件，默认0：CEmpid为使用人和保管人，1：CEmpid为保管人,2：未分配人
                url: "api/OA_Asset/getUserRelatedAsset",//加载数据的接口
                columns: [
                    { field: "TypeID", title: "类型", width: 90, bind: { key: "资产类型", data: [] } },
                    { field: "AutoNum", title: "资产编号", width: 90 },
                    {"dataKey":"仓库-tree","data":[],"title":"所属仓库","field":"WhseID","type":"cascader", width: 120},
                    { field: "DeptName", title: "所属部门", width: 120 },
                    { field: "Assetname", title: "资产名称", width: 120 },
                    { field: "Brand", title: "品牌", width: 120 },
                    { field: "Model", title: "型号", width: 120 },
                    { field: "Use_name_str", title: "使用人", width: 120 },
                    { field: "Keeper_name_str", title: "保管人", width: 120 },
                    { field: "Location", title: "存放位置", width: 120 },
                    { field: "Keeper_name", title: "保管人", hidden: true },
                    { field: "Use_name", title: "使用人", hidden: true },
                    { field: "Original_val", title: "原值", hidden: true },
                    { field: "Using_date", title: "购买时间", hidden: true },
                    { field: "Depr_age", title: "折旧年限", hidden: true },
                    { field: "ResidualValueRate", title: "残值率", hidden: true },
                    {
                        field: "opt",
                        title: "操作",
                        render: (h, { row, column, index }) => {
                            return h(
                                "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                                h(
                                    "a", {
                                    style: {},
                                    class: [],
                                    onClick: (e) => {
                                        e.stopPropagation()
                                        this.$emit("parentCall", ($parent) => {
                                            $parent.selectAsset(row);
                                        })
                                        this.selectAssetCall(row)
                                        // this.handleClick(row)
                                        //关闭当前窗口
                                        this.model = false;
                                    }
                                },
                                    "选择"
                                ),
                            ])
                        }
                    },
                ],
                pagination: {}, //分页配置，见voltable组件api
            };
        },
        methods: {
            load(CEmpid, SearchType, depID) {
                this.EmpID = CEmpid;
                this.depID = depID;
                if (SearchType) {
                    this.SearchType = SearchType;
                }
                this.$refs.mytable.load();
            },
            openDemo(row) {
                this.model = true;
                //打开弹出框时，加载table数据
                this.$nextTick(() => {
                    this.$refs.mytable.load();
                });
            },
            search() {
                //点击搜索
                this.$refs.mytable.load();
            },
            addRow() {
                var rows = this.$refs.mytable.getSelected();
                if (!rows || rows.length == 0) {
                    return this.$message.error("请选择行数据");
                }
                this.$emit("parentCall", ($parent) => {
                    $parent.selectAsset(rows);
                })
                this.handleClick(rows)
                //关闭当前窗口
                this.model = false;
            },
            //这里是从api查询后返回数据的方法
            loadTableAfter(row) { },
            //查询前
            loadTableBefore(params) {
                if (this.EmpID) {
                    if (this.SearchType == 0) { //同时匹配使用人和保管人
                        params.wheres.push({ name: "EmpID", value: this.EmpID });
                    } else if (this.SearchType == 1) { //只匹配保管人
                        params.wheres.push({ name: "Keeper_name", value: this.EmpID });
                    }
                }
                if (this.SearchType == 2) { //未分配使用人和保管人
                    params.wheres.push({ name: "NoneEmp", value: this.EmpID });
                }
                if (this.depID) {
                    params.wheres.push({ name: "depID", value: this.depID });
                }
                if (this.searchKey) {
                    params.wheres.push({ name: "searchKey", value: this.searchKey });
                }
                return true;
            },
            selectAssetCall(row) {
                this.$emit("assetCallBack", row);
            },
        },
        emits: ['AssetCallBack', 'CloseAssetBox'],
        setup(props, { emit }) {
            const handleClick = (item) => {
                emit('AssetCallBack', item)
            }
            const closeAssetBox = (item) => {
                emit('CloseAssetBox', item)
            }
            return {
                handleClick,
                closeAssetBox
            }
        }
    }

</script>