<template>
    <VolBox v-model="model" :lazy="true" title="选择区域" :height="540" :width="900" :padding="15">
        <div style="height: 550px;">
            <div>
                <div style="padding-bottom: 10px" v-show="this.base.checkEmpty(areaid)">
                    <span style="margin-right: 20px">请输入</span>
                    <el-input placeholder="区域名称" style="width: 250px" v-model="name" />
                    <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i
                            class="el-icon-search"></i>搜索区域</el-button>
                </div>

                <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination-hide="true" :url="url" :index="true"
                    :sigle="sigle" :defaultLoadPage="defaultLoadPage" @loadBefore="loadTableBefore"
                    @loadAfter="loadTableAfter"></vol-table>
            </div>
        </div>

        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()"><i
                        class="el-icon-plus" v-show="!sigle"></i>添加数据</el-button>
                <el-button size="medium" @click="closeBox();"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>

<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {
        selectRow: Object
    },
    data() {
        return {
            model: false,
            name: '',
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            url: "api/Qua_Area/getPageData",//加载数据的接口
            columns: [
                { field: "AreaID", title: "AreaID", width: 90, hidden: true },
                { field: "Name", title: "区域名称", width: 100 },
                { field: 'DeptID', title: '所属部门', width: 200, type: 'string', bind: { key: 'dept', type: "select", data: [] } },
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: async (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.selectSpotCheckArea(row);
                                    })
                                    this.spotCheckAreaCallBack(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            pagination: {}, //分页配置，见voltable组件api
            sigle: true,
        };
    },
    methods: {
        load() {
            this.$refs.mytable.load();
        },
        openDemo(item) {
            this.model = true;
            this.name = ''
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.mytable.load();
        },
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.selectSpotCheckArea(rows.length <= 1 ? rows[0] : rows);
            })
            this.spotCheckAreaCallBack(rows.length <= 1 ? rows[0] : rows)
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { 
            
        },
        loadTableBefore(params) {
            //查询前，设置查询条件
            if (!this.base.checkEmpty(this.name)) {
                params.wheres.push({ name: "Name", value: this.name, displayType: 'like' });
            }
            params.row = 1000;
            return true;
        },
        closeBox() {
            this.model = false;
        }
    },
    emits: ["SpotCheckAreaCallBack", "CloseSpotCheckAreaBox"],
    setup(props, { emit }) {
        const spotCheckAreaCallBack = (item) => {
            emit('SpotCheckAreaCallBack', item)
        }
        const closeSpotCheckAreaBox = (item) => {
            emit('CloseSpotCheckAreaBox', item)
        }
        return {
            spotCheckAreaCallBack,
            closeSpotCheckAreaBox,
        }
    },
};
</script>

<style lang="less">
.left-bar {
    float: left;
    width: 600px;
    height: 100%;
    position: absolute;
    overflow: auto;
    border: 1px solid #cbcbcb5e;
    padding: 5px;
}

.right-bar {
    border: 1px solid #cbcbcb5e;
    padding: 5px;
    float: right;
    right: 0px;
    width: 280px;
    height: 100%;
    position: absolute;
    overflow: auto;
}
</style>