<template>
    <VolBox v-model="model" :lazy="true" title="选择合同" :height="540" :width="800" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="可输入合同编号、合同名称、合同方" style="width: 250px" v-model="CompName" @keyup.enter="search()"/>
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i class="el-icon-search" ></i>搜索</el-button>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :max-height="380" :url="url" :index="true" :single="sigle" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false;closeContractCompBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {
        selectRow: Object
    },
    data() {
        
        return {
            model: false,           
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            CompName: "", //查询条件字段
            url: "api/OA_ContractCompliance/getSelectContractComplianceList",//加载数据的接口
            columns: [
                {field: "ComplianceID", title: "合同id", width: 90, hidden: true },
                {field:'ContractName',title:'合同名称',width:150},
                {field:'DeptID',title:'所属部门',width:100,type:'string',bind:{ key:'dept-treeSelect',type:"treeSelect",data:[]}},
                // {field:'SignEmpName',title:'签署人',width:100},
                {field: "ContractNO", title: "合同编号", width: 120},
                {field: "PartyA", title: "合同甲方", width: 120},
                {field: "PartyB", title: "合同乙方", width: 120},
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.SelectContractComp(row);
                                    })
                                    //this.selectCompCall(row)
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            pagination: {size:120}, //分页配置，见voltable组件api
            sigle: true            
        };
    },
    methods: {
        load(){
            this.$refs.mytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
       
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.SelectContractComp(rows[0]);
            })
            this.handleClick(rows[0]);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
          
            //查询前，设置查询条件
            if (this.CompName) {
                params.wheres = [{ name: "cont", value: this.CompName }];
            }
            //var EmpID = this.$store.getters.data().Emp.EmpID;
            // if (EmpID !== undefined) {
            //         params.wheres.push({ name: 'EmpID', value: EmpID })
            //     }
            return true;
        },
        // selectEmpCall(row){
        //     this.$emit("selectEmpCall", row);
        // }
    },
    emits: ['ContractCompCallBack','CloseContractCompBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('ContractCompCallBack', item)
        }
        const closeContractCompBox = (item) => {
            emit('CloseContractCompBox', item)
        }
        return {
            handleClick,
            closeContractCompBox
        }
    }
};
</script>
  