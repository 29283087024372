import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import form from './form'
import formsMulti from './formsMulti'
import tables from './tables'
import viewgird from './viewGird'
import store from '../store/index'
import redirect from './redirect'
import charts from './charts'
const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index'),
    redirect: '/home',
    children: [
      ...form,//Demo表单路由
      ...formsMulti,
      ...tables,//tables
      ...viewgird,
      ...redirect,
      ...charts,
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
          keepAlive: false
        }
      }, {
        path: '/UserInfo',
        name: 'UserInfo',
        component: () => import('@/views/system/UserInfo.vue')
      },
      {
        path: '/sysMenu',
        name: 'sysMenu',
        component: () => import('@/views/system/Sys_Menu.vue')
      }, {
        path: '/coder',
        name: 'coder',
        component: () => import('@/views/builder/coder.vue')
      },
      {
        path: '/formDraggable',  //表单设计
        name: 'formDraggable',
        component: () => import('@/views/formDraggable/formDraggable.vue')
      },
      {
        path: '/formSubmit',  //表单提交页面
        name: 'formSubmit',
        component: () => import('@/views/formDraggable/FormSubmit.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/formCollectionResultTree',  //显示收集的数据表单
        name: 'formCollectionResultTree',
        component: () => import('@/views/formDraggable/FormCollectionResultTree.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/signalR',
        name: 'signalR',
        component: () => import('@/views/signalR/Index.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/flowdemo', //发起流程示例
        name: 'flowdemo',
        component: () => import('@/views/system/flow/FlowDemo.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/Sys_Notice',
        name: 'Sys_Notice',
        component: () => import('@/views/system/system/Sys_Notice.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/Sys_Message',
        name: 'Sys_Message',
        component: () => import('@/views/system/system/Sys_Message.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/EmployeeApplyList',
        name: 'EmployeeApplyList',
        component: () => import('@/views/oa/oa/EmployeeApplyList.vue'),
        meta: {
          keepAlive: false
        }
      },
	    {
        path: '/Sys_TrialPeriodTracking',
        name: 'Sys_TrialPeriodTracking',
        component: () => import('@/views/oa/oa/Sys_TrialPeriodTracking.vue'),
        meta: {
          keepAlive: false
        }
      },

      {
        path: '/OA_Award',
        name: 'OA_Award',
        component: () => import('@/views/oa/oa/OA_Award.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/AwardList',
        name: 'AwardList',
        component: () => import('@/views/oa/oa/AwardList.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/LeaveApplyList',
        name: 'LeaveApplyList',
        component: () => import('@/views/oa/oa/LeaveApplyList.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/OA_LeaveApply',
        name: 'OA_LeaveApply',
        component: () => import('@/views/oa/oa/OA_LeaveApply.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/Sys_Employee_Apply',
        name: 'Sys_Employee_Apply',
        component: () => import('@/views/oa/oa/Sys_Employee_Apply.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/MeetingReserveList',
        name: 'MeetingReserveList',
        component: () => import('@/views/oa/oa/MeetingReserveList.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/OA_MeetingReserve',
        name: 'OA_MeetingReserve',
        component: () => import('@/views/oa/oa/OA_MeetingReserve.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/Sys_CompanyRules',
        name: 'Sys_CompanyRules',
        component: () => import('@/views/system/system/Sys_CompanyRules.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/Sys_InformationBase',
        name: 'Sys_InformationBase',
        component: () => import('@/views/system/system/Sys_InformationBase.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/todo',
        name: 'todo',
        component: () => import('@/views/system/flow/Sys_WorkFlowTable.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/done',
        name: 'done',
        component: () => import('@/views/system/flow/Sys_WorkFlowTable.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/Sys_EmpBackgroundCheck',
        name: 'Sys_EmpBackgroundCheck',
        component: () => import('@/views/oa/oa/Sys_EmpBackgroundCheck.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/OA_EmployeeTurnover',
        name: 'OA_EmployeeTurnover',
        component: () => import('@/views/oa/oa/OA_EmployeeTurnover.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/EmployeeTurnoverList',
        name: 'EmployeeTurnoverList',
        component: () => import('@/views/oa/oa/EmployeeTurnoverList.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/OA_TurnoverHandover',
        name: 'OA_TurnoverHandover',
        component: () => import('@/views/oa/oa/OA_TurnoverHandover.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/Qua_CheckProject',
        name: 'Qua_CheckProject',
        component: () => import('@/views/system/quality/Qua_CheckProject.vue'),
        meta: {
          keepAlive: false
        }
      },
      {
        path: '/CheckProjectList',
        name: 'CheckProjectList',
        component: () => import('@/views/system/quality/CheckProjectList.vue'),
        meta: {
          keepAlive: false
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/PrintAsset',
    name: 'PrintAsset',
    component: () => import('@/views/oa/Asset/AssetPrint.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/PurchasePrint',
    name: 'PurchasePrint',
    component: () => import('@/views/system/purchase/PurchasePrint.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/PaymentApplyPrint',
    name: 'PaymentApplyPrint',
    component: () => import('@/views/system/purchase/PaymentApplyPrint.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/ExpensesClaimPrint',
    name: 'ExpensesClaimPrint',
    component: () => import('@/views/system/finance/ExpensesClaimPrint.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/app/guide',
    name: 'apphome',
    meta: {
      anonymous: true
    },
    component: () => import('@/views/h5/Guide.vue'),
  },
  {
    path: '/bigdata',
    name: 'bigdata',
    component: () => import('@/views/charts/bigdata.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/PrintReportRepair',
    name: 'PrintReportRepair',
    component: () => import('@/views/oa/oa/PrintReportRepair.vue'),
    meta: {
      anonymous: true
    }
  },
  {
    path: '/SewageBigData',
    name: 'SewageBigData',
    component: () => import('@/views/sewage/SewageBigData.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/SewageIndex',
    name: 'SewageIndex',
    component: () => import('@/views/sewage/SewageIndex.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/SewageParamater',
    name: 'SewageParamater',
    component: () => import('@/views/sewage/SewageParamater.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/SewageMap',
    name: 'SewageMap',
    component: () => import('@/views/sewage/SewageMap.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/SewageFlowChart',
    name: 'SewageFlowChart',
    component: () => import('@/views/sewage/SewageFlowChart.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/Sys_MsgLog',
    name: 'Sys_MsgLog',
    component: () => import('@/views/system/Sys_MsgLog.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/QuaSpotCheckPrint',
    name: 'QuaSpotCheckPrint',
    component: () => import('@/extension/system/quality/QuaSpotCheck/QuaSpotCheckPrint.vue'),
    meta: {
      anonymous: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(), //createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.length == 0) return next({ path: '/404' });
  //2020.06.03增加路由切换时加载提示
  store.dispatch("onLoading", true);
  if ((to.hasOwnProperty('meta') && to.meta.anonymous) || store.getters.isLogin() || to.path == '/login') {
    return next();
  }

  next({ path: '/login', query: { redirect: Math.random() } });
})
router.afterEach((to, from) => {
  store.dispatch("onLoading", false);
})
router.onError((error) => {
  // const targetPath = router.currentRoute.value.matched;
  try {
    //console.log(error.message);
    if (process.env.NODE_ENV == 'development') {
      alert(error.message)
    }
    localStorage.setItem("route_error", error.message)
  } catch (e) {

  }
  console.log(error);
  // window.location.href = '/'
});
export default router
