<template>
    <VolBox v-model="model" :lazy="true" title="选择检查设备" :height="740" :width="width" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <!-- <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="请输入编码、描述、备注、层级" style="width:500px" v-model="Name" @keyup.enter="load()" />
            <el-button size="medium" type="primary" style="margin-left:10px" @click="load"><i
                    class="el-icon-search"></i>搜索</el-button> -->
            <vol-form ref="myform" :labelWidth="80" :load-key="true" :formFields="formFileds" :formRules="formRules">
            <div style="text-align: right;">
                <el-button type="primary" @click="load" size="mini"><i class="el-icon-search"></i>查询</el-button>
                <el-button type="primary" @click="reset" plain size="mini"><i
                        class="el-icon-refresh-right"></i>重置</el-button>
            </div>
        </vol-form>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="true"
            :height="540" :url="url" :index="true" :single="sigle" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false; closeBox()"><i
                        class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {
        
    },
    data() {
        return {
            width: document.body.clientWidth * 0.7,
            model: false,
            defaultLoadPage: false,
            Name: "", //查询条件字段
            DeviceType: -1,
            formFileds: {
                "ContainChild": "", "CompNumber1": "", "Desc1": "", "Level1": "", "Status1": "","Type1":""
            },
            formRules: [
                [
                    { dataKey: "设备类型", "data": [], "title": "设备类型", "field": "Type1", "type": "select" },
                    { "title": "编码", "field": "CompNumber1", "type": "text" },
                    { "dataKey": "ContainChild", "data": [], "title": "包含下级", "field": "ContainChild", "type": "switch" },
                    { "title": "描述/备注", "field": "Desc1", "type": "like" },
                    { "title": "层级", "field": "Level1", "type": "text" },
                    { dataKey: "FacilityStatus", "data": [], "title": "状态", "field": "Status1", "type": "select" },
                ]
            ],
            url: "api/Sys_Facility_Library/GetPageData",//加载数据的接口
            columns: [
                { field: 'ID', title: 'ID', type: 'long', width: 80, hidden: true, readonly: true, require: true, align: 'left', sort: true },
                // { field: 'Number', title: '本级编码', type: 'string', width: 80, require: true, align: 'left' },
                // { field: 'Desc', title: '本级描述', type: 'string', width: 120, require: true, align: 'left' },
                { field: 'CompNumber', title: '完整编码', type: 'string', width: 180, require: true, align: 'left' },
                { field: 'CompDesc', title: '完整描述', type: 'string', width: 280, require: true, align: 'left' },
                { field: 'Level', title: '层级', type: 'byte', width: 60, require: true, align: 'left' },
                { field: 'Location', title: '位置', type: 'string', width: 160, require: true, align: 'left' },
                { field: 'Status', title: '状态', type: 'int', bind: { key: 'FacilityStatus', data: [] }, width: 60, require: true, align: 'left' },
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    // this.$emit("parentCall", ($parent) => {
                                    //     $parent.SelectPurApply(row);
                                    // })
                                    //this.selectCompCall(row)
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            sigle: false,
        };
    },
    methods: {
        init(obj){
            this.model = true;
            this.Name = obj.Name || ''
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytable.reset();
                this.formFileds.Type1 = obj.DeviceType || -1
            });
        },
        load() {
            this.$refs.mytable.load();
        },
        reset(){
            this.$refs.myform.reset()
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },

        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            // this.$emit("parentCall", ($parent) => {
            //     $parent && $parent.SelectDevice(rows);
            // })
            this.handleClick(rows);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            params.rows = 5000
            //查询前，设置查询条件
            // if (this.Name) {
            //     params.wheres.push({ name: "search", value: this.Name });
            // }
            for (var i in this.formFileds) {
                if (!this.base.checkEmpty(this.formFileds[i])) {
                    params.wheres.push({ name: i, value: this.formFileds[i] });
                }
            }
            if(this.formFileds.Type1 != '' && this.formFileds.Type1 != -1){
                params.wheres.push({ name: "Type1", value: this.formFileds.Type1 });
            }
            return true;
        },
    },
    emits: ['DeviceCallBack', 'CloseDeviceBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('DeviceCallBack', item)
        }
        const closeBox = (item) => {
            emit('CloseDeviceBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
};
</script>