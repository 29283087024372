<template>
    <VolBox v-model="model" :lazy="true" title="选择检查明细项" :height="540" :width="width" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="检查项、位置" style="width: 250px" v-model="Name" @keyup.enter="search()" />
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i
                    class="el-icon-search"></i>搜索</el-button>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :max-height="380" :url="url" :index="true" :single="sigle" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false; closePurApplyBox()"><i
                        class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>

        <VolBox :lazy="true" v-model="showPurchase" title="检查项目" :width="width" :onModelClose="closeBox">
            <div style="height: 20px;width:20px">
                <purchase ref="purchase"></purchase>
            </div>
        </VolBox>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {
        selectRow: Object
    },
    data() {

        return {
            width: document.body.clientWidth * 0.7,
            model: false,
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            Name: "", //查询条件字段
            url: "api/Qua_CheckProjectItem/GetPageData",//加载数据的接口
            columns: [
                {field:'ItemID',title:'ItemID',type:'int',width:110,hidden:true,readonly:true,require:true,align:'left'},
                       {field:'ProjectID',title:'ProjectID',type:'int',width:110,hidden:true,require:true,align:'left'},
                       {field:'Item',title:'检查明细项',type:'string',width:180,require:true,align:'left',sort:true},
                       {field:'Area',title:'位置',type:'string',width:180,require:true,align:'left'},
                       {field:'Code',title:'检查编码',type:'string',width:110,align:'left'},
                       {field:'ProjectTypeID',title:'类别',type:'int',bind:{ key:'质检内容类型',data:[]},width:110,require:true,align:'left'},
                       {field:'Remarks',title:'扣分标准',type:'textarea',width:220,align:'left',require:true},
                       {field:'CheckTypeID',title:'检查类型',type:'string',bind:{ key:'检查类型',data:[]},width:110,require:true,align:'left'},
                       {field:'IsMain',title:'重点检查项',type:'int',bind:{ key:'enable',data:[]},width:110,require:true,align:'left'},
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.SelectPurApply(row);
                                    })
                                    //this.selectCompCall(row)
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            // pagination: {size:100}, //分页配置，见voltable组件api
            sigle: true,
            auditStatus: ''
        };
    },
    methods: {
        load() {
            this.$refs.mytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },

        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.selectQuaCheckItem(rows[0]);
            })
            this.handleClick(rows[0]);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {

            //查询前，设置查询条件
            if (this.Name) {
                params.wheres = [{ name: "search", value: this.Name }];
            }
            return true;
        },
    },
    emits: ['QuaCheckItemCallBack', 'CloseQuaCheckItemBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('QuaCheckItemCallBack', item)
        }
        const closeBox = (item) => {
            emit('CloseQuaCheckItemBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
};
</script>