<template>
    <div class="progress" :class="[status ? `is-${status}` : '', `progress--${type}`]" v-show="showLoding || progressFlag">
        <div style="width: 126px;height: 126px;margin: auto;" v-show="progressFlag">
            <div class="progress-circle" :style="{ width: width + 'px', height: width + 'px' }">
                <el-progress type="circle" show-text="true" :percentage="progressPercent"></el-progress>
            </div>
        </div>
        <div style="width: 150px;height: 150px;margin: auto;" v-show="showLoding">
            <div style="width: 150px;color: #1a89ff;font-size: 14px;" class="progress__text">
                正在处理文件...
            </div>
        </div>
    </div>
</template>

<script>
import Bus from 'vue3-eventbus'

export default {
    props: {
        type: {
            type: String,
            default: 'circle',
            validator: val => ['circle', 'line'].includes(val)
        },
        strokeWidth: {
            type: Number,
            default: 6
        },
        percentage: {
            type: Number,
            required: true,
            default: 0,
            validator: val => val >= 0 && val <= 100
        },
        status: {
            type: String
        },
        textInside: {
            type: Boolean,
            default: false
        },
        showText: {
            type: Boolean,
            default: true
        },
        color: {
            type: String
        },
        width: {
            type: Number,
            default: 126
        }
    },
    mounted() {
        Bus.on('openProgress', (item) => {
            this.showLoding = false;
            this.progressFlag = true;
            this.progressPercent = item.progressPercent
            this.status = 'line'
            if (this.progressPercent >= 100) {
                this.status = 'success';
            }
        })
        Bus.on('closeProgress', () => {
            this.progressFlag = false;
            this.progressPercent = 0
            this.showLoding = false;
        })
        Bus.on('openProgressLoading', (item) => {
            this.showLoding = true;
            this.progressFlag = false;
        })
        Bus.on('closeProgressLoading', () => {
            this.showLoding = false;
            this.progressFlag = false;
        })
    },
    data(){
        return{
            progressFlag: false,
            progressPercent: 0,
            status: 'info',
            showLoding: false
        }
    },
    computed: {
        progressTextSize() {
            return 12 + this.strokeWidth * 0.4;
        },
        stroke() {
            if (this.color) {
                return this.color;
            }
            let color;
            switch (this.status) {
                case 'success':
                    color = '#13ce66';
                    break;
                case 'exception':
                    color = '#ff4949';
                    break;
                default:
                    color = '#20a0ff';
            }
            return color;
        },
        barStyle() {
            return {
                width: this.percentage + '%',
                backgroundColor: this.stroke
            }
        },
        iconClass() {
            if (this.type === 'line') {
                return this.status === 'success'
                    ? 'icon-circle-success'
                    : 'icon-circle-cancel';
            } else {
                return this.status === 'success'
                    ? 'icon-success'
                    : 'icon-cancel';
            }
        },
        trackPath() {
            const radius = 50 - this.relativeStrokeWidth / 2;
            return `
            M 50 50
            m 0 -${radius}
            a ${radius} ${radius} 0 1 1 0 ${radius * 2}
            a ${radius} ${radius} 0 1 1 0 -${radius * 2}`;
        },
        relativeStrokeWidth() {
            return this.strokeWidth * 100 / this.width;
        },
        perimeter() {
            const radius = 50 - this.relativeStrokeWidth / 2;
            return 2 * Math.PI * radius;
        },
        circlePathStyle() {
            const perimeter = this.perimeter;
            return {
                strokeDasharray: `${perimeter}px,${perimeter}px`,
                strokeDashoffset: (1 - this.percentage / 100) * perimeter + 'px'
            }
        }
    }
}
</script>
  
<style>
@font-face {
    font-family: 'icon';
    /* Project id 2735378 */
    /* src: url('icon/iconfont.ttf') format('woff2'),
        url('./icon/iconfont.woff') format('woff'),
        url('./icon/iconfont.woff2') format('truetype'); */
}

.icon {
    font-family: 'icon' !important;
    font-size: 16px;
    font-style: normal;
}

.icon-circle-success::before {
    content: '\e605';
}

.icon-circle-cancel::before {
    content: '\e615';
}

.icon-success::before {
    content: '\e72e';
}

.icon-cancel::before {
    content: '\e625';
}

.progress{
    width: 100%;
    height: 100%;
    background: rgb(175 171 171 / 24%);
    z-index: 999999;
    position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
}

.progress.is-success .progress__text {
    color: #67c23a;
}

.progress.is-info .progress__text {
    color: blue;
}

.progress.is-exception .progress__text {
    color: #f56c6c;
}

.progress-bar {
    display: inline-block;
    box-sizing: border-box;
    /*width: calc(100% - 50px);*/
    width: 100%;
    padding-right: 50px;
    margin-right: -50px;
}

.progress-bar__outer {
    border-radius: 100px;
    background-color: #ebeef5;
}

.progress-bar__inner {
    width: 60px;
    height: 100%;
    border-radius: 100px;
    transition: width .6s ease;
    text-align: right;
    line-height: 1;
}

.progress-bar__innerText {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    margin: 0 5px;
    vertical-align: middle;
}

.progress__text {
    display: inline-block;
    margin-left: 10px;
    color: #606266;
}

.progress-circle {
    display: inline-block;
    position: relative;

}

.progress-circle,.progress__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    margin-left: 0;
}
</style>
  