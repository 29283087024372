<template>
    <VolBox v-model="show" :lazy="true" title="数据权限设置" :height="540" :width="800" :padding="15">
        <vol-form ref="form" :load-key="true" :label-width="80" :formRules="formOptions"
            :formFields="formFields"></vol-form>
        <template #footer>
            <div>
                <el-button size="mini" type="primary" @click="save()"><i class="el-icon-plus"></i>保存</el-button>
                <el-button size="mini" @click="close()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
        <SelectEmp v-model="showEmp" ref="selectEmp" @EmpCallBack="empCallBack" @CloseEmpBox="closeEmpBox"></SelectEmp>
    </VolBox>

</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import SelectEmp from '@/components/custom/SelectEmp.vue'

export default {
    components: {
        'vol-form': VolForm,
        VolBox: VolBox,
        SelectEmp: SelectEmp
    },
    props: {
        selectRow: Object,
    },
    data() {
        return {
            show: false,
            showEmp: false,
            formFields: { "UserIDs": "", "DeptIDs": "", "RoleIDs": "", "UserNames": "", "PositionIDs": "" },
            formOptions: [
                [
                    {
                        field: "UserNames", title: "按用户", type: "popup", popup: {
                            idfield: "UserIDs",
                            click: (e) => {
                                this.showEmp = true;
                                this.$nextTick(() => {
                                    this.$refs.selectEmp.model = true;
                                    this.$refs.selectEmp.sigle = false;
                                    this.$refs.selectEmp.reset();
                                    this.$refs.selectEmp.formFields.status = "1,3";
                                    this.$refs.selectEmp.load();
                                })
                            }
                        }
                    },
                    // { field: "UserIDs", title: "按用户", type: "selectList", data: [], dataKey: "用户列表" },
                ],
                [{ title: "按部门", field: "DeptIDs", type: "treeSelect", data: [], dataKey: "dept-treeSelect" }],
                [{ title: "按角色", field: "RoleIDs", type: "treeSelect", data: [], dataKey: "tree_roles" }],
                [{ title: "按职位", field: "PositionIDs", type: "selectList", data: [], dataKey: "职位" }],
            ],
            data: {

            }
        };
    },
    methods: {
        initData() {
            this.formFields = {
                "UserIDs": "", "DeptIDs": "", "RoleIDs": "", "UserNames": "", "PositionIDs": ""
            }
        },
        open(data) {
            this.show = true;
            this.$nextTick(() => {
                this.initData();
                this.$refs.form.reset()
                this.data = data
                this.formFields.UserIDs = this.data.row.UserIDs
                this.formFields.UserNames = this.data.row.UserNames
                if (this.data.row.DeptIDs) {
                    this.formFields.DeptIDs = this.base.getStringArray(this.data.row.DeptIDs, ',')
                }
                if (this.data.row.RoleIDs) {
                    this.formFields.RoleIDs = this.base.getStringArray(this.data.row.RoleIDs, ',')
                }
                if (this.data.row.PositionIDs) {
                    this.formFields.PositionIDs = this.base.getStringArray(this.data.row.PositionIDs, ',')
                }
            })
        },
        save() {
            this.data.row.UserIDs = this.formFields.UserIDs
            this.data.row.DeptIDs = this.base.checkEmpty(this.formFields.DeptIDs) ? "" : this.formFields.DeptIDs.join(",")
            this.data.row.RoleIDs = this.base.checkEmpty(this.formFields.RoleIDs) ? "" : this.formFields.RoleIDs.join(",")
            this.data.row.PositionIDs = this.base.checkEmpty(this.formFields.PositionIDs) ? "" : this.formFields.PositionIDs.join(",")
            this.http.post("api" + this.data.table.url + "update", { mainData: this.data.row }).then(res => {
                this.initData();
                this.$refs.form.reset()
                this.show = false;
                this.handleClick(true)
            })
        },
        close() {
            this.initData();
            this.$refs.form.reset()
            this.show = false;
            this.closeBox()
        },
        empCallBack(row) {
            if ((!(row instanceof Array))) {
                row = [row]
            }
            var userids = this.base.checkEmpty(this.formFields.UserIDs) ? [] : this.base.getIntArray(this.formFields.UserIDs, ',')
            var usernames = this.base.checkEmpty(this.formFields.UserNames) ? [] : this.base.getStringArray(this.formFields.UserNames, ',')
            row.forEach(x => {
                var filters = userids.filter(u => u == x.userID)
                if (filters == null || filters.length <= 0) {
                    userids.push(x.userID)
                    usernames.push(x.empName)
                }
            })
            this.formFields.UserIDs = userids.join(',')
            this.formFields.UserNames = usernames.join(',')
            this.closeEmpBox()
        },
        closeEmpBox() {
            this.showEmp = false
        }
    },
    emits: ['DataPermissionCallBack', 'CloseDataPermissionBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('DataPermissionCallBack', item)
        }
        const closeBox = (item) => {
            emit('CloseDataPermissionBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
}

</script>