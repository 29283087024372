<!--
 * @Author: leonli
 * @Date: 2024-06-18 14:19:52
 * @LastEditors: leonli
 * @LastEditTime: 2024-07-23 18:20:22
 * @Description: 
 * @FilePath: /yd.new.web/src/App.vue
-->
<template>
  <div id="nav"></div>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>

<d-progress />

</template>
<script>
import { ElConfigProvider } from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
//引入全局进度条组件
import DProgress from "./components/basic/Progress";

export default {
  name: "vol_app",
  components: {
    [ElConfigProvider.name]: ElConfigProvider, //添加组件
    DProgress
  },
  data() {
    return {
      locale: zhCn,
    };
  },
};
</script>
<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

.el-alert--error.is-light {
  border: 1px solid #ffe0e0;
}

.el-alert--error.is-light {
  color: #f74444 !important;
}

.el-alert--warning.is-light {
  border: 1px solid #ffe6c1;
}

.el-alert--info.is-light {
  border: 1px solid #e6e5e5;
}

.el-alert--info .el-alert__description {
  color: #6b6b6b !important;
}

.el-alert--warning.is-light {
  background-color: #fdf6ec;
  color: #d68409 !important;
}

.el-alert--success.is-light {
  border: 1px solid #cdf7b8;
}

.el-alert--success.is-light .el-alert__description {
  color: #3baf02 !important;
}
</style>
