<template>

    <VolBox v-model="model" :lazy="true" title="选择员工" :height="540" :width="1000" :padding="15">
        <!-- 设置查询条件 -->
        <!-- <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入员工名称</span>
            <el-input placeholder="员工名称" style="width: 200px" v-model="empName" />
            <el-button type="primary" style="margin-left:10px" size="medium" icon="el-icon-zoom-out"
                @click="search">搜索</el-button>
        </div> -->

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :max-height="380" :url="url" :index="true" :single="true" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="mini" type="primary" @click="addRow()"><i class="el-icon-plus"></i>添加选择的数据</el-button>
                <el-button size="mini" @click="model = false;closeTackingBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>

</template>
<script>
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
    },
    props: {
        selectRow: Object
    },
    data() {
        return {
            model: false,
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            searchKey: "", //查询条件字段
            url: "api/Sys_TrialPeriodTracking/getSelectTrialList",//加载数据的接口
            columns: [
                { field: "TackingID", title: "员工试用期跟踪ID", width: 90, hidden: true },
                { field: "EmpName", title: "员工", width: 80 },
                { field: "JobNumber", title: "工号", width: 80 },
                { field: "DeptID", title: "所属部门", width: 100, type: 'int', bind: { key: 'dept', data: [] } },
                { field: "PositionID", title: "担任职位", width: 100, type: 'int', bind: { key: '职位-tree', data: [] } },
                { field: "HireDate", title: "入职日期", width: 90 },
                { field: "Score", title: "得分", width: 80 },
                { field: "Appraise", title: "综合评价", width: 120 },
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.SelectTracking(row);
                                    })
                                    // this.selectEmpCall(row)
                                    this.handleClick(row);
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            pagination: {}, //分页配置，见voltable组件api
        };
    },
    methods: {
       
        load(){
           //console.log(this.$store.getters.data().Emp.EmpID);
         
            this.$refs.mytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
       
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.SelectTrialPeriodTracking(rows[0]);
            })
            this.handleClick(rows[0]);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
          
            //查询前，设置查询条件
            if (this.searchKey) {
                params.wheres = [{ name: "searchKey", value: this.searchKey }];
            }
            var EmpID = this.$store.getters.data().Emp.EmpID;
            if (EmpID !== undefined) {
                    params.wheres.push({ name: 'EmpID', value: EmpID })
                }
            return true;
        },
        // selectEmpCall(row){
        //     this.$emit("selectEmpCall", row);
        // }
    },
    emits: ['TackingCallBack','CloseTackingBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('TackingCallBack', item)
        }
        const closeTackingBox = (item) => {
            emit('CloseTackingBox', item)
        }
        return {
            handleClick,
            closeTackingBox
        }
    }
};



</script>