<template>
    <view>
        <VolBox v-model="model" :lazy="true" title="选择供应商报价" :height="540" :width="800" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="关键字" style="width: 250px" v-model="SupplierQuoName" @keyup.enter="search()"/>
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i class="el-icon-search" ></i>搜索</el-button>
            <el-button size="medium" type="primary" style="margin-left:10px" @click="AddSQ"><i class="el-icon-add" ></i>添加报价</el-button>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :max-height="380" :url="url" :index="true" :single="sigle" 
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 --><!-- :defaultLoadPage="defaultLoadPage"-->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false;closeSupplierQuoBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
        <view>
            <VolBox v-model="showSQ" :lazy="true" title="添加物料报价" :width="700" ::height="700" :padding="15" :onModelClose="onModelClose">
              <!-- <div style="height: 20px;width:20px"> -->
                <SupQuo ref="SupQuo" ></SupQuo>
              <!-- </div> -->
            </VolBox>
        </view>
    </view>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
import SupQuo from "@/views/system/purchase/Pur_SupplierQuoSingle.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm,
         SupQuo: SupQuo
    },
    props: {
        selectRow: Object
    },
    data() {
        
        return {
            showSQ:false,
            model: false,           
            //defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            SupplierQuoName: "", //查询条件字段
            url: "api/Pur_SupplierQuotation/GetPageData",//加载数据的接口
            columns: [
                {field: "QuotationID", title: "主键id", width: 90, hidden: true },
                {field:'MaterialsID',title:'物料编码',hidden:true},
                {field:'MatNo',title:'物料编码',width:90},
                {field:'MatName',title:'物料名称',width:90},              
                {field: "SupplierID", title: "供应商", hidden:true},
                {field: "SupplierName", title: "供应商", width: 100},
                {field: "Price", title: "报价", width: 120},
                {field: "ReviewLevel", title: "评审等级", width: 120},
                {field: "maxPrice", title: "历史最高价", width: 80, hidden: true}, 
                {field: "minPrice", title: "历史最低价", width: 80, hidden: true},
                {field: "avgPrice", title: "历史均价", width: 80, hidden: true},            
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.SelectSupplierQuo(row);
                                    })
                                    //this.selectCompCall(row)
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
           // pagination: {size:100}, //分页配置，见voltable组件api
            sigle: true            
        };
    },
    methods: {
        load(){
            this.$refs.mytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.mytable.load();
        },
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.SelectSupplierQuo(rows[0]);
            })
            this.handleClick(rows[0]);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
          
            //查询前，设置查询条件
            if (this.SupplierQuoName) {
                params.wheres = [{ name: "search", value: this.SupplierQuoName },{name: "Forbidden", value: 0 }];
            }
            var MaterialsID = this.$store.getters.data().MaterialsID;
            if (MaterialsID !== undefined) {
                    params.wheres.push({ name: 'MaterialsID', value: MaterialsID })
                }
            return true;
        },
        // selectEmpCall(row){
        //     this.$emit("selectEmpCall", row);
        // }
        onModelClose() {
            this.$store.getters.data().SupQuoButtonModel = null;
            this.showSQ = false
        },
        AddSQ(){
            this.showSQ = true;
            this.$store.getters.data().SupQuoButtonModel = this;
            // var this_=this;
            
            // this.$nextTick(x => {
            //     console.log(this_.$refs);
            //     this.$refs.SupQuo.$.refs.grid.add();
            // })
           
        },

    },
    emits: ['SupplierQuoCallBack','CloseSupplierQuoBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('SupplierQuoCallBack', item)
        }
        const closeSupplierQuoBox = (item) => {
            emit('CloseSupplierQuoBox', item)
        }
        return {
            handleClick,
            closeSupplierQuoBox
        }
    }
};
</script>