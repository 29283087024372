<template>
    <vol-box ref="fileUpload" v-model="fileUploads" title="附件上传" :height="200" :width="300" :padding="15" :display="true">
            <VolUpload
            url="/api/OA_Asset/upload"
            :upload-after="uploadAfter"
            >
            <!-- <div>单文件自动上传</div> -->
            </VolUpload>
    </vol-box>
</template>

<script>
import VolBox from "@/components/basic/VolBox.vue";
import VolUpload from "@/components/basic/VolUpload.vue";
export default {
    components: {
        "vol-box": VolBox,
        "VolUpload": VolUpload,
    },
    methods: {
        uploadAfter(result, files) {
            if (!result.status) return true;
            let imgs = [];
            files.forEach(x => {
                imgs.push(result.data + x.name);
            });
            this.$emit("parentCall", ($parent) => {
                $parent.fileUploadDetails(imgs[0]);
            })
            this.handleClick(imgs[0])
            //关闭当前窗口
            this.model = false;
        }
    },
    emits: ['fileUploadCallBack','closefileUploadBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('fileUploadCallBack', item)
        }
        const closefileUploadBox = (item) => {
            emit('closefileUploadBox', item)
        }
        return {
            handleClick,
            closefileUploadBox
        }
    }
};
</script>