<template>
    <VolBox v-model="model" :lazy="true" title="选择领用明细" :height="540" :width="800" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="关键字" style="width: 250px" v-model="StockOutName" @keyup.enter="search()"/>
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i class="el-icon-search" ></i>搜索</el-button>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :max-height="380" :url="url" :index="true" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false;closeStockOutBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {
        selectRow: Object
    },
    data() {
        // 领用出库明细，按批次显示
        return {
            model: false,           
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            StockOutName: "", //查询条件字段
            url: "api/Pur_StockOut/GetStockOutDetailPage",//加载数据的接口
            columns: [
                {field: "SOutID", title: "主键id", width: 90, hidden: true },
                {field:'AutoNumSO',title:'领用出库单号',width:130},
                {field:'EmpName',title:'领用人',width:90,bind:{ key:'采购类型',data:[]}},              
                {field: "ReceiveDate", title: "领用时间", width: 100 ,type:'date'},
                { field: "MaterialsID", title: "MaterialsID", type: "int", width: 80, hidden: true },
                { field: "MatNo", title: "物料编码", type: "text", width: 150 },
                { field: "MatName", title: "物料名称", type: "string", width: 150,required:true},
                { field: "Brand", title: "品牌", type: "string", width: 80},
                { field: "Unit", title: "计量单位", type: "string", width: 100},
                { field: "Model", title: "规格型号", type: "string", width: 100},
                { field: "UseType", title: "物料属性", type: "int", width: 100,bind: { key: 'UseType', data: [] }},     
                { field: "Number", title: "数量", type: "string", width: 100},
                { field: "Price", title: "单价", type: "string", width: 100},               
                { field: "PurApplyID", title: "采购申请单ID", type: "int", width: 10, hidden: true },
                { field: "AutoNumPA", title: "采购申请单", type: "string", width: 100},
                { field: "PADetailID", title: "采购申请明细ID", type: "int", width: 10, hidden: true },
                { field: "StoIID", title: "验收入库ID", type: "int", width: 10, hidden: true },
                { field: "AutoNumSTOI", title: "验收入库单", type: "string", width: 100},
                { field: "StoIDetailID", title: "验收入库明细ID", type: "int", width: 10, hidden: true },  
                { field: "SODLogID", title: "领用出库明细ID", type: "int", width: 10, hidden: true },  
                { field: "SOutDetailID", title: "领用出库明细LogID", type: "int", width: 10, hidden: true },     
                { field: "SupplierName", title: "供应商", type: "string", width: 100},
                { field: "SupplierID", title: "供应商ID", type: "int", width: 10, hidden: true },              
                { field: "Address", title: "地址/网址", type: "string", width: 10, hidden: true },                       
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.SelectStockOut(row);
                                    })
                                    //this.selectCompCall(row)
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
           // pagination: {size:100}, //分页配置，见voltable组件api
            sigle: true            
        };
    },
    methods: {
        load(){
            this.$refs.mytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.mytable.load();
        },
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
               //$parent.SelectStockOut(rows[0]);
                $parent.SelectStockOut(rows);
            })
                //this.handleClick(rows[0]);
                this.handleClick(rows);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
          
            //查询前，设置查询条件
            if (this.StockOutName) {
                params.wheres = [{ name: "search", value: this.StockOutName },{name: "Forbidden", value: 0 }];
            }
            //var EmpID = this.$store.getters.data().Emp.EmpID;
            // if (EmpID !== undefined) {
            //         params.wheres.push({ name: 'EmpID', value: EmpID })
            //     }
            return true;
        },
        // selectEmpCall(row){
        //     this.$emit("selectEmpCall", row);
        // }
    },
    emits: ['StockOutCallBack','CloseStockOutBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('StockOutCallBack', item)
        }
        const closeStockOutBox = (item) => {
            emit('CloseStockOutBox', item)
        }
        return {
            handleClick,
            closeStockOutBox
        }
    }
};
</script>