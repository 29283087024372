<template>
    <VolBox v-model="model" :lazy="true" title="选择离职申请员工" :height="450" :width="800" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入员工名称</span>
            <el-input placeholder="员工名称" style="width: 200px" v-model="empName" />
            <el-button type="primary" style="margin-left:10px" size="medium"
                @click="search"><i class="el-icon-zoom-out"></i>搜索</el-button>
        </div>

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="leaveapplytable" :loadKey="true" :columns="columns" :pagination="pagination"
            :pagination-hide="false" :max-height="380" :url="url" :index="true" :single="true"
            :defaultLoadPage="defaultLoadPage" @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()"><i class="el-icon-plus"></i>添加选择的数据</el-button>
                <el-button size="medium" @click="model = false;closeLeveApplyBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
    },
    data() {
        return {
            model: false,
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            empName: "", //查询条件字段
            url: "api/OA_LeaveApply/getPageData",//加载数据的接口
            columns: [
                { field: "EmpName", title: "申请人", width: 120 },
                { field: "ApplyDate", title: "申请日期", width: 120, type: 'date' },
                { field: "DeptID", title: "部门", width: 120, type: 'int', bind: { key: 'dept-treeSelect',type:"treeSelect", data: [] } },
                { field: "PositionID", title: "职位", width: 120, type: 'int', bind: { key: '职位-tree', data: [] } },
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.$emit("parentCall", ($parent) => {
                                        $parent.selectLeaveApply(row);
                                    })
                                    this.handleClick(row);
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            pagination: {}, //分页配置，见voltable组件api
        };
    },
    methods: {
        rowClick({ row, column, event }) {
            this.$refs.leaveapplytable.$refs.table.clearSelection();//清除当前选中的行
            this.$refs.leaveapplytable.$refs.table.toggleRowSelection(row);
        },
        load() {
            //点击搜索
            this.$refs.leaveapplytable.load();
        },
        openDemo(row) {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.leaveapplytable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.leaveapplytable.load();
        },
        addRow() {
            var rows = this.$refs.leaveapplytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.selectLeaveApply(rows[0]);
            })
            this.handleClick(rows[0]);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            //查询前，设置查询条件
            if (this.empName) {
                params.wheres = [{ name: "EmpName", value: this.empName }];
            }
            return true;
        },
    },
    emits: ['LeaveApplyCallBack','CloseLeveApplyBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('LeaveApplyCallBack', item)
        }
        const closeLeveApplyBox = (item) => {
            emit('CloseLeveApplyBox', item)
        }
        return {
            handleClick,
            closeLeveApplyBox,
        }
    }
};
</script>