<template>
  <VolBox
    v-model="model"
    :lazy="true"
    title="选择档案"
    :height="540"
    :width="800"
    :padding="15"
  >
    <!-- 设置查询条件 -->
    <div style="padding-bottom: 10px">
      <span style="margin-right: 20px">请选择数据</span>
      <el-input
        placeholder="关键字查询"
        style="width: 200px"
        v-model="searchKey"
      />
      <el-button
        type="primary"
        style="margin-left: 10px"
        size="medium"
        @click="search"
        ><i class="el-icon-zoom-out"></i>搜索</el-button
      >
    </div>

    <!-- vol-table配置的这些属性见VolTable组件api文件 -->
    <vol-table
      ref="mytable"
      :loadKey="true"
      :columns="columns"
      :pagination="pagination"
      :pagination-hide="false"
      :max-height="400"
      :url="url"
      :index="true"
      :single="true"
      :defaultLoadPage="defaultLoadPage"
      @loadBefore="loadTableBefore"
      @loadAfter="loadTableAfter"
    ></vol-table>
    <!-- 设置弹出框的操作按钮 -->
    <template #footer>
      <div>
        <el-button size="mini" type="primary" @click="addRow()"
          ><i class="el-icon-plus"></i>添加选择的数据</el-button
        >
        <el-button
          size="mini"
          @click="
            model = false;
            CloseBox();
          "
          ><i class="el-icon-close"></i>关闭</el-button
        >
      </div>
    </template>
  </VolBox>
</template>
<script>
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
  components: {
    VolBox: VolBox,
    VolTable: VolTable,
  },
  props: {
    selectRow: Object,
  },
  data() {
    return {
      searchKey:"",
      model: false,
      defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
      EmpID: "", //查询条件字段
      url: "api/OA_ShareDocumentFolder/getDocumentFileList", //加载数据的接口
      columns: [
        { field: "Name", title: "文件名称", width: 90 },
        {
          field: "Level",
          title: "密级",
          bind: { key: "档案等级", data: [] }, //此处值为data空数据，自行从后台字典数据源加载
          width: 80,
        },
        { field: "FileType", title: "文件类型", width: 120 },
        {
          field: "FilePath",
          title: "文件路径",
          width: 200,
          require: true,
          formatter: (row) => {
            //对单元格的数据格式化处理
            if (!row.FilePath) {
              return;
            }
            return (
              "<span title='" +
              row.FilePath.replace("files/Archives/", "") +
              "'>" +
              row.FilePath.replace("files/Archives/", "") +
              "<span>"
            );
          },
        },
        {
          field: "opt",
          title: "操作",
          render: (h, { row, column, index }) => {
            return h(
              "div",
              {
                style: {
                  "font-size": "13px",
                  cursor: "pointer",
                  color: "#409eff",
                },
              },
              [
                h(
                  "a",
                  {
                    style: {},
                    class: [],
                    onClick: (e) => {
                      e.stopPropagation();
                      this.$emit("parentCall", ($parent) => {
                        $parent.SelectDocumentFile(row);
                      });
                      this.selectDocumentCall(row);
                      //关闭当前窗口
                      this.model = false;
                    },
                  },
                  "选择"
                ),
              ]
            );
          },
        },
      ],
      pagination: {}, //分页配置，见voltable组件api
    };
  },
  methods: {
    load() {
      this.$refs.mytable.load();
    },
    openDemo(row) {
      this.model = true;
      //打开弹出框时，加载table数据
      this.$nextTick(() => {
        this.$refs.mytable.load();
      });
    },
    search() {
      //点击搜索
      this.$refs.mytable.load();
    },
    addRow() {
      var rows = this.$refs.mytable.getSelected();
      if (!rows || rows.length == 0) {
        return this.$message.error("请选择行数据");
      }
      this.$emit("parentCall", ($parent) => {
        $parent.SelectDocumentFile(rows[0]);
      });
      this.handleClick(rows[0]);
      //关闭当前窗口
      this.model = false;
    },
    //这里是从api查询后返回数据的方法
    loadTableAfter(row) {},
    //查询前
    loadTableBefore(params) {
      if (this.searchKey) {
        params.wheres.push({ name: "searchKey", value: this.searchKey });
      }
      return true;
    },
    selectDocumentCall(row) {
      this.$emit("documentFileCallBack", row);
    },
  },
  emits: ["documentFileCallBack", "CloseBox"],
  setup(props, { emit }) {
    const handleClick = (item) => {
      emit("documentFileCallBack", item);
    };
    const CloseBox = (item) => {
      emit("closeDocumentFileBox", item);
    };
    return {
      handleClick,
      CloseBox,
    };
  },
};
</script>
  