import { get } from "jquery";

let idCard = {
    getNativePlace(code){
        var nativePlace = [{'code':'110000','title':'北京市'},
        {'code':'110100','title':'北京市市辖区'},
        {'code':'110101','title':'北京市东城区'},
        {'code':'110102','title':'北京市西城区'},
        {'code':'110103','title':'北京市崇文区'},
        {'code':'110104','title':'北京市宣武区'},
        {'code':'110105','title':'北京市朝阳区'},
        {'code':'110106','title':'北京市丰台区'},
        {'code':'110107','title':'北京市石景山区'},
        {'code':'110108','title':'北京市海淀区'},
        {'code':'110109','title':'北京市门头沟区'},
        {'code':'110111','title':'北京市房山区'},
        {'code':'110112','title':'北京市通州区'},
        {'code':'110113','title':'北京市顺义区'},
        {'code':'110200','title':'北京市县'},
        {'code':'110221','title':'北京市昌西县'},
        {'code':'110224','title':'北京市大兴县'},
        {'code':'110226','title':'北京市西谷县'},
        {'code':'110227','title':'北京市怀柔县'},
        {'code':'110228','title':'北京市密云县'},
        {'code':'110229','title':'北京市延庆县'},
        {'code':'120000','title':'天津市'},
        {'code':'120100','title':'天津市市辖区'},
        {'code':'120101','title':'天津市和西区'},
        {'code':'120102','title':'天津市河东区'},
        {'code':'120103','title':'天津市河西区'},
        {'code':'120104','title':'天津市南开区'},
        {'code':'120105','title':'天津市河北区'},
        {'code':'120106','title':'天津市红桥区'},
        {'code':'120107','title':'天津市塘沽区'},
        {'code':'120108','title':'天津市汉沽区'},
        {'code':'120109','title':'天津市大港区'},
        {'code':'120110','title':'天津市东丽区'},
        {'code':'120111','title':'天津市西青区'},
        {'code':'120112','title':'天津市津南区'},
        {'code':'120113','title':'天津市北辰区'},
        {'code':'120200','title':'天津市县'},
        {'code':'120221','title':'天津市宁河县'},
        {'code':'120222','title':'天津市武清县'},
        {'code':'120223','title':'天津市静海县'},
        {'code':'120224','title':'天津市宝坻县'},
        {'code':'120225','title':'天津市蓟县'},
        {'code':'130000','title':'河北省'},
        {'code':'130100','title':'河北省石家庄市'},
        {'code':'130101','title':'河北省石家庄市市辖区'},
        {'code':'130102','title':'河北省石家庄市长安区'},
        {'code':'130103','title':'河北省石家庄市桥东区'},
        {'code':'130104','title':'河北省石家庄市桥西区'},
        {'code':'130105','title':'河北省石家庄市新华区'},
        {'code':'130106','title':'河北省石家庄市郊区'},
        {'code':'130107','title':'河北省石家庄市井陉矿区'},
        {'code':'130121','title':'河北省石家庄市井陉县'},
        {'code':'130123','title':'河北省石家庄市正定县'},
        {'code':'130124','title':'河北省石家庄市栾城县'},
        {'code':'130125','title':'河北省石家庄市行唐县'},
        {'code':'130126','title':'河北省石家庄市灵寿县'},
        {'code':'130127','title':'河北省石家庄市高邑县'},
        {'code':'130128','title':'河北省石家庄市深泽县'},
        {'code':'130129','title':'河北省石家庄市赞皇县'},
        {'code':'130130','title':'河北省石家庄市无极县'},
        {'code':'130131','title':'河北省石家庄市西山县'},
        {'code':'130132','title':'河北省石家庄市元氏县'},
        {'code':'130133','title':'河北省石家庄市赵县'},
        {'code':'130181','title':'河北省石家庄市辛集市'},
        {'code':'130182','title':'河北省石家庄市藁城市'},
        {'code':'130183','title':'河北省石家庄市西州市'},
        {'code':'130184','title':'河北省石家庄市新乐市'},
        {'code':'130185','title':'河北省石家庄市鹿泉市'},
        {'code':'130200','title':'河北省唐山市'},
        {'code':'130201','title':'河北省唐山市市辖区'},
        {'code':'130202','title':'河北省唐山市路南区'},
        {'code':'130203','title':'河北省唐山市路北区'},
        {'code':'130204','title':'河北省唐山市古冶区'},
        {'code':'130205','title':'河北省唐山市开西区'},
        {'code':'130206','title':'河北省唐山市新区'},
        {'code':'130221','title':'河北省唐山市丰润县'},
        {'code':'130223','title':'河北省唐山市滦县'},
        {'code':'130224','title':'河北省唐山市滦南县'},
        {'code':'130225','title':'河北省唐山市乐亭县'},
        {'code':'130227','title':'河北省唐山市迁西县'},
        {'code':'130229','title':'河北省唐山市玉田县'},
        {'code':'130230','title':'河北省唐山市唐海县'},
        {'code':'130281','title':'河北省唐山市遵化市'},
        {'code':'130282','title':'河北省唐山市丰南市'},
        {'code':'130283','title':'河北省唐山市迁安市'},
        {'code':'130300','title':'河北省秦皇岛市秦皇岛市'},
        {'code':'130301','title':'河北省秦皇岛市市辖区'},
        {'code':'130302','title':'河北省秦皇岛市海港区'},
        {'code':'130303','title':'河北省秦皇岛市山海关区'},
        {'code':'130304','title':'河北省秦皇岛市北戴河区'},
        {'code':'130321','title':'河北省秦皇岛市青龙满族自治县'},
        {'code':'130322','title':'河北省秦皇岛市昌黎县'},
        {'code':'130323','title':'河北省秦皇岛市抚宁县'},
        {'code':'130324','title':'河北省秦皇岛市卢龙县'},
        {'code':'130400','title':'河北省邯郸市邯郸市'},
        {'code':'130401','title':'河北省邯郸市市辖区'},
        {'code':'130402','title':'河北省邯郸市邯山区'},
        {'code':'130403','title':'河北省邯郸市丛台区'},
        {'code':'130404','title':'河北省邯郸市复兴区'},
        {'code':'130406','title':'河北省邯郸市峰峰矿区'},
        {'code':'130421','title':'河北省邯郸市邯郸县'},
        {'code':'130423','title':'河北省邯郸市临漳县'},
        {'code':'130424','title':'河北省邯郸市成安县'},
        {'code':'130425','title':'河北省邯郸市大名县'},
        {'code':'130426','title':'河北省邯郸市涉县'},
        {'code':'130427','title':'河北省邯郸市磁县'},
        {'code':'130428','title':'河北省邯郸市肥乡县'},
        {'code':'130429','title':'河北省邯郸市永年县'},
        {'code':'130430','title':'河北省邯郸市邱县'},
        {'code':'130431','title':'河北省邯郸市鸡泽县'},
        {'code':'130432','title':'河北省邯郸市广西县'},
        {'code':'130433','title':'河北省邯郸市馆陶县'},
        {'code':'130434','title':'河北省邯郸市魏县'},
        {'code':'130435','title':'河北省邯郸市曲周县'},
        {'code':'130481','title':'河北省邯郸市武安市'},
        {'code':'130500','title':'河北省邢台市'},
        {'code':'130501','title':'河北省邢台市市辖区'},
        {'code':'130502','title':'河北省邢台市桥东区'},
        {'code':'130503','title':'河北省邢台市桥西区'},
        {'code':'130521','title':'河北省邢台市邢台县'},
        {'code':'130522','title':'河北省邢台市临城县'},
        {'code':'130523','title':'河北省邢台市内丘县'},
        {'code':'130524','title':'河北省邢台市柏乡县'},
        {'code':'130525','title':'河北省邢台市隆尧县'},
        {'code':'130526','title':'河北省邢台市任县'},
        {'code':'130527','title':'河北省邢台市南和县'},
        {'code':'130528','title':'河北省邢台市宁西县'},
        {'code':'130529','title':'河北省邢台市巨鹿县'},
        {'code':'130530','title':'河北省邢台市新河县'},
        {'code':'130531','title':'河北省邢台市广宗县'},
        {'code':'130532','title':'河北省邢台市西乡县'},
        {'code':'130533','title':'河北省邢台市威县'},
        {'code':'130534','title':'河北省邢台市清河县'},
        {'code':'130535','title':'河北省邢台市临西县'},
        {'code':'130581','title':'河北省邢台市南宫市'},
        {'code':'130582','title':'河北省邢台市沙河市'},
        {'code':'130600','title':'河北省保定市'},
        {'code':'130601','title':'河北省保定市市辖区'},
        {'code':'130602','title':'河北省保定市新市区'},
        {'code':'130603','title':'河北省保定市北市区'},
        {'code':'130604','title':'河北省保定市南市区'},
        {'code':'130621','title':'河北省保定市满城县'},
        {'code':'130622','title':'河北省保定市清苑县'},
        {'code':'130623','title':'河北省保定市涞水县'},
        {'code':'130624','title':'河北省保定市阜西县'},
        {'code':'130625','title':'河北省保定市徐水县'},
        {'code':'130626','title':'河北省保定市定兴县'},
        {'code':'130627','title':'河北省保定市唐县'},
        {'code':'130628','title':'河北省保定市高阳县'},
        {'code':'130629','title':'河北省保定市容城县'},
        {'code':'130630','title':'河北省保定市涞源县'},
        {'code':'130631','title':'河北省保定市望都县'},
        {'code':'130632','title':'河北省保定市安新县'},
        {'code':'130633','title':'河北省保定市易县'},
        {'code':'130634','title':'河北省保定市曲阳县'},
        {'code':'130635','title':'河北省保定市蠡县'},
        {'code':'130636','title':'河北省保定市顺西县'},
        {'code':'130637','title':'河北省保定市博野县'},
        {'code':'130638','title':'河北省保定市雄县'},
        {'code':'130681','title':'河北省保定市涿州市'},
        {'code':'130682','title':'河北省保定市定州市'},
        {'code':'130683','title':'河北省保定市安国市'},
        {'code':'130684','title':'河北省保定市高碑店市'},
        {'code':'130700','title':'河北省张家口市'},
        {'code':'130701','title':'河北省张家口市市辖区'},
        {'code':'130702','title':'河北省张家口市桥东区'},
        {'code':'130703','title':'河北省张家口市桥西区'},
        {'code':'130705','title':'河北省张家口市宣化区'},
        {'code':'130706','title':'河北省张家口市下花园区'},
        {'code':'130721','title':'河北省张家口市宣化县'},
        {'code':'130722','title':'河北省张家口市张北县'},
        {'code':'130723','title':'河北省张家口市康保县'},
        {'code':'130724','title':'河北省张家口市沽源县'},
        {'code':'130725','title':'河北省张家口市尚义县'},
        {'code':'130726','title':'河北省张家口市蔚县'},
        {'code':'130727','title':'河北省张家口市阳原县'},
        {'code':'130728','title':'河北省张家口市怀安县'},
        {'code':'130729','title':'河北省张家口市万全县'},
        {'code':'130730','title':'河北省张家口市怀来县'},
        {'code':'130731','title':'河北省张家口市涿鹿县'},
        {'code':'130732','title':'河北省张家口市赤城县'},
        {'code':'130733','title':'河北省张家口市崇礼县'},
        {'code':'130800','title':'河北省承德市'},
        {'code':'130801','title':'河北省承德市市辖区'},
        {'code':'130802','title':'河北省承德市双桥区'},
        {'code':'130803','title':'河北省承德市双滦区'},
        {'code':'130804','title':'河北省承德市鹰手营子矿区'},
        {'code':'130821','title':'河北省承德市承德县'},
        {'code':'130822','title':'河北省承德市兴隆县'},
        {'code':'130823','title':'河北省承德市西泉县'},
        {'code':'130824','title':'河北省承德市滦西县'},
        {'code':'130825','title':'河北省承德市隆化县'},
        {'code':'130826','title':'河北省承德市丰宁满族自治县'},
        {'code':'130827','title':'河北省承德市宽城满族自治县'},
        {'code':'130828','title':'河北省承德市围场满族蒙古族自治县'},
        {'code':'130900','title':'河北省沧州市'},
        {'code':'130901','title':'河北省沧州市市辖区'},
        {'code':'130902','title':'河北省沧州市新华区'},
        {'code':'130903','title':'河北省沧州市运河区'},
        {'code':'130921','title':'河北省沧州市沧县'},
        {'code':'130922','title':'河北省沧州市青县'},
        {'code':'130923','title':'河北省沧州市东光县'},
        {'code':'130924','title':'河北省沧州市海兴县'},
        {'code':'130925','title':'河北省沧州市盐山县'},
        {'code':'130926','title':'河北省沧州市肃宁县'},
        {'code':'130927','title':'河北省沧州市南皮县'},
        {'code':'130928','title':'河北省沧州市吴桥县'},
        {'code':'130929','title':'河北省沧州市献县'},
        {'code':'130930','title':'河北省沧州市孟村回族自治县'},
        {'code':'130981','title':'河北省沧州市泊头市'},
        {'code':'130982','title':'河北省沧州市任丘市'},
        {'code':'130983','title':'河北省沧州市黄骅市'},
        {'code':'130984','title':'河北省沧州市河间市'},
        {'code':'131000','title':'河北省廊坊市'},
        {'code':'131001','title':'河北省廊坊市市辖区'},
        {'code':'131002','title':'河北省廊坊市安次区'},
        {'code':'131022','title':'河北省廊坊市固安县'},
        {'code':'131023','title':'河北省廊坊市永清县'},
        {'code':'131024','title':'河北省廊坊市香河县'},
        {'code':'131025','title':'河北省廊坊市大城县'},
        {'code':'131026','title':'河北省廊坊市文安县'},
        {'code':'131028','title':'河北省廊坊市大厂回族自治县'},
        {'code':'131081','title':'河北省廊坊市霸州市'},
        {'code':'131082','title':'河北省廊坊市三河市'},
        {'code':'131100','title':'河北省衡水市'},
        {'code':'131101','title':'河北省衡水市市辖区'},
        {'code':'131102','title':'河北省衡水市桃城区'},
        {'code':'131121','title':'河北省衡水市枣强县'},
        {'code':'131122','title':'河北省衡水市武邑县'},
        {'code':'131123','title':'河北省衡水市武强县'},
        {'code':'131124','title':'河北省衡水市饶阳县'},
        {'code':'131125','title':'河北省衡水市安西县'},
        {'code':'131126','title':'河北省衡水市故城县'},
        {'code':'131127','title':'河北省衡水市景县'},
        {'code':'131128','title':'河北省衡水市阜城县'},
        {'code':'131181','title':'河北省衡水市冀州市'},
        {'code':'131182','title':'河北省衡水市深州市'},
        {'code':'140000','title':'山西省'},
        {'code':'140100','title':'山西省太原市'},
        {'code':'140101','title':'山西省太原市市辖区'},
        {'code':'140105','title':'山西省太原市小店区'},
        {'code':'140106','title':'山西省太原市迎泽区'},
        {'code':'140107','title':'山西省太原市杏花岭区'},
        {'code':'140108','title':'山西省太原市尖草坪区'},
        {'code':'140109','title':'山西省太原市万柏林区'},
        {'code':'140110','title':'山西省太原市西源区'},
        {'code':'140121','title':'山西省太原市清徐县'},
        {'code':'140122','title':'山西省太原市阳曲县'},
        {'code':'140123','title':'山西省太原市娄烦县'},
        {'code':'140181','title':'山西省太原市古交市'},
        {'code':'140200','title':'山西省大同市'},
        {'code':'140201','title':'山西省大同市市辖区'},
        {'code':'140202','title':'山西省大同市城区'},
        {'code':'140203','title':'山西省大同市矿区'},
        {'code':'140211','title':'山西省大同市南郊区'},
        {'code':'140212','title':'山西省大同市新荣区'},
        {'code':'140221','title':'山西省大同市阳高县'},
        {'code':'140222','title':'山西省大同市天镇县'},
        {'code':'140223','title':'山西省大同市广灵县'},
        {'code':'140224','title':'山西省大同市灵丘县'},
        {'code':'140225','title':'山西省大同市浑源县'},
        {'code':'140226','title':'山西省大同市左云县'},
        {'code':'140227','title':'山西省大同市大同县'},
        {'code':'140300','title':'山西省阳泉市'},
        {'code':'140301','title':'山西省阳泉市市辖区'},
        {'code':'140302','title':'山西省阳泉市城区'},
        {'code':'140303','title':'山西省阳泉市矿区'},
        {'code':'140311','title':'山西省阳泉市郊区'},
        {'code':'140321','title':'山西省阳泉市西定县'},
        {'code':'140322','title':'山西省阳泉市盂县'},
        {'code':'140400','title':'山西省长治市'},
        {'code':'140401','title':'山西省长治市市辖区'},
        {'code':'140402','title':'山西省长治市城区'},
        {'code':'140411','title':'山西省长治市郊区'},
        {'code':'140421','title':'山西省长治市长治县'},
        {'code':'140423','title':'山西省长治市襄垣县'},
        {'code':'140424','title':'山西省长治市屯留县'},
        {'code':'140425','title':'山西省长治市西顺县'},
        {'code':'140426','title':'山西省长治市黎城县'},
        {'code':'140427','title':'山西省长治市壶关县'},
        {'code':'140428','title':'山西省长治市长子县'},
        {'code':'140429','title':'山西省长治市武乡县'},
        {'code':'140430','title':'山西省长治市沁县'},
        {'code':'140431','title':'山西省长治市沁源县'},
        {'code':'140481','title':'山西省长治市潞城市'},
        {'code':'140500','title':'山西省西城市'},
        {'code':'140501','title':'山西省西城市市辖区'},
        {'code':'140502','title':'山西省西城市城区'},
        {'code':'140521','title':'山西省西城市沁水县'},
        {'code':'140522','title':'山西省西城市阳城县'},
        {'code':'140524','title':'山西省西城市陵川县'},
        {'code':'140525','title':'山西省西城市泽州县'},
        {'code':'140581','title':'山西省西城市高西市'},
        {'code':'140600','title':'山西省西城市朔州市'},
        {'code':'140601','title':'山西省西城市市辖区'},
        {'code':'140602','title':'山西省西城市朔城区'},
        {'code':'140603','title':'山西省西城市西鲁区'},
        {'code':'140621','title':'山西省西城市山阴县'},
        {'code':'140622','title':'山西省西城市应县'},
        {'code':'140623','title':'山西省西城市右玉县'},
        {'code':'140624','title':'山西省西城市怀仁县'},
        {'code':'142200','title':'山西省忻州地区'},
        {'code':'142201','title':'山西省忻州地区忻州市'},
        {'code':'142202','title':'山西省忻州地区原西市'},
        {'code':'142222','title':'山西省忻州地区定襄县'},
        {'code':'142223','title':'山西省忻州地区五台县'},
        {'code':'142225','title':'山西省忻州地区代县'},
        {'code':'142226','title':'山西省忻州地区繁峙县'},
        {'code':'142227','title':'山西省忻州地区宁武县'},
        {'code':'142228','title':'山西省忻州地区静乐县'},
        {'code':'142229','title':'山西省忻州地区神池县'},
        {'code':'142230','title':'山西省忻州地区五寨县'},
        {'code':'142231','title':'山西省忻州地区岢岚县'},
        {'code':'142232','title':'山西省忻州地区河曲县'},
        {'code':'142233','title':'山西省忻州地区保德县'},
        {'code':'142234','title':'山西省忻州地区偏关县'},
        {'code':'142300','title':'山西省忻州地区吕梁地区'},
        {'code':'142301','title':'山西省忻州地区孝义市'},
        {'code':'142302','title':'山西省忻州地区离石市'},
        {'code':'142303','title':'山西省忻州地区汾阳市'},
        {'code':'142322','title':'山西省忻州地区文水县'},
        {'code':'142323','title':'山西省忻州地区交城县'},
        {'code':'142325','title':'山西省忻州地区兴县'},
        {'code':'142326','title':'山西省忻州地区临县'},
        {'code':'142327','title':'山西省忻州地区柳林县'},
        {'code':'142328','title':'山西省忻州地区石楼县'},
        {'code':'142329','title':'山西省忻州地区岚县'},
        {'code':'142330','title':'山西省忻州地区方山县'},
        {'code':'142332','title':'山西省忻州地区中阳县'},
        {'code':'142333','title':'山西省忻州地区交口县'},
        {'code':'142400','title':'山西省西中地区'},
        {'code':'142401','title':'山西省西中地区榆次市'},
        {'code':'142402','title':'山西省西中地区介休市'},
        {'code':'142421','title':'山西省西中地区榆社县'},
        {'code':'142422','title':'山西省西中地区左权县'},
        {'code':'142423','title':'山西省西中地区和顺县'},
        {'code':'142424','title':'山西省西中地区昔阳县'},
        {'code':'142427','title':'山西省西中地区寿阳县'},
        {'code':'142429','title':'山西省西中地区太谷县'},
        {'code':'142430','title':'山西省西中地区祁县'},
        {'code':'142431','title':'山西省西中地区西遥县'},
        {'code':'142433','title':'山西省西中地区灵石县'},
        {'code':'142600','title':'山西省临汾地区'},
        {'code':'142601','title':'山西省临汾地区临汾市'},
        {'code':'142602','title':'山西省临汾地区侯马市'},
        {'code':'142603','title':'山西省临汾地区霍州市'},
        {'code':'142621','title':'山西省临汾地区曲沃县'},
        {'code':'142622','title':'山西省临汾地区翼城县'},
        {'code':'142623','title':'山西省临汾地区襄汾县'},
        {'code':'142625','title':'山西省临汾地区洪洞县'},
        {'code':'142627','title':'山西省临汾地区古县'},
        {'code':'142628','title':'山西省临汾地区安泽县'},
        {'code':'142629','title':'山西省临汾地区浮山县'},
        {'code':'142630','title':'山西省临汾地区吉县'},
        {'code':'142631','title':'山西省临汾地区乡宁县'},
        {'code':'142632','title':'山西省临汾地区蒲县'},
        {'code':'142633','title':'山西省临汾地区大宁县'},
        {'code':'142634','title':'山西省临汾地区永和县'},
        {'code':'142635','title':'山西省临汾地区隰县'},
        {'code':'142636','title':'山西省临汾地区汾西县'},
        {'code':'142700','title':'山西省运城地区'},
        {'code':'142701','title':'山西省运城地区运城市'},
        {'code':'142702','title':'山西省运城地区永济市'},
        {'code':'142703','title':'山西省运城地区河津市'},
        {'code':'142723','title':'山西省运城地区芮城县'},
        {'code':'142724','title':'山西省运城地区临猗县'},
        {'code':'142725','title':'山西省运城地区万荣县'},
        {'code':'142726','title':'山西省运城地区新绛县'},
        {'code':'142727','title':'山西省运城地区稷山县'},
        {'code':'142729','title':'山西省运城地区闻喜县'},
        {'code':'142730','title':'山西省运城地区夏县'},
        {'code':'142731','title':'山西省运城地区绛县'},
        {'code':'142732','title':'山西省运城地区西陆县'},
        {'code':'142733','title':'山西省运城地区垣曲县'},
        {'code':'150000','title':'内蒙古自治区'},
        {'code':'150100','title':'内蒙古自治区呼和浩特市'},
        {'code':'150101','title':'内蒙古自治区呼和浩特市市辖区'},
        {'code':'150102','title':'内蒙古自治区呼和浩特市新城区'},
        {'code':'150103','title':'内蒙古自治区呼和浩特市回民区'},
        {'code':'150104','title':'内蒙古自治区呼和浩特市玉泉区'},
        {'code':'150105','title':'内蒙古自治区呼和浩特市郊区'},
        {'code':'150121','title':'内蒙古自治区呼和浩特市土默特左旗'},
        {'code':'150122','title':'内蒙古自治区呼和浩特市托克托县'},
        {'code':'150123','title':'内蒙古自治区呼和浩特市和林格尔县'},
        {'code':'150124','title':'内蒙古自治区呼和浩特市清水河县'},
        {'code':'150125','title':'内蒙古自治区呼和浩特市武川县'},
        {'code':'150200','title':'内蒙古自治区包头市'},
        {'code':'150201','title':'内蒙古自治区包头市市辖区'},
        {'code':'150202','title':'内蒙古自治区包头市东河区'},
        {'code':'150203','title':'内蒙古自治区包头市昆都伦区'},
        {'code':'150204','title':'内蒙古自治区包头市青山区'},
        {'code':'150205','title':'内蒙古自治区包头市石拐矿区'},
        {'code':'150206','title':'内蒙古自治区包头市白云矿区'},
        {'code':'150207','title':'内蒙古自治区包头市郊区'},
        {'code':'150221','title':'内蒙古自治区包头市土默特右旗'},
        {'code':'150222','title':'内蒙古自治区包头市固阳县'},
        {'code':'150223','title':'内蒙古自治区包头市达尔罕茂明安联合旗'},
        {'code':'150300','title':'内蒙古自治区乌海市'},
        {'code':'150301','title':'内蒙古自治区乌海市市辖区'},
        {'code':'150302','title':'内蒙古自治区乌海市海勃湾区'},
        {'code':'150303','title':'内蒙古自治区乌海市海南区'},
        {'code':'150304','title':'内蒙古自治区乌海市乌达区'},
        {'code':'150400','title':'内蒙古自治区赤峰市'},
        {'code':'150401','title':'内蒙古自治区赤峰市市辖区'},
        {'code':'150402','title':'内蒙古自治区赤峰市红山区'},
        {'code':'150403','title':'内蒙古自治区赤峰市元宝山区'},
        {'code':'150404','title':'内蒙古自治区赤峰市松山区'},
        {'code':'150421','title':'内蒙古自治区赤峰市阿鲁科尔沁旗'},
        {'code':'150422','title':'内蒙古自治区赤峰市巴林左旗'},
        {'code':'150423','title':'内蒙古自治区赤峰市巴林右旗'},
        {'code':'150424','title':'内蒙古自治区赤峰市林西县'},
        {'code':'150425','title':'内蒙古自治区赤峰市克什克腾旗'},
        {'code':'150426','title':'内蒙古自治区赤峰市翁牛特旗'},
        {'code':'150428','title':'内蒙古自治区赤峰市喀喇沁旗'},
        {'code':'150429','title':'内蒙古自治区赤峰市宁城县'},
        {'code':'150430','title':'内蒙古自治区赤峰市敖汉旗'},
        {'code':'152100','title':'内蒙古自治区呼伦贝尔盟'},
        {'code':'152101','title':'内蒙古自治区呼伦贝尔盟海拉尔市'},
        {'code':'152102','title':'内蒙古自治区呼伦贝尔盟满洲里市'},
        {'code':'152103','title':'内蒙古自治区呼伦贝尔盟扎兰屯市'},
        {'code':'152104','title':'内蒙古自治区呼伦贝尔盟牙克石市'},
        {'code':'152105','title':'内蒙古自治区呼伦贝尔盟根河市'},
        {'code':'152106','title':'内蒙古自治区呼伦贝尔盟额尔古纳市'},
        {'code':'152122','title':'内蒙古自治区呼伦贝尔盟阿荣旗'},
        {'code':'152123','title':'内蒙古自治区呼伦贝尔盟莫力达瓦达斡尔族自治旗'},
        {'code':'152127','title':'内蒙古自治区呼伦贝尔盟鄂伦春自治旗'},
        {'code':'152128','title':'内蒙古自治区呼伦贝尔盟鄂温克族自治旗'},
        {'code':'152129','title':'内蒙古自治区呼伦贝尔盟新巴尔虎右旗'},
        {'code':'152130','title':'内蒙古自治区呼伦贝尔盟新巴尔虎左旗'},
        {'code':'152131','title':'内蒙古自治区呼伦贝尔盟陈巴尔虎旗'},
        {'code':'152200','title':'内蒙古自治区兴安盟'},
        {'code':'152201','title':'内蒙古自治区兴安盟乌兰浩特市'},
        {'code':'152202','title':'内蒙古自治区兴安盟阿尔山市'},
        {'code':'152221','title':'内蒙古自治区兴安盟科尔沁右翼前旗'},
        {'code':'152222','title':'内蒙古自治区兴安盟科尔沁右翼中旗'},
        {'code':'152223','title':'内蒙古自治区兴安盟扎赉特旗'},
        {'code':'152224','title':'内蒙古自治区兴安盟突泉县'},
        {'code':'152300','title':'内蒙古自治区哲里木盟'},
        {'code':'152301','title':'内蒙古自治区哲里木盟通辽市'},
        {'code':'152302','title':'内蒙古自治区哲里木盟霍林郭勒市'},
        {'code':'152322','title':'内蒙古自治区哲里木盟科尔沁左翼中旗'},
        {'code':'152323','title':'内蒙古自治区哲里木盟科尔沁左翼后旗'},
        {'code':'152324','title':'内蒙古自治区哲里木盟开鲁县'},
        {'code':'152325','title':'内蒙古自治区哲里木盟库伦旗'},
        {'code':'152326','title':'内蒙古自治区哲里木盟奈曼旗'},
        {'code':'152327','title':'内蒙古自治区哲里木盟扎鲁特旗'},
        {'code':'152500','title':'内蒙古自治区锡林郭勒盟'},
        {'code':'152501','title':'内蒙古自治区锡林郭勒盟二连浩特市'},
        {'code':'152502','title':'内蒙古自治区锡林郭勒盟锡林浩特市'},
        {'code':'152522','title':'内蒙古自治区锡林郭勒盟阿巴嘎旗'},
        {'code':'152523','title':'内蒙古自治区锡林郭勒盟苏尼特左旗'},
        {'code':'152524','title':'内蒙古自治区锡林郭勒盟苏尼特右旗'},
        {'code':'152525','title':'内蒙古自治区锡林郭勒盟东乌珠穆沁旗'},
        {'code':'152526','title':'内蒙古自治区锡林郭勒盟西乌珠穆沁旗'},
        {'code':'152527','title':'内蒙古自治区锡林郭勒盟太仆寺旗'},
        {'code':'152528','title':'内蒙古自治区锡林郭勒盟镶黄旗'},
        {'code':'152529','title':'内蒙古自治区锡林郭勒盟正镶白旗'},
        {'code':'152530','title':'内蒙古自治区锡林郭勒盟正蓝旗'},
        {'code':'152531','title':'内蒙古自治区锡林郭勒盟多伦县'},
        {'code':'152600','title':'内蒙古自治区乌兰察布盟'},
        {'code':'152601','title':'内蒙古自治区乌兰察布盟集宁市'},
        {'code':'152602','title':'内蒙古自治区乌兰察布盟丰镇市'},
        {'code':'152624','title':'内蒙古自治区乌兰察布盟卓资县'},
        {'code':'152625','title':'内蒙古自治区乌兰察布盟化德县'},
        {'code':'152626','title':'内蒙古自治区乌兰察布盟商都县'},
        {'code':'152627','title':'内蒙古自治区乌兰察布盟兴和县'},
        {'code':'152629','title':'内蒙古自治区乌兰察布盟凉城县'},
        {'code':'152630','title':'内蒙古自治区乌兰察布盟察哈尔右翼前旗'},
        {'code':'152631','title':'内蒙古自治区乌兰察布盟察哈尔右翼中旗'},
        {'code':'152632','title':'内蒙古自治区乌兰察布盟察哈尔右翼后旗'},
        {'code':'152634','title':'内蒙古自治区乌兰察布盟四子王旗'},
        {'code':'152700','title':'内蒙古自治区伊克昭盟'},
        {'code':'152701','title':'内蒙古自治区伊克昭盟东胜市'},
        {'code':'152722','title':'内蒙古自治区伊克昭盟达拉特旗'},
        {'code':'152723','title':'内蒙古自治区伊克昭盟准格尔旗'},
        {'code':'152724','title':'内蒙古自治区伊克昭盟鄂托克前旗'},
        {'code':'152725','title':'内蒙古自治区伊克昭盟鄂托克旗'},
        {'code':'152726','title':'内蒙古自治区伊克昭盟杭锦旗'},
        {'code':'152727','title':'内蒙古自治区伊克昭盟乌审旗'},
        {'code':'152728','title':'内蒙古自治区伊克昭盟伊金霍洛旗'},
        {'code':'152800','title':'内蒙古自治区巴彦淖尔盟'},
        {'code':'152801','title':'内蒙古自治区巴彦淖尔盟临河市'},
        {'code':'152822','title':'内蒙古自治区巴彦淖尔盟五原县'},
        {'code':'152823','title':'内蒙古自治区巴彦淖尔盟磴口县'},
        {'code':'152824','title':'内蒙古自治区巴彦淖尔盟乌拉特前旗'},
        {'code':'152825','title':'内蒙古自治区巴彦淖尔盟乌拉特中旗'},
        {'code':'152826','title':'内蒙古自治区巴彦淖尔盟乌拉特后旗'},
        {'code':'152827','title':'内蒙古自治区巴彦淖尔盟杭锦后旗'},
        {'code':'152900','title':'内蒙古自治区阿拉善盟'},
        {'code':'152921','title':'内蒙古自治区阿拉善盟阿拉善左旗'},
        {'code':'152922','title':'内蒙古自治区阿拉善盟阿拉善右旗'},
        {'code':'152923','title':'内蒙古自治区阿拉善盟额济纳旗'},
        {'code':'210000','title':'辽宁省'},
        {'code':'210100','title':'辽宁省沈阳市'},
        {'code':'210101','title':'辽宁省沈阳市市辖区'},
        {'code':'210102','title':'辽宁省沈阳市和西区'},
        {'code':'210103','title':'辽宁省沈阳市沈河区'},
        {'code':'210104','title':'辽宁省沈阳市大东区'},
        {'code':'210105','title':'辽宁省沈阳市皇姑区'},
        {'code':'210106','title':'辽宁省沈阳市铁西区'},
        {'code':'210111','title':'辽宁省沈阳市苏家屯区'},
        {'code':'210112','title':'辽宁省沈阳市东陵区'},
        {'code':'210113','title':'辽宁省沈阳市新城子区'},
        {'code':'210114','title':'辽宁省沈阳市于洪区'},
        {'code':'210122','title':'辽宁省沈阳市辽中县'},
        {'code':'210123','title':'辽宁省沈阳市康西县'},
        {'code':'210124','title':'辽宁省沈阳市法库县'},
        {'code':'210181','title':'辽宁省沈阳市新民市'},
        {'code':'210200','title':'辽宁省大连市'},
        {'code':'210201','title':'辽宁省大连市市辖区'},
        {'code':'210202','title':'辽宁省大连市中山区'},
        {'code':'210203','title':'辽宁省大连市西岗区'},
        {'code':'210204','title':'辽宁省大连市沙河口区'},
        {'code':'210211','title':'辽宁省大连市甘井子区'},
        {'code':'210212','title':'辽宁省大连市旅顺口区'},
        {'code':'210213','title':'辽宁省大连市金州区'},
        {'code':'210224','title':'辽宁省大连市长海县'},
        {'code':'210281','title':'辽宁省大连市瓦房店市'},
        {'code':'210282','title':'辽宁省大连市普兰店市'},
        {'code':'210283','title':'辽宁省大连市庄河市'},
        {'code':'210300','title':'辽宁省鞍山市'},
        {'code':'210301','title':'辽宁省鞍山市市辖区'},
        {'code':'210302','title':'辽宁省鞍山市铁东区'},
        {'code':'210303','title':'辽宁省鞍山市铁西区'},
        {'code':'210304','title':'辽宁省鞍山市立山区'},
        {'code':'210311','title':'辽宁省鞍山市千山区'},
        {'code':'210321','title':'辽宁省鞍山市台安县'},
        {'code':'210323','title':'辽宁省鞍山市岫岩满族自治县'},
        {'code':'210381','title':'辽宁省鞍山市海城市'},
        {'code':'210400','title':'辽宁省抚顺市'},
        {'code':'210401','title':'辽宁省抚顺市市辖区'},
        {'code':'210402','title':'辽宁省抚顺市新抚区'},
        {'code':'210403','title':'辽宁省抚顺市露天区'},
        {'code':'210404','title':'辽宁省抚顺市望花区'},
        {'code':'210411','title':'辽宁省抚顺市顺城区'},
        {'code':'210421','title':'辽宁省抚顺市抚顺县'},
        {'code':'210422','title':'辽宁省抚顺市新宾满族自治县'},
        {'code':'210423','title':'辽宁省抚顺市清原满族自治县'},
        {'code':'210500','title':'辽宁省本溪市'},
        {'code':'210501','title':'辽宁省本溪市市辖区'},
        {'code':'210502','title':'辽宁省本溪市西山区'},
        {'code':'210503','title':'辽宁省本溪市溪湖区'},
        {'code':'210504','title':'辽宁省本溪市明山区'},
        {'code':'210505','title':'辽宁省本溪市南芬区'},
        {'code':'210521','title':'辽宁省本溪市本溪满族自治县'},
        {'code':'210522','title':'辽宁省本溪市桓仁满族自治县'},
        {'code':'210600','title':'辽宁省丹东市'},
        {'code':'210601','title':'辽宁省丹东市市辖区'},
        {'code':'210602','title':'辽宁省丹东市元宝区'},
        {'code':'210603','title':'辽宁省丹东市振兴区'},
        {'code':'210604','title':'辽宁省丹东市振安区'},
        {'code':'210624','title':'辽宁省丹东市宽甸满族自治县'},
        {'code':'210681','title':'辽宁省丹东市东港市'},
        {'code':'210682','title':'辽宁省丹东市凤城市'},
        {'code':'210700','title':'辽宁省锦州市'},
        {'code':'210701','title':'辽宁省锦州市市辖区'},
        {'code':'210702','title':'辽宁省锦州市古塔区'},
        {'code':'210703','title':'辽宁省锦州市凌河区'},
        {'code':'210711','title':'辽宁省锦州市太和区'},
        {'code':'210726','title':'辽宁省锦州市黑山县'},
        {'code':'210727','title':'辽宁省锦州市义县'},
        {'code':'210781','title':'辽宁省锦州市凌海市'},
        {'code':'210782','title':'辽宁省锦州市北宁市'},
        {'code':'210800','title':'辽宁省营口市'},
        {'code':'210801','title':'辽宁省营口市市辖区'},
        {'code':'210802','title':'辽宁省营口市站前区'},
        {'code':'210803','title':'辽宁省营口市西市区'},
        {'code':'210804','title':'辽宁省营口市鲅鱼圈区'},
        {'code':'210811','title':'辽宁省营口市老边区'},
        {'code':'210881','title':'辽宁省营口市盖州市'},
        {'code':'210882','title':'辽宁省营口市大石桥市'},
        {'code':'210900','title':'辽宁省阜新市'},
        {'code':'210901','title':'辽宁省阜新市市辖区'},
        {'code':'210902','title':'辽宁省阜新市海州区'},
        {'code':'210903','title':'辽宁省阜新市新邱区'},
        {'code':'210904','title':'辽宁省阜新市太西区'},
        {'code':'210905','title':'辽宁省阜新市清河门区'},
        {'code':'210911','title':'辽宁省阜新市细河区'},
        {'code':'210921','title':'辽宁省阜新市阜新蒙古族自治县'},
        {'code':'210922','title':'辽宁省阜新市彰武县'},
        {'code':'211000','title':'辽宁省辽阳市'},
        {'code':'211001','title':'辽宁省辽阳市市辖区'},
        {'code':'211002','title':'辽宁省辽阳市白塔区'},
        {'code':'211003','title':'辽宁省辽阳市文圣区'},
        {'code':'211004','title':'辽宁省辽阳市宏伟区'},
        {'code':'211005','title':'辽宁省辽阳市弓长岭区'},
        {'code':'211011','title':'辽宁省辽阳市太子河区'},
        {'code':'211021','title':'辽宁省辽阳市辽阳县'},
        {'code':'211081','title':'辽宁省辽阳市灯塔市'},
        {'code':'211100','title':'辽宁省盘锦市'},
        {'code':'211101','title':'辽宁省盘锦市市辖区'},
        {'code':'211102','title':'辽宁省盘锦市双台子区'},
        {'code':'211103','title':'辽宁省盘锦市兴隆台区'},
        {'code':'211121','title':'辽宁省盘锦市大洼县'},
        {'code':'211122','title':'辽宁省盘锦市盘山县'},
        {'code':'211200','title':'辽宁省铁岭市'},
        {'code':'211201','title':'辽宁省铁岭市市辖区'},
        {'code':'211202','title':'辽宁省铁岭市银州区'},
        {'code':'211204','title':'辽宁省铁岭市清河区'},
        {'code':'211221','title':'辽宁省铁岭市铁岭县'},
        {'code':'211223','title':'辽宁省铁岭市西丰县'},
        {'code':'211224','title':'辽宁省铁岭市昌图县'},
        {'code':'211281','title':'辽宁省铁岭市铁法市'},
        {'code':'211282','title':'辽宁省铁岭市开原市'},
        {'code':'211300','title':'辽宁省朝阳市'},
        {'code':'211301','title':'辽宁省朝阳市市辖区'},
        {'code':'211302','title':'辽宁省朝阳市双塔区'},
        {'code':'211303','title':'辽宁省朝阳市龙城区'},
        {'code':'211321','title':'辽宁省朝阳市朝阳县'},
        {'code':'211322','title':'辽宁省朝阳市建西县'},
        {'code':'211324','title':'辽宁省朝阳市喀喇沁左翼蒙古族自治县'},
        {'code':'211381','title':'辽宁省朝阳市北票市'},
        {'code':'211382','title':'辽宁省朝阳市凌源市'},
        {'code':'211400','title':'辽宁省葫芦岛市'},
        {'code':'211401','title':'辽宁省葫芦岛市市辖区'},
        {'code':'211402','title':'辽宁省葫芦岛市连山区'},
        {'code':'211403','title':'辽宁省葫芦岛市龙港区'},
        {'code':'211404','title':'辽宁省葫芦岛市南票区'},
        {'code':'211421','title':'辽宁省葫芦岛市绥中县'},
        {'code':'211422','title':'辽宁省葫芦岛市建昌县'},
        {'code':'211481','title':'辽宁省葫芦岛市兴城市'},
        {'code':'220000','title':'吉林省'},
        {'code':'220100','title':'吉林省长春市'},
        {'code':'220101','title':'吉林省长春市市辖区'},
        {'code':'220102','title':'吉林省长春市南关区'},
        {'code':'220103','title':'吉林省长春市宽城区'},
        {'code':'220104','title':'吉林省长春市朝阳区'},
        {'code':'220105','title':'吉林省长春市二道区'},
        {'code':'220106','title':'吉林省长春市绿园区'},
        {'code':'220112','title':'吉林省长春市双阳区'},
        {'code':'220122','title':'吉林省长春市农安县'},
        {'code':'220181','title':'吉林省长春市九台市'},
        {'code':'220182','title':'吉林省长春市榆树市'},
        {'code':'220183','title':'吉林省长春市德惠市'},
        {'code':'220200','title':'吉林省吉林市'},
        {'code':'220201','title':'吉林省吉林市市辖区'},
        {'code':'220202','title':'吉林省吉林市昌邑区'},
        {'code':'220203','title':'吉林省吉林市龙潭区'},
        {'code':'220204','title':'吉林省吉林市船营区'},
        {'code':'220211','title':'吉林省吉林市丰满区'},
        {'code':'220221','title':'吉林省吉林市永吉县'},
        {'code':'220281','title':'吉林省吉林市蛟河市'},
        {'code':'220282','title':'吉林省吉林市桦甸市'},
        {'code':'220283','title':'吉林省吉林市舒兰市'},
        {'code':'220284','title':'吉林省吉林市磐石市'},
        {'code':'220300','title':'吉林省四西市'},
        {'code':'220301','title':'吉林省四西市市辖区'},
        {'code':'220302','title':'吉林省四西市铁西区'},
        {'code':'220303','title':'吉林省四西市铁东区'},
        {'code':'220322','title':'吉林省四西市梨树县'},
        {'code':'220323','title':'吉林省四西市伊通满族自治县'},
        {'code':'220381','title':'吉林省四西市公主岭市'},
        {'code':'220382','title':'吉林省四西市双辽市'},
        {'code':'220400','title':'吉林省辽源市'},
        {'code':'220401','title':'吉林省辽源市市辖区'},
        {'code':'220402','title':'吉林省辽源市龙山区'},
        {'code':'220403','title':'吉林省辽源市西安区'},
        {'code':'220421','title':'吉林省辽源市东丰县'},
        {'code':'220422','title':'吉林省辽源市东辽县'},
        {'code':'220500','title':'吉林省通化市'},
        {'code':'220501','title':'吉林省通化市市辖区'},
        {'code':'220502','title':'吉林省通化市东昌区'},
        {'code':'220503','title':'吉林省通化市二道江区'},
        {'code':'220521','title':'吉林省通化市通化县'},
        {'code':'220523','title':'吉林省通化市辉南县'},
        {'code':'220524','title':'吉林省通化市柳河县'},
        {'code':'220581','title':'吉林省通化市梅河口市'},
        {'code':'220582','title':'吉林省通化市集安市'},
        {'code':'220600','title':'吉林省白山市'},
        {'code':'220601','title':'吉林省白山市市辖区'},
        {'code':'220602','title':'吉林省白山市八道江区'},
        {'code':'220621','title':'吉林省白山市抚松县'},
        {'code':'220622','title':'吉林省白山市靖宇县'},
        {'code':'220623','title':'吉林省白山市长白朝鲜族自治县'},
        {'code':'220625','title':'吉林省白山市江源县'},
        {'code':'220681','title':'吉林省白山市临江市'},
        {'code':'220700','title':'吉林省松原市'},
        {'code':'220701','title':'吉林省松原市市辖区'},
        {'code':'220702','title':'吉林省松原市宁江区'},
        {'code':'220721','title':'吉林省松原市前郭尔罗斯蒙古族自治县'},
        {'code':'220722','title':'吉林省松原市长岭县'},
        {'code':'220723','title':'吉林省松原市乾安县'},
        {'code':'220724','title':'吉林省松原市扶余县'},
        {'code':'220800','title':'吉林省白城市'},
        {'code':'220801','title':'吉林省白城市市辖区'},
        {'code':'220802','title':'吉林省白城市洮北区'},
        {'code':'220821','title':'吉林省白城市镇赉县'},
        {'code':'220822','title':'吉林省白城市通榆县'},
        {'code':'220881','title':'吉林省白城市洮南市'},
        {'code':'220882','title':'吉林省白城市大安市'},
        {'code':'222400','title':'吉林省延边朝鲜族自治州'},
        {'code':'222401','title':'吉林省延边朝鲜族自治州延吉市'},
        {'code':'222402','title':'吉林省延边朝鲜族自治州图们市'},
        {'code':'222403','title':'吉林省延边朝鲜族自治州敦化市'},
        {'code':'222404','title':'吉林省延边朝鲜族自治州珲春市'},
        {'code':'222405','title':'吉林省延边朝鲜族自治州龙井市'},
        {'code':'222406','title':'吉林省延边朝鲜族自治州和龙市'},
        {'code':'222424','title':'吉林省延边朝鲜族自治州汪清县'},
        {'code':'222426','title':'吉林省延边朝鲜族自治州安图县'},
        {'code':'230000','title':'黑龙江省'},
        {'code':'230100','title':'黑龙江省哈尔滨市'},
        {'code':'230101','title':'黑龙江省哈尔滨市市辖区'},
        {'code':'230102','title':'黑龙江省哈尔滨市道里区'},
        {'code':'230103','title':'黑龙江省哈尔滨市南岗区'},
        {'code':'230104','title':'黑龙江省哈尔滨市道外区'},
        {'code':'230105','title':'黑龙江省哈尔滨市太西区'},
        {'code':'230106','title':'黑龙江省哈尔滨市香坊区'},
        {'code':'230107','title':'黑龙江省哈尔滨市动力区'},
        {'code':'230108','title':'黑龙江省哈尔滨市西房区'},
        {'code':'230121','title':'黑龙江省哈尔滨市呼兰县'},
        {'code':'230123','title':'黑龙江省哈尔滨市依兰县'},
        {'code':'230124','title':'黑龙江省哈尔滨市方正县'},
        {'code':'230125','title':'黑龙江省哈尔滨市宾县'},
        {'code':'230126','title':'黑龙江省哈尔滨市巴彦县'},
        {'code':'230127','title':'黑龙江省哈尔滨市木兰县'},
        {'code':'230128','title':'黑龙江省哈尔滨市通河县'},
        {'code':'230129','title':'黑龙江省哈尔滨市延寿县'},
        {'code':'230181','title':'黑龙江省哈尔滨市阿城市'},
        {'code':'230182','title':'黑龙江省哈尔滨市双城市'},
        {'code':'230183','title':'黑龙江省哈尔滨市尚志市'},
        {'code':'230184','title':'黑龙江省哈尔滨市五常市'},
        {'code':'230200','title':'黑龙江省齐齐哈尔市'},
        {'code':'230201','title':'黑龙江省齐齐哈尔市市辖区'},
        {'code':'230202','title':'黑龙江省齐齐哈尔市龙沙区'},
        {'code':'230203','title':'黑龙江省齐齐哈尔市建华区'},
        {'code':'230204','title':'黑龙江省齐齐哈尔市铁锋区'},
        {'code':'230205','title':'黑龙江省齐齐哈尔市昂昂溪区'},
        {'code':'230206','title':'黑龙江省齐齐哈尔市富拉尔基区'},
        {'code':'230207','title':'黑龙江省齐齐哈尔市碾子山区'},
        {'code':'230208','title':'黑龙江省齐齐哈尔市梅里斯达斡尔族区'},
        {'code':'230221','title':'黑龙江省齐齐哈尔市龙江县'},
        {'code':'230223','title':'黑龙江省齐齐哈尔市依安县'},
        {'code':'230224','title':'黑龙江省齐齐哈尔市泰来县'},
        {'code':'230225','title':'黑龙江省齐齐哈尔市甘南县'},
        {'code':'230227','title':'黑龙江省齐齐哈尔市富裕县'},
        {'code':'230229','title':'黑龙江省齐齐哈尔市克山县'},
        {'code':'230230','title':'黑龙江省齐齐哈尔市克东县'},
        {'code':'230231','title':'黑龙江省齐齐哈尔市拜泉县'},
        {'code':'230281','title':'黑龙江省齐齐哈尔市讷河市'},
        {'code':'230300','title':'黑龙江省鸡西市'},
        {'code':'230301','title':'黑龙江省鸡西市市辖区'},
        {'code':'230302','title':'黑龙江省鸡西市鸡冠区'},
        {'code':'230303','title':'黑龙江省鸡西市恒山区'},
        {'code':'230304','title':'黑龙江省鸡西市滴道区'},
        {'code':'230305','title':'黑龙江省鸡西市梨树区'},
        {'code':'230306','title':'黑龙江省鸡西市城子河区'},
        {'code':'230307','title':'黑龙江省鸡西市麻山区'},
        {'code':'230321','title':'黑龙江省鸡西市鸡东县'},
        {'code':'230381','title':'黑龙江省鸡西市虎林市'},
        {'code':'230382','title':'黑龙江省鸡西市密山市'},
        {'code':'230400','title':'黑龙江省鹤岗市'},
        {'code':'230401','title':'黑龙江省鹤岗市市辖区'},
        {'code':'230402','title':'黑龙江省鹤岗市向阳区'},
        {'code':'230403','title':'黑龙江省鹤岗市工农区'},
        {'code':'230404','title':'黑龙江省鹤岗市南山区'},
        {'code':'230405','title':'黑龙江省鹤岗市兴安区'},
        {'code':'230406','title':'黑龙江省鹤岗市东山区'},
        {'code':'230407','title':'黑龙江省鹤岗市兴山区'},
        {'code':'230421','title':'黑龙江省鹤岗市萝北县'},
        {'code':'230422','title':'黑龙江省鹤岗市绥滨县'},
        {'code':'230500','title':'黑龙江省双鸭山市'},
        {'code':'230501','title':'黑龙江省双鸭山市市辖区'},
        {'code':'230502','title':'黑龙江省双鸭山市尖山区'},
        {'code':'230503','title':'黑龙江省双鸭山市岭东区'},
        {'code':'230505','title':'黑龙江省双鸭山市四方台区'},
        {'code':'230506','title':'黑龙江省双鸭山市宝山区'},
        {'code':'230521','title':'黑龙江省双鸭山市集贤县'},
        {'code':'230522','title':'黑龙江省双鸭山市友谊县'},
        {'code':'230523','title':'黑龙江省双鸭山市宝清县'},
        {'code':'230524','title':'黑龙江省双鸭山市饶河县'},
        {'code':'230600','title':'黑龙江省大庆市'},
        {'code':'230601','title':'黑龙江省大庆市市辖区'},
        {'code':'230602','title':'黑龙江省大庆市萨尔图区'},
        {'code':'230603','title':'黑龙江省大庆市龙凤区'},
        {'code':'230604','title':'黑龙江省大庆市让胡路区'},
        {'code':'230605','title':'黑龙江省大庆市红岗区'},
        {'code':'230606','title':'黑龙江省大庆市大同区'},
        {'code':'230621','title':'黑龙江省大庆市肇州县'},
        {'code':'230622','title':'黑龙江省大庆市肇源县'},
        {'code':'230623','title':'黑龙江省大庆市林甸县'},
        {'code':'230624','title':'黑龙江省大庆市杜尔伯特蒙古族自治县'},
        {'code':'230700','title':'黑龙江省伊春市'},
        {'code':'230701','title':'黑龙江省伊春市市辖区'},
        {'code':'230702','title':'黑龙江省伊春市伊春区'},
        {'code':'230703','title':'黑龙江省伊春市南岔区'},
        {'code':'230704','title':'黑龙江省伊春市友好区'},
        {'code':'230705','title':'黑龙江省伊春市西林区'},
        {'code':'230706','title':'黑龙江省伊春市翠峦区'},
        {'code':'230707','title':'黑龙江省伊春市新青区'},
        {'code':'230708','title':'黑龙江省伊春市美溪区'},
        {'code':'230709','title':'黑龙江省伊春市金山屯区'},
        {'code':'230710','title':'黑龙江省伊春市五营区'},
        {'code':'230711','title':'黑龙江省伊春市乌马河区'},
        {'code':'230712','title':'黑龙江省伊春市汤旺河区'},
        {'code':'230713','title':'黑龙江省伊春市带岭区'},
        {'code':'230714','title':'黑龙江省伊春市乌伊岭区'},
        {'code':'230715','title':'黑龙江省伊春市红星区'},
        {'code':'230716','title':'黑龙江省伊春市上甘岭区'},
        {'code':'230722','title':'黑龙江省伊春市嘉荫县'},
        {'code':'230781','title':'黑龙江省伊春市铁力市'},
        {'code':'230800','title':'黑龙江省佳木斯市'},
        {'code':'230801','title':'黑龙江省佳木斯市市辖区'},
        {'code':'230802','title':'黑龙江省佳木斯市永红区'},
        {'code':'230803','title':'黑龙江省佳木斯市向阳区'},
        {'code':'230804','title':'黑龙江省佳木斯市前进区'},
        {'code':'230805','title':'黑龙江省佳木斯市东风区'},
        {'code':'230811','title':'黑龙江省佳木斯市郊区'},
        {'code':'230822','title':'黑龙江省佳木斯市桦南县'},
        {'code':'230826','title':'黑龙江省佳木斯市桦川县'},
        {'code':'230828','title':'黑龙江省佳木斯市汤原县'},
        {'code':'230833','title':'黑龙江省佳木斯市抚远县'},
        {'code':'230881','title':'黑龙江省佳木斯市同江市'},
        {'code':'230882','title':'黑龙江省佳木斯市富锦市'},
        {'code':'230900','title':'黑龙江省七台河市'},
        {'code':'230901','title':'黑龙江省七台河市市辖区'},
        {'code':'230902','title':'黑龙江省七台河市新兴区'},
        {'code':'230903','title':'黑龙江省七台河市桃山区'},
        {'code':'230904','title':'黑龙江省七台河市茄子河区'},
        {'code':'230921','title':'黑龙江省七台河市勃利县'},
        {'code':'231000','title':'黑龙江省牡丹江市'},
        {'code':'231001','title':'黑龙江省牡丹江市市辖区'},
        {'code':'231002','title':'黑龙江省牡丹江市东安区'},
        {'code':'231003','title':'黑龙江省牡丹江市阳明区'},
        {'code':'231004','title':'黑龙江省牡丹江市爱民区'},
        {'code':'231005','title':'黑龙江省牡丹江市西安区'},
        {'code':'231024','title':'黑龙江省牡丹江市东宁县'},
        {'code':'231025','title':'黑龙江省牡丹江市林口县'},
        {'code':'231081','title':'黑龙江省牡丹江市绥芬河市'},
        {'code':'231083','title':'黑龙江省牡丹江市海林市'},
        {'code':'231084','title':'黑龙江省牡丹江市宁安市'},
        {'code':'231085','title':'黑龙江省牡丹江市穆棱市'},
        {'code':'231100','title':'黑龙江省黑河市'},
        {'code':'231101','title':'黑龙江省黑河市市辖区'},
        {'code':'231102','title':'黑龙江省黑河市爱辉区'},
        {'code':'231121','title':'黑龙江省黑河市嫩江县'},
        {'code':'231123','title':'黑龙江省黑河市逊克县'},
        {'code':'231124','title':'黑龙江省黑河市孙吴县'},
        {'code':'231181','title':'黑龙江省黑河市北安市'},
        {'code':'231182','title':'黑龙江省黑河市五大连池市'},
        {'code':'232300','title':'黑龙江省绥化地区'},
        {'code':'232301','title':'黑龙江省绥化地区绥化市'},
        {'code':'232302','title':'黑龙江省绥化地区安达市'},
        {'code':'232303','title':'黑龙江省绥化地区肇东市'},
        {'code':'232304','title':'黑龙江省绥化地区海伦市'},
        {'code':'232324','title':'黑龙江省绥化地区望奎县'},
        {'code':'232325','title':'黑龙江省绥化地区兰西县'},
        {'code':'232326','title':'黑龙江省绥化地区青冈县'},
        {'code':'232330','title':'黑龙江省绥化地区庆安县'},
        {'code':'232331','title':'黑龙江省绥化地区明水县'},
        {'code':'232332','title':'黑龙江省绥化地区绥棱县'},
        {'code':'232700','title':'黑龙江省大兴安岭地区'},
        {'code':'232721','title':'黑龙江省大兴安岭地区呼玛县'},
        {'code':'232722','title':'黑龙江省大兴安岭地区塔河县'},
        {'code':'232723','title':'黑龙江省大兴安岭地区漠河县'},
        {'code':'310000','title':'上海市'},
        {'code':'310100','title':'上海市市辖区'},
        {'code':'310101','title':'上海市黄浦区'},
        {'code':'310102','title':'上海市南市区'},
        {'code':'310103','title':'上海市卢湾区'},
        {'code':'310104','title':'上海市徐汇区'},
        {'code':'310105','title':'上海市长宁区'},
        {'code':'310106','title':'上海市静安区'},
        {'code':'310107','title':'上海市普陀区'},
        {'code':'310108','title':'上海市闸北区'},
        {'code':'310109','title':'上海市虹口区'},
        {'code':'310110','title':'上海市杨浦区'},
        {'code':'310112','title':'上海市闵行区'},
        {'code':'310113','title':'上海市宝山区'},
        {'code':'310114','title':'上海市嘉定区'},
        {'code':'310115','title':'上海市浦东新区'},
        {'code':'310116','title':'上海市金山区'},
        {'code':'310117','title':'上海市松江区'},
        {'code':'310200','title':'上海市县'},
        {'code':'310225','title':'上海市南汇县'},
        {'code':'310226','title':'上海市奉贤县'},
        {'code':'310229','title':'上海市青浦县'},
        {'code':'310230','title':'上海市崇明县'},
        {'code':'320000','title':'江苏省'},
        {'code':'320100','title':'江苏省南京市'},
        {'code':'320101','title':'江苏省南京市市辖区'},
        {'code':'320102','title':'江苏省南京市玄武区'},
        {'code':'320103','title':'江苏省南京市白下区'},
        {'code':'320104','title':'江苏省南京市秦淮区'},
        {'code':'320105','title':'江苏省南京市建邺区'},
        {'code':'320106','title':'江苏省南京市鼓楼区'},
        {'code':'320107','title':'江苏省南京市下关区'},
        {'code':'320111','title':'江苏省南京市浦口区'},
        {'code':'320112','title':'江苏省南京市大厂区'},
        {'code':'320113','title':'江苏省南京市栖霞区'},
        {'code':'320114','title':'江苏省南京市雨花台区'},
        {'code':'320121','title':'江苏省南京市江宁县'},
        {'code':'320122','title':'江苏省南京市江浦县'},
        {'code':'320123','title':'江苏省南京市六合县'},
        {'code':'320124','title':'江苏省南京市溧水县'},
        {'code':'320125','title':'江苏省南京市高淳县'},
        {'code':'320200','title':'江苏省无锡市'},
        {'code':'320201','title':'江苏省无锡市市辖区'},
        {'code':'320202','title':'江苏省无锡市崇安区'},
        {'code':'320203','title':'江苏省无锡市南长区'},
        {'code':'320204','title':'江苏省无锡市北塘区'},
        {'code':'320211','title':'江苏省无锡市郊区'},
        {'code':'320281','title':'江苏省无锡市江阴市'},
        {'code':'320282','title':'江苏省无锡市宜兴市'},
        {'code':'320283','title':'江苏省无锡市锡山市'},
        {'code':'320300','title':'江苏省徐州市'},
        {'code':'320301','title':'江苏省徐州市市辖区'},
        {'code':'320302','title':'江苏省徐州市鼓楼区'},
        {'code':'320303','title':'江苏省徐州市云龙区'},
        {'code':'320304','title':'江苏省徐州市九里区'},
        {'code':'320305','title':'江苏省徐州市贾汪区'},
        {'code':'320311','title':'江苏省徐州市泉山区'},
        {'code':'320321','title':'江苏省徐州市丰县'},
        {'code':'320322','title':'江苏省徐州市沛县'},
        {'code':'320323','title':'江苏省徐州市铜山县'},
        {'code':'320324','title':'江苏省徐州市睢宁县'},
        {'code':'320381','title':'江苏省徐州市新沂市'},
        {'code':'320382','title':'江苏省徐州市邳州市'},
        {'code':'320400','title':'江苏省常州市'},
        {'code':'320401','title':'江苏省常州市市辖区'},
        {'code':'320402','title':'江苏省常州市天宁区'},
        {'code':'320404','title':'江苏省常州市钟楼区'},
        {'code':'320405','title':'江苏省常州市戚墅堰区'},
        {'code':'320411','title':'江苏省常州市郊区'},
        {'code':'320481','title':'江苏省常州市溧阳市'},
        {'code':'320482','title':'江苏省常州市金坛市'},
        {'code':'320483','title':'江苏省常州市武进市'},
        {'code':'320500','title':'江苏省苏州市'},
        {'code':'320501','title':'江苏省苏州市市辖区'},
        {'code':'320502','title':'江苏省苏州市沧浪区'},
        {'code':'320503','title':'江苏省苏州市西江区'},
        {'code':'320504','title':'江苏省苏州市金阊区'},
        {'code':'320511','title':'江苏省苏州市郊区'},
        {'code':'320581','title':'江苏省苏州市常熟市'},
        {'code':'320582','title':'江苏省苏州市张家港市'},
        {'code':'320583','title':'江苏省苏州市昆山市'},
        {'code':'320584','title':'江苏省苏州市吴江市'},
        {'code':'320585','title':'江苏省苏州市太仓市'},
        {'code':'320586','title':'江苏省苏州市吴县市'},
        {'code':'320600','title':'江苏省南通市'},
        {'code':'320601','title':'江苏省南通市市辖区'},
        {'code':'320602','title':'江苏省南通市崇川区'},
        {'code':'320611','title':'江苏省南通市港闸区'},
        {'code':'320621','title':'江苏省南通市海安县'},
        {'code':'320623','title':'江苏省南通市如东县'},
        {'code':'320681','title':'江苏省南通市启东市'},
        {'code':'320682','title':'江苏省南通市如皋市'},
        {'code':'320683','title':'江苏省南通市通州市'},
        {'code':'320684','title':'江苏省南通市海门市'},
        {'code':'320700','title':'江苏省连云港市'},
        {'code':'320701','title':'江苏省连云港市市辖区'},
        {'code':'320703','title':'江苏省连云港市连云区'},
        {'code':'320704','title':'江苏省连云港市云台区'},
        {'code':'320705','title':'江苏省连云港市新浦区'},
        {'code':'320706','title':'江苏省连云港市海州区'},
        {'code':'320721','title':'江苏省连云港市赣榆县'},
        {'code':'320722','title':'江苏省连云港市东海县'},
        {'code':'320723','title':'江苏省连云港市灌云县'},
        {'code':'320724','title':'江苏省连云港市灌南县'},
        {'code':'320800','title':'江苏省淮阴市'},
        {'code':'320801','title':'江苏省淮阴市市辖区'},
        {'code':'320802','title':'江苏省淮阴市清河区'},
        {'code':'320811','title':'江苏省淮阴市清浦区'},
        {'code':'320821','title':'江苏省淮阴市淮阴县'},
        {'code':'320826','title':'江苏省淮阴市涟水县'},
        {'code':'320829','title':'江苏省淮阴市洪泽县'},
        {'code':'320830','title':'江苏省淮阴市盱眙县'},
        {'code':'320831','title':'江苏省淮阴市金湖县'},
        {'code':'320882','title':'江苏省淮阴市淮安市'},
        {'code':'320900','title':'江苏省盐城市'},
        {'code':'320901','title':'江苏省盐城市市辖区'},
        {'code':'320902','title':'江苏省盐城市城区'},
        {'code':'320921','title':'江苏省盐城市响水县'},
        {'code':'320922','title':'江苏省盐城市滨海县'},
        {'code':'320923','title':'江苏省盐城市阜宁县'},
        {'code':'320924','title':'江苏省盐城市射阳县'},
        {'code':'320925','title':'江苏省盐城市建湖县'},
        {'code':'320928','title':'江苏省盐城市盐都县'},
        {'code':'320981','title':'江苏省盐城市东台市'},
        {'code':'320982','title':'江苏省盐城市大丰市'},
        {'code':'321000','title':'江苏省扬州市'},
        {'code':'321001','title':'江苏省扬州市市辖区'},
        {'code':'321002','title':'江苏省扬州市广陵区'},
        {'code':'321011','title':'江苏省扬州市郊区'},
        {'code':'321023','title':'江苏省扬州市宝应县'},
        {'code':'321027','title':'江苏省扬州市邗江县'},
        {'code':'321081','title':'江苏省扬州市仪征市'},
        {'code':'321084','title':'江苏省扬州市高邮市'},
        {'code':'321088','title':'江苏省扬州市江都市'},
        {'code':'321100','title':'江苏省镇江市'},
        {'code':'321101','title':'江苏省镇江市市辖区'},
        {'code':'321102','title':'江苏省镇江市京口区'},
        {'code':'321111','title':'江苏省镇江市润州区'},
        {'code':'321121','title':'江苏省镇江市丹徒县'},
        {'code':'321181','title':'江苏省镇江市丹阳市'},
        {'code':'321182','title':'江苏省镇江市扬中市'},
        {'code':'321183','title':'江苏省镇江市句容市'},
        {'code':'321200','title':'江苏省泰州市'},
        {'code':'321201','title':'江苏省泰州市市辖区'},
        {'code':'321202','title':'江苏省泰州市海陵区'},
        {'code':'321203','title':'江苏省泰州市高港区'},
        {'code':'321281','title':'江苏省泰州市兴化市'},
        {'code':'321282','title':'江苏省泰州市靖江市'},
        {'code':'321283','title':'江苏省泰州市泰兴市'},
        {'code':'321284','title':'江苏省泰州市姜堰市'},
        {'code':'321300','title':'江苏省宿迁市'},
        {'code':'321301','title':'江苏省宿迁市市辖区'},
        {'code':'321302','title':'江苏省宿迁市宿城区'},
        {'code':'321321','title':'江苏省宿迁市宿豫县'},
        {'code':'321322','title':'江苏省宿迁市沭阳县'},
        {'code':'321323','title':'江苏省宿迁市泗阳县'},
        {'code':'321324','title':'江苏省宿迁市泗洪县'},
        {'code':'330000','title':'浙江省'},
        {'code':'330100','title':'浙江省杭州市'},
        {'code':'330101','title':'浙江省杭州市市辖区'},
        {'code':'330102','title':'浙江省杭州市上城区'},
        {'code':'330103','title':'浙江省杭州市下城区'},
        {'code':'330104','title':'浙江省杭州市江干区'},
        {'code':'330105','title':'浙江省杭州市拱墅区'},
        {'code':'330106','title':'浙江省杭州市西湖区'},
        {'code':'330108','title':'浙江省杭州市滨江区'},
        {'code':'330122','title':'浙江省杭州市桐庐县'},
        {'code':'330127','title':'浙江省杭州市淳安县'},
        {'code':'330181','title':'浙江省杭州市萧山市'},
        {'code':'330182','title':'浙江省杭州市建德市'},
        {'code':'330183','title':'浙江省杭州市富阳市'},
        {'code':'330184','title':'浙江省杭州市余杭市'},
        {'code':'330185','title':'浙江省杭州市临安市'},
        {'code':'330200','title':'浙江省宁波市'},
        {'code':'330201','title':'浙江省宁波市市辖区'},
        {'code':'330203','title':'浙江省宁波市海曙区'},
        {'code':'330204','title':'浙江省宁波市江东区'},
        {'code':'330205','title':'浙江省宁波市江北区'},
        {'code':'330206','title':'浙江省宁波市北仑区'},
        {'code':'330211','title':'浙江省宁波市镇海区'},
        {'code':'330225','title':'浙江省宁波市象山县'},
        {'code':'330226','title':'浙江省宁波市宁海县'},
        {'code':'330227','title':'浙江省宁波市鄞县'},
        {'code':'330281','title':'浙江省宁波市余姚市'},
        {'code':'330282','title':'浙江省宁波市慈溪市'},
        {'code':'330283','title':'浙江省宁波市奉化市'},
        {'code':'330300','title':'浙江省温州市'},
        {'code':'330301','title':'浙江省温州市市辖区'},
        {'code':'330302','title':'浙江省温州市鹿城区'},
        {'code':'330303','title':'浙江省温州市龙湾区'},
        {'code':'330304','title':'浙江省温州市瓯海区'},
        {'code':'330322','title':'浙江省温州市洞头县'},
        {'code':'330324','title':'浙江省温州市永嘉县'},
        {'code':'330326','title':'浙江省温州市西阳县'},
        {'code':'330327','title':'浙江省温州市苍南县'},
        {'code':'330328','title':'浙江省温州市文成县'},
        {'code':'330329','title':'浙江省温州市泰顺县'},
        {'code':'330381','title':'浙江省温州市瑞安市'},
        {'code':'330382','title':'浙江省温州市乐清市'},
        {'code':'330400','title':'浙江省嘉兴市'},
        {'code':'330401','title':'浙江省嘉兴市市辖区'},
        {'code':'330402','title':'浙江省嘉兴市秀城区'},
        {'code':'330411','title':'浙江省嘉兴市郊区'},
        {'code':'330421','title':'浙江省嘉兴市嘉善县'},
        {'code':'330424','title':'浙江省嘉兴市海盐县'},
        {'code':'330481','title':'浙江省嘉兴市海宁市'},
        {'code':'330482','title':'浙江省嘉兴市西湖市'},
        {'code':'330483','title':'浙江省嘉兴市桐乡市'},
        {'code':'330500','title':'浙江省湖州市'},
        {'code':'330501','title':'浙江省湖州市市辖区'},
        {'code':'330521','title':'浙江省湖州市德清县'},
        {'code':'330522','title':'浙江省湖州市长兴县'},
        {'code':'330523','title':'浙江省湖州市安吉县'},
        {'code':'330600','title':'浙江省绍兴市'},
        {'code':'330601','title':'浙江省绍兴市市辖区'},
        {'code':'330602','title':'浙江省绍兴市越城区'},
        {'code':'330621','title':'浙江省绍兴市绍兴县'},
        {'code':'330624','title':'浙江省绍兴市新昌县'},
        {'code':'330681','title':'浙江省绍兴市诸暨市'},
        {'code':'330682','title':'浙江省绍兴市上虞市'},
        {'code':'330683','title':'浙江省绍兴市嵊州市'},
        {'code':'330700','title':'浙江省金华市'},
        {'code':'330701','title':'浙江省金华市市辖区'},
        {'code':'330702','title':'浙江省金华市婺城区'},
        {'code':'330721','title':'浙江省金华市金华县'},
        {'code':'330723','title':'浙江省金华市武义县'},
        {'code':'330726','title':'浙江省金华市浦江县'},
        {'code':'330727','title':'浙江省金华市磐安县'},
        {'code':'330781','title':'浙江省金华市兰溪市'},
        {'code':'330782','title':'浙江省金华市义乌市'},
        {'code':'330783','title':'浙江省金华市东阳市'},
        {'code':'330784','title':'浙江省金华市永康市'},
        {'code':'330800','title':'浙江省衢州市'},
        {'code':'330801','title':'浙江省衢州市市辖区'},
        {'code':'330802','title':'浙江省衢州市柯城区'},
        {'code':'330821','title':'浙江省衢州市衢县'},
        {'code':'330822','title':'浙江省衢州市常山县'},
        {'code':'330824','title':'浙江省衢州市开化县'},
        {'code':'330825','title':'浙江省衢州市龙游县'},
        {'code':'330881','title':'浙江省衢州市江山市'},
        {'code':'330900','title':'浙江省舟山市'},
        {'code':'330901','title':'浙江省舟山市市辖区'},
        {'code':'330902','title':'浙江省舟山市定海区'},
        {'code':'330903','title':'浙江省舟山市普陀区'},
        {'code':'330921','title':'浙江省舟山市岱山县'},
        {'code':'330922','title':'浙江省舟山市嵊泗县'},
        {'code':'331000','title':'浙江省台州市'},
        {'code':'331001','title':'浙江省台州市市辖区'},
        {'code':'331002','title':'浙江省台州市椒江区'},
        {'code':'331003','title':'浙江省台州市黄岩区'},
        {'code':'331004','title':'浙江省台州市路桥区'},
        {'code':'331021','title':'浙江省台州市玉环县'},
        {'code':'331022','title':'浙江省台州市三门县'},
        {'code':'331023','title':'浙江省台州市天台县'},
        {'code':'331024','title':'浙江省台州市仙居县'},
        {'code':'331081','title':'浙江省台州市温岭市'},
        {'code':'331082','title':'浙江省台州市临海市'},
        {'code':'332500','title':'浙江省丽水地区'},
        {'code':'332501','title':'浙江省丽水地区丽水市'},
        {'code':'332502','title':'浙江省丽水地区龙泉市'},
        {'code':'332522','title':'浙江省丽水地区青田县'},
        {'code':'332523','title':'浙江省丽水地区云和县'},
        {'code':'332525','title':'浙江省丽水地区庆元县'},
        {'code':'332526','title':'浙江省丽水地区缙云县'},
        {'code':'332527','title':'浙江省丽水地区遂昌县'},
        {'code':'332528','title':'浙江省丽水地区松阳县'},
        {'code':'332529','title':'浙江省丽水地区景宁畲族自治县'},
        {'code':'340000','title':'安徽省'},
        {'code':'340100','title':'安徽省合肥市'},
        {'code':'340101','title':'安徽省合肥市市辖区'},
        {'code':'340102','title':'安徽省合肥市东市区'},
        {'code':'340103','title':'安徽省合肥市中市区'},
        {'code':'340104','title':'安徽省合肥市西市区'},
        {'code':'340111','title':'安徽省合肥市郊区'},
        {'code':'340121','title':'安徽省合肥市长丰县'},
        {'code':'340122','title':'安徽省合肥市肥东县'},
        {'code':'340123','title':'安徽省合肥市肥西县'},
        {'code':'340200','title':'安徽省芜湖市'},
        {'code':'340201','title':'安徽省芜湖市市辖区'},
        {'code':'340202','title':'安徽省芜湖市镜湖区'},
        {'code':'340203','title':'安徽省芜湖市马塘区'},
        {'code':'340204','title':'安徽省芜湖市新芜区'},
        {'code':'340207','title':'安徽省芜湖市鸠江区'},
        {'code':'340221','title':'安徽省芜湖市芜湖县'},
        {'code':'340222','title':'安徽省芜湖市繁昌县'},
        {'code':'340223','title':'安徽省芜湖市南陵县'},
        {'code':'340300','title':'安徽省蚌埠市'},
        {'code':'340301','title':'安徽省蚌埠市市辖区'},
        {'code':'340302','title':'安徽省蚌埠市东市区'},
        {'code':'340303','title':'安徽省蚌埠市中市区'},
        {'code':'340304','title':'安徽省蚌埠市西市区'},
        {'code':'340311','title':'安徽省蚌埠市郊区'},
        {'code':'340321','title':'安徽省蚌埠市怀远县'},
        {'code':'340322','title':'安徽省蚌埠市五河县'},
        {'code':'340323','title':'安徽省蚌埠市固镇县'},
        {'code':'340400','title':'安徽省淮南市'},
        {'code':'340401','title':'安徽省淮南市市辖区'},
        {'code':'340402','title':'安徽省淮南市大通区'},
        {'code':'340403','title':'安徽省淮南市田家庵区'},
        {'code':'340404','title':'安徽省淮南市谢家集区'},
        {'code':'340405','title':'安徽省淮南市八公山区'},
        {'code':'340406','title':'安徽省淮南市潘集区'},
        {'code':'340421','title':'安徽省淮南市凤台县'},
        {'code':'340500','title':'安徽省马鞍山市'},
        {'code':'340501','title':'安徽省马鞍山市市辖区'},
        {'code':'340502','title':'安徽省马鞍山市金家庄区'},
        {'code':'340503','title':'安徽省马鞍山市花山区'},
        {'code':'340504','title':'安徽省马鞍山市雨山区'},
        {'code':'340505','title':'安徽省马鞍山市向山区'},
        {'code':'340521','title':'安徽省马鞍山市当涂县'},
        {'code':'340600','title':'安徽省淮北市'},
        {'code':'340601','title':'安徽省淮北市市辖区'},
        {'code':'340602','title':'安徽省淮北市杜集区'},
        {'code':'340603','title':'安徽省淮北市相山区'},
        {'code':'340604','title':'安徽省淮北市烈山区'},
        {'code':'340621','title':'安徽省淮北市濉溪县'},
        {'code':'340700','title':'安徽省铜陵市'},
        {'code':'340701','title':'安徽省铜陵市市辖区'},
        {'code':'340702','title':'安徽省铜陵市铜官山区'},
        {'code':'340703','title':'安徽省铜陵市狮子山区'},
        {'code':'340711','title':'安徽省铜陵市郊区'},
        {'code':'340721','title':'安徽省铜陵市铜陵县'},
        {'code':'340800','title':'安徽省安庆市'},
        {'code':'340801','title':'安徽省安庆市市辖区'},
        {'code':'340802','title':'安徽省安庆市迎江区'},
        {'code':'340803','title':'安徽省安庆市大观区'},
        {'code':'340811','title':'安徽省安庆市郊区'},
        {'code':'340822','title':'安徽省安庆市怀宁县'},
        {'code':'340823','title':'安徽省安庆市枞阳县'},
        {'code':'340824','title':'安徽省安庆市潜山县'},
        {'code':'340825','title':'安徽省安庆市太湖县'},
        {'code':'340826','title':'安徽省安庆市宿松县'},
        {'code':'340827','title':'安徽省安庆市望江县'},
        {'code':'340828','title':'安徽省安庆市岳西县'},
        {'code':'340881','title':'安徽省安庆市桐城市'},
        {'code':'341000','title':'安徽省黄山市'},
        {'code':'341001','title':'安徽省黄山市市辖区'},
        {'code':'341002','title':'安徽省黄山市屯溪区'},
        {'code':'341003','title':'安徽省黄山市黄山区'},
        {'code':'341004','title':'安徽省黄山市徽州区'},
        {'code':'341021','title':'安徽省黄山市歙县'},
        {'code':'341022','title':'安徽省黄山市休宁县'},
        {'code':'341023','title':'安徽省黄山市黟县'},
        {'code':'341024','title':'安徽省黄山市祁门县'},
        {'code':'341100','title':'安徽省滁州市'},
        {'code':'341101','title':'安徽省滁州市市辖区'},
        {'code':'341102','title':'安徽省滁州市琅琊区'},
        {'code':'341103','title':'安徽省滁州市南谯区'},
        {'code':'341122','title':'安徽省滁州市来安县'},
        {'code':'341124','title':'安徽省滁州市全椒县'},
        {'code':'341125','title':'安徽省滁州市定远县'},
        {'code':'341126','title':'安徽省滁州市凤阳县'},
        {'code':'341181','title':'安徽省滁州市天长市'},
        {'code':'341182','title':'安徽省滁州市明光市'},
        {'code':'341200','title':'安徽省阜阳市'},
        {'code':'341201','title':'安徽省阜阳市市辖区'},
        {'code':'341202','title':'安徽省阜阳市颍州区'},
        {'code':'341203','title':'安徽省阜阳市颍东区'},
        {'code':'341204','title':'安徽省阜阳市颍泉区'},
        {'code':'341221','title':'安徽省阜阳市临泉县'},
        {'code':'341222','title':'安徽省阜阳市太和县'},
        {'code':'341223','title':'安徽省阜阳市涡阳县'},
        {'code':'341224','title':'安徽省阜阳市蒙城县'},
        {'code':'341225','title':'安徽省阜阳市阜南县'},
        {'code':'341226','title':'安徽省阜阳市颍上县'},
        {'code':'341227','title':'安徽省阜阳市利辛县'},
        {'code':'341281','title':'安徽省阜阳市亳州市'},
        {'code':'341282','title':'安徽省阜阳市界首市'},
        {'code':'341300','title':'安徽省宿州市'},
        {'code':'341301','title':'安徽省宿州市市辖区'},
        {'code':'341302','title':'安徽省宿州市甬桥区'},
        {'code':'341321','title':'安徽省宿州市砀山县'},
        {'code':'341322','title':'安徽省宿州市萧县'},
        {'code':'341323','title':'安徽省宿州市灵璧县'},
        {'code':'341324','title':'安徽省宿州市泗县'},
        {'code':'342400','title':'安徽省六安地区'},
        {'code':'342401','title':'安徽省六安地区六安市'},
        {'code':'342422','title':'安徽省六安地区寿县'},
        {'code':'342423','title':'安徽省六安地区霍邱县'},
        {'code':'342425','title':'安徽省六安地区舒城县'},
        {'code':'342426','title':'安徽省六安地区金寨县'},
        {'code':'342427','title':'安徽省六安地区霍山县'},
        {'code':'342500','title':'安徽省宣城地区'},
        {'code':'342501','title':'安徽省宣城地区宣州市'},
        {'code':'342502','title':'安徽省宣城地区宁国市'},
        {'code':'342522','title':'安徽省宣城地区郎溪县'},
        {'code':'342523','title':'安徽省宣城地区广德县'},
        {'code':'342529','title':'安徽省宣城地区泾县'},
        {'code':'342530','title':'安徽省宣城地区旌德县'},
        {'code':'342531','title':'安徽省宣城地区绩溪县'},
        {'code':'342600','title':'安徽省巢湖地区'},
        {'code':'342601','title':'安徽省巢湖地区巢湖市'},
        {'code':'342622','title':'安徽省巢湖地区庐江县'},
        {'code':'342623','title':'安徽省巢湖地区无为县'},
        {'code':'342625','title':'安徽省巢湖地区含山县'},
        {'code':'342626','title':'安徽省巢湖地区和县'},
        {'code':'342900','title':'安徽省池州地区'},
        {'code':'342901','title':'安徽省池州地区贵池市'},
        {'code':'342921','title':'安徽省池州地区东至县'},
        {'code':'342922','title':'安徽省池州地区石台县'},
        {'code':'342923','title':'安徽省池州地区青阳县'},
        {'code':'350000','title':'福建省'},
        {'code':'350100','title':'福建省福州市'},
        {'code':'350101','title':'福建省福州市市辖区'},
        {'code':'350102','title':'福建省福州市鼓楼区'},
        {'code':'350103','title':'福建省福州市台江区'},
        {'code':'350104','title':'福建省福州市仓山区'},
        {'code':'350105','title':'福建省福州市马尾区'},
        {'code':'350111','title':'福建省福州市西安区'},
        {'code':'350121','title':'福建省福州市闽侯县'},
        {'code':'350122','title':'福建省福州市连江县'},
        {'code':'350123','title':'福建省福州市罗源县'},
        {'code':'350124','title':'福建省福州市闽清县'},
        {'code':'350125','title':'福建省福州市永泰县'},
        {'code':'350128','title':'福建省福州市西潭县'},
        {'code':'350181','title':'福建省福州市福清市'},
        {'code':'350182','title':'福建省福州市长乐市'},
        {'code':'350200','title':'福建省厦门市'},
        {'code':'350201','title':'福建省厦门市市辖区'},
        {'code':'350202','title':'福建省厦门市鼓浪屿区'},
        {'code':'350203','title':'福建省厦门市思明区'},
        {'code':'350204','title':'福建省厦门市开元区'},
        {'code':'350205','title':'福建省厦门市杏林区'},
        {'code':'350206','title':'福建省厦门市湖里区'},
        {'code':'350211','title':'福建省厦门市集美区'},
        {'code':'350212','title':'福建省厦门市同安区'},
        {'code':'350300','title':'福建省莆田市'},
        {'code':'350301','title':'福建省莆田市市辖区'},
        {'code':'350302','title':'福建省莆田市城厢区'},
        {'code':'350303','title':'福建省莆田市涵江区'},
        {'code':'350321','title':'福建省莆田市莆田县'},
        {'code':'350322','title':'福建省莆田市仙游县'},
        {'code':'350400','title':'福建省三明市'},
        {'code':'350401','title':'福建省三明市市辖区'},
        {'code':'350402','title':'福建省三明市梅列区'},
        {'code':'350403','title':'福建省三明市三元区'},
        {'code':'350421','title':'福建省三明市明溪县'},
        {'code':'350423','title':'福建省三明市清流县'},
        {'code':'350424','title':'福建省三明市宁化县'},
        {'code':'350425','title':'福建省三明市大田县'},
        {'code':'350426','title':'福建省三明市尤溪县'},
        {'code':'350427','title':'福建省三明市沙县'},
        {'code':'350428','title':'福建省三明市将乐县'},
        {'code':'350429','title':'福建省三明市泰宁县'},
        {'code':'350430','title':'福建省三明市建宁县'},
        {'code':'350481','title':'福建省三明市永安市'},
        {'code':'350500','title':'福建省泉州市'},
        {'code':'350501','title':'福建省泉州市市辖区'},
        {'code':'350502','title':'福建省泉州市鲤城区'},
        {'code':'350503','title':'福建省泉州市丰泽区'},
        {'code':'350504','title':'福建省泉州市洛江区'},
        {'code':'350521','title':'福建省泉州市惠安县'},
        {'code':'350524','title':'福建省泉州市安溪县'},
        {'code':'350525','title':'福建省泉州市永春县'},
        {'code':'350526','title':'福建省泉州市德化县'},
        {'code':'350527','title':'福建省泉州市金门县'},
        {'code':'350581','title':'福建省泉州市石狮市'},
        {'code':'350582','title':'福建省泉州市西江市'},
        {'code':'350583','title':'福建省泉州市南安市'},
        {'code':'350600','title':'福建省漳州市'},
        {'code':'350601','title':'福建省漳州市市辖区'},
        {'code':'350602','title':'福建省漳州市芗城区'},
        {'code':'350603','title':'福建省漳州市龙文区'},
        {'code':'350622','title':'福建省漳州市云霄县'},
        {'code':'350623','title':'福建省漳州市漳浦县'},
        {'code':'350624','title':'福建省漳州市诏安县'},
        {'code':'350625','title':'福建省漳州市长泰县'},
        {'code':'350626','title':'福建省漳州市东山县'},
        {'code':'350627','title':'福建省漳州市南靖县'},
        {'code':'350628','title':'福建省漳州市西和县'},
        {'code':'350629','title':'福建省漳州市华安县'},
        {'code':'350681','title':'福建省漳州市龙海市'},
        {'code':'350700','title':'福建省南西市'},
        {'code':'350701','title':'福建省南西市市辖区'},
        {'code':'350702','title':'福建省南西市延西区'},
        {'code':'350721','title':'福建省南西市顺昌县'},
        {'code':'350722','title':'福建省南西市浦城县'},
        {'code':'350723','title':'福建省南西市光泽县'},
        {'code':'350724','title':'福建省南西市松溪县'},
        {'code':'350725','title':'福建省南西市政和县'},
        {'code':'350781','title':'福建省南西市邵武市'},
        {'code':'350782','title':'福建省南西市武夷山市'},
        {'code':'350783','title':'福建省南西市建瓯市'},
        {'code':'350784','title':'福建省南西市建阳市'},
        {'code':'350800','title':'福建省龙岩市'},
        {'code':'350801','title':'福建省龙岩市市辖区'},
        {'code':'350802','title':'福建省龙岩市新罗区'},
        {'code':'350821','title':'福建省龙岩市长汀县'},
        {'code':'350822','title':'福建省龙岩市永定县'},
        {'code':'350823','title':'福建省龙岩市上杭县'},
        {'code':'350824','title':'福建省龙岩市武西县'},
        {'code':'350825','title':'福建省龙岩市连城县'},
        {'code':'350881','title':'福建省龙岩市漳西市'},
        {'code':'352200','title':'福建省宁德地区'},
        {'code':'352201','title':'福建省宁德地区宁德市'},
        {'code':'352202','title':'福建省宁德地区福安市'},
        {'code':'352203','title':'福建省宁德地区福鼎市'},
        {'code':'352225','title':'福建省宁德地区霞浦县'},
        {'code':'352227','title':'福建省宁德地区古田县'},
        {'code':'352228','title':'福建省宁德地区屏南县'},
        {'code':'352229','title':'福建省宁德地区寿宁县'},
        {'code':'352230','title':'福建省宁德地区周宁县'},
        {'code':'352231','title':'福建省宁德地区柘荣县'},
        {'code':'360000','title':'江西省'},
        {'code':'360100','title':'江西省南昌市'},
        {'code':'360101','title':'江西省南昌市市辖区'},
        {'code':'360102','title':'江西省南昌市东湖区'},
        {'code':'360103','title':'江西省南昌市西湖区'},
        {'code':'360104','title':'江西省南昌市青云谱区'},
        {'code':'360105','title':'江西省南昌市湾里区'},
        {'code':'360111','title':'江西省南昌市郊区'},
        {'code':'360121','title':'江西省南昌市南昌县'},
        {'code':'360122','title':'江西省南昌市新建县'},
        {'code':'360123','title':'江西省南昌市安义县'},
        {'code':'360124','title':'江西省南昌市进贤县'},
        {'code':'360200','title':'江西省景德镇市'},
        {'code':'360201','title':'江西省景德镇市市辖区'},
        {'code':'360202','title':'江西省景德镇市昌江区'},
        {'code':'360203','title':'江西省景德镇市珠山区'},
        {'code':'360222','title':'江西省景德镇市浮梁县'},
        {'code':'360281','title':'江西省景德镇市乐西市'},
        {'code':'360300','title':'江西省萍乡市'},
        {'code':'360301','title':'江西省萍乡市市辖区'},
        {'code':'360302','title':'江西省萍乡市安源区'},
        {'code':'360313','title':'江西省萍乡市湘东区'},
        {'code':'360321','title':'江西省萍乡市莲花县'},
        {'code':'360322','title':'江西省萍乡市上栗县'},
        {'code':'360323','title':'江西省萍乡市芦溪县'},
        {'code':'360400','title':'江西省九江市'},
        {'code':'360401','title':'江西省九江市市辖区'},
        {'code':'360402','title':'江西省九江市庐山区'},
        {'code':'360403','title':'江西省九江市浔阳区'},
        {'code':'360421','title':'江西省九江市九江县'},
        {'code':'360423','title':'江西省九江市武宁县'},
        {'code':'360424','title':'江西省九江市修水县'},
        {'code':'360425','title':'江西省九江市永修县'},
        {'code':'360426','title':'江西省九江市德安县'},
        {'code':'360427','title':'江西省九江市星子县'},
        {'code':'360428','title':'江西省九江市都昌县'},
        {'code':'360429','title':'江西省九江市湖口县'},
        {'code':'360430','title':'江西省九江市彭泽县'},
        {'code':'360481','title':'江西省九江市瑞昌市'},
        {'code':'360500','title':'江西省新余市'},
        {'code':'360501','title':'江西省新余市市辖区'},
        {'code':'360502','title':'江西省新余市渝水区'},
        {'code':'360521','title':'江西省新余市分宜县'},
        {'code':'360600','title':'江西省鹰潭市'},
        {'code':'360601','title':'江西省鹰潭市市辖区'},
        {'code':'360602','title':'江西省鹰潭市月湖区'},
        {'code':'360622','title':'江西省鹰潭市余江县'},
        {'code':'360681','title':'江西省鹰潭市贵溪市'},
        {'code':'360700','title':'江西省赣州市'},
        {'code':'360701','title':'江西省赣州市市辖区'},
        {'code':'360702','title':'江西省赣州市章贡区'},
        {'code':'360721','title':'江西省赣州市赣县'},
        {'code':'360722','title':'江西省赣州市信丰县'},
        {'code':'360723','title':'江西省赣州市大余县'},
        {'code':'360724','title':'江西省赣州市上犹县'},
        {'code':'360725','title':'江西省赣州市崇义县'},
        {'code':'360726','title':'江西省赣州市安远县'},
        {'code':'360727','title':'江西省赣州市龙南县'},
        {'code':'360728','title':'江西省赣州市定南县'},
        {'code':'360729','title':'江西省赣州市全南县'},
        {'code':'360730','title':'江西省赣州市宁都县'},
        {'code':'360731','title':'江西省赣州市于都县'},
        {'code':'360732','title':'江西省赣州市兴国县'},
        {'code':'360733','title':'江西省赣州市会昌县'},
        {'code':'360734','title':'江西省赣州市寻乌县'},
        {'code':'360735','title':'江西省赣州市石城县'},
        {'code':'360781','title':'江西省赣州市瑞金市'},
        {'code':'360782','title':'江西省赣州市南康市'},
        {'code':'362200','title':'江西省宜春地区'},
        {'code':'362201','title':'江西省宜春地区宜春市'},
        {'code':'362202','title':'江西省宜春地区丰城市'},
        {'code':'362203','title':'江西省宜春地区樟树市'},
        {'code':'362204','title':'江西省宜春地区高安市'},
        {'code':'362226','title':'江西省宜春地区奉新县'},
        {'code':'362227','title':'江西省宜春地区万载县'},
        {'code':'362228','title':'江西省宜春地区上高县'},
        {'code':'362229','title':'江西省宜春地区宜丰县'},
        {'code':'362232','title':'江西省宜春地区靖安县'},
        {'code':'362233','title':'江西省宜春地区铜鼓县'},
        {'code':'362300','title':'江西省上饶地区'},
        {'code':'362301','title':'江西省上饶地区上饶市'},
        {'code':'362302','title':'江西省上饶地区德兴市'},
        {'code':'362321','title':'江西省上饶地区上饶县'},
        {'code':'362322','title':'江西省上饶地区广丰县'},
        {'code':'362323','title':'江西省上饶地区玉山县'},
        {'code':'362324','title':'江西省上饶地区铅山县'},
        {'code':'362325','title':'江西省上饶地区横峰县'},
        {'code':'362326','title':'江西省上饶地区弋阳县'},
        {'code':'362329','title':'江西省上饶地区余干县'},
        {'code':'362330','title':'江西省上饶地区波阳县'},
        {'code':'362331','title':'江西省上饶地区万年县'},
        {'code':'362334','title':'江西省上饶地区婺源县'},
        {'code':'362400','title':'江西省吉安地区'},
        {'code':'362401','title':'江西省吉安地区吉安市'},
        {'code':'362402','title':'江西省吉安地区井冈山市'},
        {'code':'362421','title':'江西省吉安地区吉安县'},
        {'code':'362422','title':'江西省吉安地区吉水县'},
        {'code':'362423','title':'江西省吉安地区峡江县'},
        {'code':'362424','title':'江西省吉安地区新干县'},
        {'code':'362425','title':'江西省吉安地区永丰县'},
        {'code':'362426','title':'江西省吉安地区泰和县'},
        {'code':'362427','title':'江西省吉安地区遂川县'},
        {'code':'362428','title':'江西省吉安地区万安县'},
        {'code':'362429','title':'江西省吉安地区安福县'},
        {'code':'362430','title':'江西省吉安地区永新县'},
        {'code':'362432','title':'江西省吉安地区宁冈县'},
        {'code':'362500','title':'江西省抚州地区'},
        {'code':'362502','title':'江西省抚州地区临川市'},
        {'code':'362522','title':'江西省抚州地区南城县'},
        {'code':'362523','title':'江西省抚州地区黎川县'},
        {'code':'362524','title':'江西省抚州地区南丰县'},
        {'code':'362525','title':'江西省抚州地区崇仁县'},
        {'code':'362526','title':'江西省抚州地区乐安县'},
        {'code':'362527','title':'江西省抚州地区宜黄县'},
        {'code':'362528','title':'江西省抚州地区金溪县'},
        {'code':'362529','title':'江西省抚州地区资溪县'},
        {'code':'362531','title':'江西省抚州地区东乡县'},
        {'code':'362532','title':'江西省抚州地区广昌县'},
        {'code':'370000','title':'山东省'},
        {'code':'370100','title':'山东省济南市'},
        {'code':'370101','title':'山东省济南市市辖区'},
        {'code':'370102','title':'山东省济南市历下区'},
        {'code':'370103','title':'山东省济南市市中区'},
        {'code':'370104','title':'山东省济南市槐荫区'},
        {'code':'370105','title':'山东省济南市天桥区'},
        {'code':'370112','title':'山东省济南市历城区'},
        {'code':'370123','title':'山东省济南市长清县'},
        {'code':'370124','title':'山东省济南市西阴县'},
        {'code':'370125','title':'山东省济南市济阳县'},
        {'code':'370126','title':'山东省济南市商河县'},
        {'code':'370181','title':'山东省济南市章丘市'},
        {'code':'370200','title':'山东省青岛市'},
        {'code':'370201','title':'山东省青岛市市辖区'},
        {'code':'370202','title':'山东省青岛市市南区'},
        {'code':'370203','title':'山东省青岛市市北区'},
        {'code':'370205','title':'山东省青岛市四方区'},
        {'code':'370211','title':'山东省青岛市黄岛区'},
        {'code':'370212','title':'山东省青岛市崂山区'},
        {'code':'370213','title':'山东省青岛市李沧区'},
        {'code':'370214','title':'山东省青岛市城阳区'},
        {'code':'370281','title':'山东省青岛市胶州市'},
        {'code':'370282','title':'山东省青岛市即墨市'},
        {'code':'370283','title':'山东省青岛市西度市'},
        {'code':'370284','title':'山东省青岛市胶南市'},
        {'code':'370285','title':'山东省青岛市莱西市'},
        {'code':'370300','title':'山东省淄博市'},
        {'code':'370301','title':'山东省淄博市市辖区'},
        {'code':'370302','title':'山东省淄博市淄川区'},
        {'code':'370303','title':'山东省淄博市张店区'},
        {'code':'370304','title':'山东省淄博市博山区'},
        {'code':'370305','title':'山东省淄博市临淄区'},
        {'code':'370306','title':'山东省淄博市周村区'},
        {'code':'370321','title':'山东省淄博市桓台县'},
        {'code':'370322','title':'山东省淄博市高青县'},
        {'code':'370323','title':'山东省淄博市沂源县'},
        {'code':'370400','title':'山东省枣庄市'},
        {'code':'370401','title':'山东省枣庄市市辖区'},
        {'code':'370402','title':'山东省枣庄市市中区'},
        {'code':'370403','title':'山东省枣庄市薛城区'},
        {'code':'370404','title':'山东省枣庄市峄城区'},
        {'code':'370405','title':'山东省枣庄市台儿庄区'},
        {'code':'370406','title':'山东省枣庄市山亭区'},
        {'code':'370481','title':'山东省枣庄市滕州市'},
        {'code':'370500','title':'山东省东营市'},
        {'code':'370501','title':'山东省东营市市辖区'},
        {'code':'370502','title':'山东省东营市东营区'},
        {'code':'370503','title':'山东省东营市河口区'},
        {'code':'370521','title':'山东省东营市垦利县'},
        {'code':'370522','title':'山东省东营市利津县'},
        {'code':'370523','title':'山东省东营市广饶县'},
        {'code':'370600','title':'山东省烟台市'},
        {'code':'370601','title':'山东省烟台市市辖区'},
        {'code':'370602','title':'山东省烟台市芝罘区'},
        {'code':'370611','title':'山东省烟台市福山区'},
        {'code':'370612','title':'山东省烟台市牟西区'},
        {'code':'370613','title':'山东省烟台市莱山区'},
        {'code':'370634','title':'山东省烟台市长岛县'},
        {'code':'370681','title':'山东省烟台市龙口市'},
        {'code':'370682','title':'山东省烟台市莱阳市'},
        {'code':'370683','title':'山东省烟台市莱州市'},
        {'code':'370684','title':'山东省烟台市蓬莱市'},
        {'code':'370685','title':'山东省烟台市招远市'},
        {'code':'370686','title':'山东省烟台市栖霞市'},
        {'code':'370687','title':'山东省烟台市海阳市'},
        {'code':'370700','title':'山东省潍坊市'},
        {'code':'370701','title':'山东省潍坊市市辖区'},
        {'code':'370702','title':'山东省潍坊市潍城区'},
        {'code':'370703','title':'山东省潍坊市寒亭区'},
        {'code':'370704','title':'山东省潍坊市坊子区'},
        {'code':'370705','title':'山东省潍坊市奎文区'},
        {'code':'370724','title':'山东省潍坊市临朐县'},
        {'code':'370725','title':'山东省潍坊市昌乐县'},
        {'code':'370781','title':'山东省潍坊市青州市'},
        {'code':'370782','title':'山东省潍坊市诸城市'},
        {'code':'370783','title':'山东省潍坊市寿光市'},
        {'code':'370784','title':'山东省潍坊市安丘市'},
        {'code':'370785','title':'山东省潍坊市高密市'},
        {'code':'370786','title':'山东省潍坊市昌邑市'},
        {'code':'370800','title':'山东省济宁市'},
        {'code':'370801','title':'山东省济宁市市辖区'},
        {'code':'370802','title':'山东省济宁市市中区'},
        {'code':'370811','title':'山东省济宁市任城区'},
        {'code':'370826','title':'山东省济宁市微山县'},
        {'code':'370827','title':'山东省济宁市鱼台县'},
        {'code':'370828','title':'山东省济宁市金乡县'},
        {'code':'370829','title':'山东省济宁市嘉祥县'},
        {'code':'370830','title':'山东省济宁市汶上县'},
        {'code':'370831','title':'山东省济宁市泗水县'},
        {'code':'370832','title':'山东省济宁市梁山县'},
        {'code':'370881','title':'山东省济宁市曲阜市'},
        {'code':'370882','title':'山东省济宁市兖州市'},
        {'code':'370883','title':'山东省济宁市邹城市'},
        {'code':'370900','title':'山东省泰安市'},
        {'code':'370901','title':'山东省泰安市市辖区'},
        {'code':'370902','title':'山东省泰安市泰山区'},
        {'code':'370911','title':'山东省泰安市郊区'},
        {'code':'370921','title':'山东省泰安市宁阳县'},
        {'code':'370923','title':'山东省泰安市东西县'},
        {'code':'370982','title':'山东省泰安市新泰市'},
        {'code':'370983','title':'山东省泰安市肥城市'},
        {'code':'371000','title':'山东省威海市'},
        {'code':'371001','title':'山东省威海市市辖区'},
        {'code':'371002','title':'山东省威海市环翠区'},
        {'code':'371081','title':'山东省威海市文登市'},
        {'code':'371082','title':'山东省威海市荣成市'},
        {'code':'371083','title':'山东省威海市乳山市'},
        {'code':'371100','title':'山东省日照市'},
        {'code':'371101','title':'山东省日照市市辖区'},
        {'code':'371102','title':'山东省日照市东港区'},
        {'code':'371121','title':'山东省日照市五莲县'},
        {'code':'371122','title':'山东省日照市莒县'},
        {'code':'371200','title':'山东省莱芜市'},
        {'code':'371201','title':'山东省莱芜市市辖区'},
        {'code':'371202','title':'山东省莱芜市莱城区'},
        {'code':'371203','title':'山东省莱芜市钢城区'},
        {'code':'371300','title':'山东省临沂市'},
        {'code':'371301','title':'山东省临沂市市辖区'},
        {'code':'371302','title':'山东省临沂市兰山区'},
        {'code':'371311','title':'山东省临沂市罗庄区'},
        {'code':'371312','title':'山东省临沂市河东区'},
        {'code':'371321','title':'山东省临沂市沂南县'},
        {'code':'371322','title':'山东省临沂市郯城县'},
        {'code':'371323','title':'山东省临沂市沂水县'},
        {'code':'371324','title':'山东省临沂市苍山县'},
        {'code':'371325','title':'山东省临沂市费县'},
        {'code':'371326','title':'山东省临沂市西邑县'},
        {'code':'371327','title':'山东省临沂市莒南县'},
        {'code':'371328','title':'山东省临沂市蒙阴县'},
        {'code':'371329','title':'山东省临沂市临沭县'},
        {'code':'371400','title':'山东省德州市'},
        {'code':'371401','title':'山东省德州市市辖区'},
        {'code':'371402','title':'山东省德州市德城区'},
        {'code':'371421','title':'山东省德州市陵县'},
        {'code':'371422','title':'山东省德州市宁津县'},
        {'code':'371423','title':'山东省德州市庆云县'},
        {'code':'371424','title':'山东省德州市临邑县'},
        {'code':'371425','title':'山东省德州市齐河县'},
        {'code':'371426','title':'山东省德州市西原县'},
        {'code':'371427','title':'山东省德州市夏津县'},
        {'code':'371428','title':'山东省德州市武城县'},
        {'code':'371481','title':'山东省德州市乐陵市'},
        {'code':'371482','title':'山东省德州市禹城市'},
        {'code':'371500','title':'山东省聊城市'},
        {'code':'371501','title':'山东省聊城市市辖区'},
        {'code':'371502','title':'山东省聊城市东昌府区'},
        {'code':'371521','title':'山东省聊城市阳谷县'},
        {'code':'371522','title':'山东省聊城市莘县'},
        {'code':'371523','title':'山东省聊城市茌西县'},
        {'code':'371524','title':'山东省聊城市东阿县'},
        {'code':'371525','title':'山东省聊城市冠县'},
        {'code':'371526','title':'山东省聊城市高唐县'},
        {'code':'371581','title':'山东省聊城市临清市'},
        {'code':'372300','title':'山东省滨州地区'},
        {'code':'372301','title':'山东省滨州地区滨州市'},
        {'code':'372321','title':'山东省滨州地区惠民县'},
        {'code':'372323','title':'山东省滨州地区阳信县'},
        {'code':'372324','title':'山东省滨州地区无棣县'},
        {'code':'372325','title':'山东省滨州地区沾化县'},
        {'code':'372328','title':'山东省滨州地区博兴县'},
        {'code':'372330','title':'山东省滨州地区邹西县'},
        {'code':'372900','title':'山东省菏泽地区'},
        {'code':'372901','title':'山东省菏泽地区菏泽市'},
        {'code':'372922','title':'山东省菏泽地区曹县'},
        {'code':'372923','title':'山东省菏泽地区定陶县'},
        {'code':'372924','title':'山东省菏泽地区成武县'},
        {'code':'372925','title':'山东省菏泽地区单县'},
        {'code':'372926','title':'山东省菏泽地区巨野县'},
        {'code':'372928','title':'山东省菏泽地区郓城县'},
        {'code':'372929','title':'山东省菏泽地区鄄城县'},
        {'code':'372930','title':'山东省菏泽地区东明县'},
        {'code':'410000','title':'河南省'},
        {'code':'410100','title':'河南省郑州市'},
        {'code':'410101','title':'河南省郑州市市辖区'},
        {'code':'410102','title':'河南省郑州市中原区'},
        {'code':'410103','title':'河南省郑州市二七区'},
        {'code':'410104','title':'河南省郑州市管城回族区'},
        {'code':'410105','title':'河南省郑州市金水区'},
        {'code':'410106','title':'河南省郑州市上街区'},
        {'code':'410108','title':'河南省郑州市邙山区'},
        {'code':'410122','title':'河南省郑州市中牟县'},
        {'code':'410181','title':'河南省郑州市巩义市'},
        {'code':'410182','title':'河南省郑州市荥阳市'},
        {'code':'410183','title':'河南省郑州市新密市'},
        {'code':'410184','title':'河南省郑州市新郑市'},
        {'code':'410185','title':'河南省郑州市登封市'},
        {'code':'410200','title':'河南省开封市'},
        {'code':'410201','title':'河南省开封市市辖区'},
        {'code':'410202','title':'河南省开封市龙亭区'},
        {'code':'410203','title':'河南省开封市顺河回族区'},
        {'code':'410204','title':'河南省开封市鼓楼区'},
        {'code':'410205','title':'河南省开封市南关区'},
        {'code':'410211','title':'河南省开封市郊区'},
        {'code':'410221','title':'河南省开封市杞县'},
        {'code':'410222','title':'河南省开封市通许县'},
        {'code':'410223','title':'河南省开封市尉氏县'},
        {'code':'410224','title':'河南省开封市开封县'},
        {'code':'410225','title':'河南省开封市兰考县'},
        {'code':'410300','title':'河南省洛阳市'},
        {'code':'410301','title':'河南省洛阳市市辖区'},
        {'code':'410302','title':'河南省洛阳市老城区'},
        {'code':'410303','title':'河南省洛阳市西工区'},
        {'code':'410304','title':'河南省洛阳市廛河回族区'},
        {'code':'410305','title':'河南省洛阳市涧西区'},
        {'code':'410306','title':'河南省洛阳市吉利区'},
        {'code':'410311','title':'河南省洛阳市郊区'},
        {'code':'410322','title':'河南省洛阳市孟津县'},
        {'code':'410323','title':'河南省洛阳市新安县'},
        {'code':'410324','title':'河南省洛阳市栾川县'},
        {'code':'410325','title':'河南省洛阳市嵩县'},
        {'code':'410326','title':'河南省洛阳市汝阳县'},
        {'code':'410327','title':'河南省洛阳市宜阳县'},
        {'code':'410328','title':'河南省洛阳市洛宁县'},
        {'code':'410329','title':'河南省洛阳市伊川县'},
        {'code':'410381','title':'河南省洛阳市偃师市'},
        {'code':'410400','title':'河南省西顶山市'},
        {'code':'410401','title':'河南省西顶山市市辖区'},
        {'code':'410402','title':'河南省西顶山市新华区'},
        {'code':'410403','title':'河南省西顶山市卫东区'},
        {'code':'410404','title':'河南省西顶山市石龙区'},
        {'code':'410411','title':'河南省西顶山市湛河区'},
        {'code':'410421','title':'河南省西顶山市宝丰县'},
        {'code':'410422','title':'河南省西顶山市叶县'},
        {'code':'410423','title':'河南省西顶山市鲁山县'},
        {'code':'410425','title':'河南省西顶山市郏县'},
        {'code':'410481','title':'河南省西顶山市舞钢市'},
        {'code':'410482','title':'河南省西顶山市汝州市'},
        {'code':'410500','title':'河南省安阳市'},
        {'code':'410501','title':'河南省安阳市市辖区'},
        {'code':'410502','title':'河南省安阳市文峰区'},
        {'code':'410503','title':'河南省安阳市北关区'},
        {'code':'410504','title':'河南省安阳市铁西区'},
        {'code':'410511','title':'河南省安阳市郊区'},
        {'code':'410522','title':'河南省安阳市安阳县'},
        {'code':'410523','title':'河南省安阳市汤阴县'},
        {'code':'410526','title':'河南省安阳市滑县'},
        {'code':'410527','title':'河南省安阳市内黄县'},
        {'code':'410581','title':'河南省安阳市林州市'},
        {'code':'410600','title':'河南省鹤壁市'},
        {'code':'410601','title':'河南省鹤壁市市辖区'},
        {'code':'410602','title':'河南省鹤壁市鹤山区'},
        {'code':'410603','title':'河南省鹤壁市山城区'},
        {'code':'410611','title':'河南省鹤壁市郊区'},
        {'code':'410621','title':'河南省鹤壁市浚县'},
        {'code':'410622','title':'河南省鹤壁市淇县'},
        {'code':'410700','title':'河南省新乡市'},
        {'code':'410701','title':'河南省新乡市市辖区'},
        {'code':'410702','title':'河南省新乡市红旗区'},
        {'code':'410703','title':'河南省新乡市新华区'},
        {'code':'410704','title':'河南省新乡市北站区'},
        {'code':'410711','title':'河南省新乡市郊区'},
        {'code':'410721','title':'河南省新乡市新乡县'},
        {'code':'410724','title':'河南省新乡市获嘉县'},
        {'code':'410725','title':'河南省新乡市原阳县'},
        {'code':'410726','title':'河南省新乡市延津县'},
        {'code':'410727','title':'河南省新乡市封丘县'},
        {'code':'410728','title':'河南省新乡市长垣县'},
        {'code':'410781','title':'河南省新乡市卫辉市'},
        {'code':'410782','title':'河南省新乡市辉县市'},
        {'code':'410800','title':'河南省焦作市'},
        {'code':'410801','title':'河南省焦作市市辖区'},
        {'code':'410802','title':'河南省焦作市解放区'},
        {'code':'410803','title':'河南省焦作市中站区'},
        {'code':'410804','title':'河南省焦作市马村区'},
        {'code':'410811','title':'河南省焦作市山阳区'},
        {'code':'410821','title':'河南省焦作市修武县'},
        {'code':'410822','title':'河南省焦作市博爱县'},
        {'code':'410823','title':'河南省焦作市武陟县'},
        {'code':'410825','title':'河南省焦作市温县'},
        {'code':'410881','title':'河南省焦作市济源市'},
        {'code':'410882','title':'河南省焦作市沁阳市'},
        {'code':'410883','title':'河南省焦作市孟州市'},
        {'code':'410900','title':'河南省濮阳市'},
        {'code':'410901','title':'河南省濮阳市市辖区'},
        {'code':'410902','title':'河南省濮阳市市区'},
        {'code':'410922','title':'河南省濮阳市清丰县'},
        {'code':'410923','title':'河南省濮阳市南乐县'},
        {'code':'410926','title':'河南省濮阳市范县'},
        {'code':'410927','title':'河南省濮阳市台前县'},
        {'code':'410928','title':'河南省濮阳市濮阳县'},
        {'code':'411000','title':'河南省许昌市'},
        {'code':'411001','title':'河南省许昌市市辖区'},
        {'code':'411002','title':'河南省许昌市魏都区'},
        {'code':'411023','title':'河南省许昌市许昌县'},
        {'code':'411024','title':'河南省许昌市鄢陵县'},
        {'code':'411025','title':'河南省许昌市襄城县'},
        {'code':'411081','title':'河南省许昌市禹州市'},
        {'code':'411082','title':'河南省许昌市长葛市'},
        {'code':'411100','title':'河南省漯河市'},
        {'code':'411101','title':'河南省漯河市市辖区'},
        {'code':'411102','title':'河南省漯河市源汇区'},
        {'code':'411121','title':'河南省漯河市舞阳县'},
        {'code':'411122','title':'河南省漯河市临颍县'},
        {'code':'411123','title':'河南省漯河市郾城县'},
        {'code':'411200','title':'河南省三门峡市'},
        {'code':'411201','title':'河南省三门峡市市辖区'},
        {'code':'411202','title':'河南省三门峡市湖滨区'},
        {'code':'411221','title':'河南省三门峡市渑池县'},
        {'code':'411222','title':'河南省三门峡市陕县'},
        {'code':'411224','title':'河南省三门峡市卢氏县'},
        {'code':'411281','title':'河南省三门峡市义马市'},
        {'code':'411282','title':'河南省三门峡市灵宝市'},
        {'code':'411300','title':'河南省南阳市'},
        {'code':'411301','title':'河南省南阳市市辖区'},
        {'code':'411302','title':'河南省南阳市宛城区'},
        {'code':'411303','title':'河南省南阳市卧龙区'},
        {'code':'411321','title':'河南省南阳市南召县'},
        {'code':'411322','title':'河南省南阳市方城县'},
        {'code':'411323','title':'河南省南阳市西峡县'},
        {'code':'411324','title':'河南省南阳市镇西县'},
        {'code':'411325','title':'河南省南阳市内乡县'},
        {'code':'411326','title':'河南省南阳市淅川县'},
        {'code':'411327','title':'河南省南阳市社旗县'},
        {'code':'411328','title':'河南省南阳市唐河县'},
        {'code':'411329','title':'河南省南阳市新野县'},
        {'code':'411330','title':'河南省南阳市桐柏县'},
        {'code':'411381','title':'河南省南阳市邓州市'},
        {'code':'411400','title':'河南省商丘市'},
        {'code':'411401','title':'河南省商丘市市辖区'},
        {'code':'411402','title':'河南省商丘市梁园区'},
        {'code':'411403','title':'河南省商丘市睢阳区'},
        {'code':'411421','title':'河南省商丘市民权县'},
        {'code':'411422','title':'河南省商丘市睢县'},
        {'code':'411423','title':'河南省商丘市宁陵县'},
        {'code':'411424','title':'河南省商丘市柘城县'},
        {'code':'411425','title':'河南省商丘市虞城县'},
        {'code':'411426','title':'河南省商丘市夏邑县'},
        {'code':'411481','title':'河南省商丘市永城市'},
        {'code':'411500','title':'河南省信阳市'},
        {'code':'411501','title':'河南省信阳市市辖区'},
        {'code':'411502','title':'河南省信阳市师河区'},
        {'code':'411503','title':'河南省信阳市西桥区'},
        {'code':'411521','title':'河南省信阳市罗山县'},
        {'code':'411522','title':'河南省信阳市光山县'},
        {'code':'411523','title':'河南省信阳市新县'},
        {'code':'411524','title':'河南省信阳市商城县'},
        {'code':'411525','title':'河南省信阳市固始县'},
        {'code':'411526','title':'河南省信阳市潢川县'},
        {'code':'411527','title':'河南省信阳市淮滨县'},
        {'code':'411528','title':'河南省信阳市息县'},
        {'code':'412700','title':'河南省周口地区'},
        {'code':'412701','title':'河南省周口地区周口市'},
        {'code':'412702','title':'河南省周口地区项城市'},
        {'code':'412721','title':'河南省周口地区扶沟县'},
        {'code':'412722','title':'河南省周口地区西华县'},
        {'code':'412723','title':'河南省周口地区商水县'},
        {'code':'412724','title':'河南省周口地区太康县'},
        {'code':'412725','title':'河南省周口地区鹿邑县'},
        {'code':'412726','title':'河南省周口地区郸城县'},
        {'code':'412727','title':'河南省周口地区淮阳县'},
        {'code':'412728','title':'河南省周口地区沈丘县'},
        {'code':'412800','title':'河南省驻马店地区'},
        {'code':'412801','title':'河南省驻马店地区驻马店市'},
        {'code':'412821','title':'河南省驻马店地区确山县'},
        {'code':'412822','title':'河南省驻马店地区泌阳县'},
        {'code':'412823','title':'河南省驻马店地区遂西县'},
        {'code':'412824','title':'河南省驻马店地区西西县'},
        {'code':'412825','title':'河南省驻马店地区上蔡县'},
        {'code':'412826','title':'河南省驻马店地区汝南县'},
        {'code':'412827','title':'河南省驻马店地区西舆县'},
        {'code':'412828','title':'河南省驻马店地区新蔡县'},
        {'code':'412829','title':'河南省驻马店地区正阳县'},
        {'code':'420000','title':'湖北省'},
        {'code':'420100','title':'湖北省武汉市'},
        {'code':'420101','title':'湖北省武汉市市辖区'},
        {'code':'420102','title':'湖北省武汉市江岸区'},
        {'code':'420103','title':'湖北省武汉市江汉区'},
        {'code':'420104','title':'湖北省武汉市乔口区'},
        {'code':'420105','title':'湖北省武汉市汉阳区'},
        {'code':'420106','title':'湖北省武汉市武昌区'},
        {'code':'420107','title':'湖北省武汉市青山区'},
        {'code':'420111','title':'湖北省武汉市洪山区'},
        {'code':'420112','title':'湖北省武汉市东西湖区'},
        {'code':'420113','title':'湖北省武汉市汉南区'},
        {'code':'420114','title':'湖北省武汉市蔡甸区'},
        {'code':'420115','title':'湖北省武汉市江夏区'},
        {'code':'420116','title':'湖北省武汉市黄陂区'},
        {'code':'420117','title':'湖北省武汉市新洲区'},
        {'code':'420200','title':'湖北省黄石市'},
        {'code':'420201','title':'湖北省黄石市市辖区'},
        {'code':'420202','title':'湖北省黄石市黄石港区'},
        {'code':'420203','title':'湖北省黄石市石灰窑区'},
        {'code':'420204','title':'湖北省黄石市下陆区'},
        {'code':'420205','title':'湖北省黄石市铁山区'},
        {'code':'420222','title':'湖北省黄石市阳新县'},
        {'code':'420281','title':'湖北省黄石市大冶市'},
        {'code':'420300','title':'湖北省十堰市'},
        {'code':'420301','title':'湖北省十堰市市辖区'},
        {'code':'420302','title':'湖北省十堰市茅箭区'},
        {'code':'420303','title':'湖北省十堰市张湾区'},
        {'code':'420321','title':'湖北省十堰市郧县'},
        {'code':'420322','title':'湖北省十堰市郧西县'},
        {'code':'420323','title':'湖北省十堰市竹山县'},
        {'code':'420324','title':'湖北省十堰市竹溪县'},
        {'code':'420325','title':'湖北省十堰市房县'},
        {'code':'420381','title':'湖北省十堰市丹江口市'},
        {'code':'420500','title':'湖北省宜昌市'},
        {'code':'420501','title':'湖北省宜昌市市辖区'},
        {'code':'420502','title':'湖北省宜昌市西陵区'},
        {'code':'420503','title':'湖北省宜昌市伍家岗区'},
        {'code':'420504','title':'湖北省宜昌市点军区'},
        {'code':'420505','title':'湖北省宜昌市虎亭区'},
        {'code':'420521','title':'湖北省宜昌市宜昌县'},
        {'code':'420525','title':'湖北省宜昌市远安县'},
        {'code':'420526','title':'湖北省宜昌市兴山县'},
        {'code':'420527','title':'湖北省宜昌市秭归县'},
        {'code':'420528','title':'湖北省宜昌市长阳土家族自治县'},
        {'code':'420529','title':'湖北省宜昌市五峰土家族自治县'},
        {'code':'420581','title':'湖北省宜昌市宜都市'},
        {'code':'420582','title':'湖北省宜昌市当阳市'},
        {'code':'420583','title':'湖北省宜昌市枝江市'},
        {'code':'420600','title':'湖北省襄樊市'},
        {'code':'420601','title':'湖北省襄樊市市辖区'},
        {'code':'420602','title':'湖北省襄樊市襄城区'},
        {'code':'420606','title':'湖北省襄樊市樊城区'},
        {'code':'420621','title':'湖北省襄樊市襄阳县'},
        {'code':'420624','title':'湖北省襄樊市南漳县'},
        {'code':'420625','title':'湖北省襄樊市谷城县'},
        {'code':'420626','title':'湖北省襄樊市保康县'},
        {'code':'420682','title':'湖北省襄樊市老河口市'},
        {'code':'420683','title':'湖北省襄樊市枣阳市'},
        {'code':'420684','title':'湖北省襄樊市宜城市'},
        {'code':'420700','title':'湖北省鄂州市'},
        {'code':'420701','title':'湖北省鄂州市市辖区'},
        {'code':'420702','title':'湖北省鄂州市梁子湖区'},
        {'code':'420703','title':'湖北省鄂州市华容区'},
        {'code':'420704','title':'湖北省鄂州市鄂城区'},
        {'code':'420800','title':'湖北省荆门市'},
        {'code':'420801','title':'湖北省荆门市市辖区'},
        {'code':'420802','title':'湖北省荆门市东宝区'},
        {'code':'420821','title':'湖北省荆门市京山县'},
        {'code':'420822','title':'湖北省荆门市沙洋县'},
        {'code':'420881','title':'湖北省荆门市钟祥市'},
        {'code':'420900','title':'湖北省孝感市'},
        {'code':'420901','title':'湖北省孝感市市辖区'},
        {'code':'420902','title':'湖北省孝感市孝南区'},
        {'code':'420921','title':'湖北省孝感市孝昌县'},
        {'code':'420922','title':'湖北省孝感市大悟县'},
        {'code':'420923','title':'湖北省孝感市云梦县'},
        {'code':'420981','title':'湖北省孝感市应城市'},
        {'code':'420982','title':'湖北省孝感市安陆市'},
        {'code':'420983','title':'湖北省孝感市广水市'},
        {'code':'420984','title':'湖北省孝感市汉川市'},
        {'code':'421000','title':'湖北省荆州市'},
        {'code':'421001','title':'湖北省荆州市市辖区'},
        {'code':'421002','title':'湖北省荆州市沙市区'},
        {'code':'421003','title':'湖北省荆州市荆州区'},
        {'code':'421022','title':'湖北省荆州市公安县'},
        {'code':'421023','title':'湖北省荆州市监利县'},
        {'code':'421024','title':'湖北省荆州市江陵县'},
        {'code':'421081','title':'湖北省荆州市石首市'},
        {'code':'421083','title':'湖北省荆州市洪湖市'},
        {'code':'421087','title':'湖北省荆州市松滋市'},
        {'code':'421100','title':'湖北省黄冈市'},
        {'code':'421101','title':'湖北省黄冈市市辖区'},
        {'code':'421102','title':'湖北省黄冈市黄州区'},
        {'code':'421121','title':'湖北省黄冈市团风县'},
        {'code':'421122','title':'湖北省黄冈市红安县'},
        {'code':'421123','title':'湖北省黄冈市罗田县'},
        {'code':'421124','title':'湖北省黄冈市英山县'},
        {'code':'421125','title':'湖北省黄冈市浠水县'},
        {'code':'421126','title':'湖北省黄冈市蕲春县'},
        {'code':'421127','title':'湖北省黄冈市黄梅县'},
        {'code':'421181','title':'湖北省黄冈市麻城市'},
        {'code':'421182','title':'湖北省黄冈市武穴市'},
        {'code':'421200','title':'湖北省咸宁市'},
        {'code':'421201','title':'湖北省咸宁市市辖区'},
        {'code':'421202','title':'湖北省咸宁市咸安区'},
        {'code':'421221','title':'湖北省咸宁市嘉鱼县'},
        {'code':'421222','title':'湖北省咸宁市通城县'},
        {'code':'421223','title':'湖北省咸宁市崇阳县'},
        {'code':'421224','title':'湖北省咸宁市通山县'},
        {'code':'422800','title':'湖北省施土家族苗族自治州'},
        {'code':'422801','title':'湖北省恩施土家族苗族自治州恩施县'},
        {'code':'422802','title':'湖北省恩施土家族苗族自治州利川市'},
        {'code':'422822','title':'湖北省恩施土家族苗族自治州建始县'},
        {'code':'422823','title':'湖北省恩施土家族苗族自治州巴东县'},
        {'code':'422825','title':'湖北省恩施土家族苗族自治州宣恩县'},
        {'code':'422826','title':'湖北省恩施土家族苗族自治州咸丰县'},
        {'code':'422827','title':'湖北省恩施土家族苗族自治州来凤县'},
        {'code':'422828','title':'湖北省恩施土家族苗族自治州鹤峰县'},
        {'code':'429000','title':'湖北省省直辖县级行政单位'},
        {'code':'429001','title':'湖北省随州市'},
        {'code':'429004','title':'湖北省仙桃市'},
        {'code':'429005','title':'湖北省潜江市'},
        {'code':'429006','title':'湖北省天门市'},
        {'code':'429021','title':'湖北省神农架林区'},
        {'code':'430000','title':'湖南省'},
        {'code':'430100','title':'湖南省长沙市'},
        {'code':'430101','title':'湖南省长沙市市辖区'},
        {'code':'430102','title':'湖南省长沙市芙蓉区'},
        {'code':'430103','title':'湖南省长沙市天心区'},
        {'code':'430104','title':'湖南省长沙市岳麓区'},
        {'code':'430105','title':'湖南省长沙市开福区'},
        {'code':'430111','title':'湖南省长沙市雨花区'},
        {'code':'430121','title':'湖南省长沙市长沙县'},
        {'code':'430122','title':'湖南省长沙市望城县'},
        {'code':'430124','title':'湖南省长沙市宁乡县'},
        {'code':'430181','title':'湖南省长沙市浏阳市'},
        {'code':'430200','title':'湖南省株洲市'},
        {'code':'430201','title':'湖南省株洲市市辖区'},
        {'code':'430202','title':'湖南省株洲市荷塘区'},
        {'code':'430203','title':'湖南省株洲市芦淞区'},
        {'code':'430204','title':'湖南省株洲市石峰区'},
        {'code':'430211','title':'湖南省株洲市天元区'},
        {'code':'430221','title':'湖南省株洲市株洲县'},
        {'code':'430223','title':'湖南省株洲市攸县'},
        {'code':'430224','title':'湖南省株洲市茶陵县'},
        {'code':'430225','title':'湖南省株洲市炎陵县'},
        {'code':'430281','title':'湖南省株洲市醴陵市'},
        {'code':'430300','title':'湖南省湘潭市'},
        {'code':'430301','title':'湖南省湘潭市市辖区'},
        {'code':'430302','title':'湖南省湘潭市雨湖区'},
        {'code':'430304','title':'湖南省湘潭市岳塘区'},
        {'code':'430321','title':'湖南省湘潭市湘潭县'},
        {'code':'430381','title':'湖南省湘潭市湘乡市'},
        {'code':'430382','title':'湖南省湘潭市韶山市'},
        {'code':'430400','title':'湖南省衡阳市'},
        {'code':'430401','title':'湖南省衡阳市市辖区'},
        {'code':'430402','title':'湖南省衡阳市江东区'},
        {'code':'430403','title':'湖南省衡阳市城南区'},
        {'code':'430404','title':'湖南省衡阳市城北区'},
        {'code':'430411','title':'湖南省衡阳市郊区'},
        {'code':'430412','title':'湖南省衡阳市南岳区'},
        {'code':'430421','title':'湖南省衡阳市衡阳县'},
        {'code':'430422','title':'湖南省衡阳市衡南县'},
        {'code':'430423','title':'湖南省衡阳市衡山县'},
        {'code':'430424','title':'湖南省衡阳市衡东县'},
        {'code':'430426','title':'湖南省衡阳市祁东县'},
        {'code':'430481','title':'湖南省衡阳市耒阳市'},
        {'code':'430482','title':'湖南省衡阳市常宁市'},
        {'code':'430500','title':'湖南省邵阳市'},
        {'code':'430501','title':'湖南省邵阳市市辖区'},
        {'code':'430502','title':'湖南省邵阳市双清区'},
        {'code':'430503','title':'湖南省邵阳市大祥区'},
        {'code':'430511','title':'湖南省邵阳市北塔区'},
        {'code':'430521','title':'湖南省邵阳市邵东县'},
        {'code':'430522','title':'湖南省邵阳市新邵县'},
        {'code':'430523','title':'湖南省邵阳市邵阳县'},
        {'code':'430524','title':'湖南省邵阳市隆回县'},
        {'code':'430525','title':'湖南省邵阳市洞口县'},
        {'code':'430527','title':'湖南省邵阳市绥宁县'},
        {'code':'430528','title':'湖南省邵阳市新宁县'},
        {'code':'430529','title':'湖南省邵阳市城步苗族自治县'},
        {'code':'430581','title':'湖南省邵阳市武冈市'},
        {'code':'430600','title':'湖南省岳阳市'},
        {'code':'430601','title':'湖南省岳阳市市辖区'},
        {'code':'430602','title':'湖南省岳阳市岳阳楼区'},
        {'code':'430603','title':'湖南省岳阳市云溪区'},
        {'code':'430611','title':'湖南省岳阳市君山区'},
        {'code':'430621','title':'湖南省岳阳市岳阳县'},
        {'code':'430623','title':'湖南省岳阳市华容县'},
        {'code':'430624','title':'湖南省岳阳市湘阴县'},
        {'code':'430626','title':'湖南省岳阳市西江县'},
        {'code':'430681','title':'湖南省岳阳市汨罗市'},
        {'code':'430682','title':'湖南省岳阳市临湘市'},
        {'code':'430700','title':'湖南省常德市'},
        {'code':'430701','title':'湖南省常德市市辖区'},
        {'code':'430702','title':'湖南省常德市武陵区'},
        {'code':'430703','title':'湖南省常德市鼎城区'},
        {'code':'430721','title':'湖南省常德市安乡县'},
        {'code':'430722','title':'湖南省常德市汉寿县'},
        {'code':'430723','title':'湖南省常德市澧县'},
        {'code':'430724','title':'湖南省常德市临澧县'},
        {'code':'430725','title':'湖南省常德市桃源县'},
        {'code':'430726','title':'湖南省常德市石门县'},
        {'code':'430781','title':'湖南省常德市津市市'},
        {'code':'430800','title':'湖南省张家界市'},
        {'code':'430801','title':'湖南省张家界市市辖区'},
        {'code':'430802','title':'湖南省张家界市永定区'},
        {'code':'430811','title':'湖南省张家界市武陵源区'},
        {'code':'430821','title':'湖南省张家界市慈利县'},
        {'code':'430822','title':'湖南省张家界市桑植县'},
        {'code':'430900','title':'湖南省益阳市'},
        {'code':'430901','title':'湖南省益阳市市辖区'},
        {'code':'430902','title':'湖南省益阳市资阳区'},
        {'code':'430903','title':'湖南省益阳市赫山区'},
        {'code':'430921','title':'湖南省益阳市南县'},
        {'code':'430922','title':'湖南省益阳市桃江县'},
        {'code':'430923','title':'湖南省益阳市安化县'},
        {'code':'430981','title':'湖南省益阳市沅江市'},
        {'code':'431000','title':'湖南省郴州市'},
        {'code':'431001','title':'湖南省郴州市市辖区'},
        {'code':'431002','title':'湖南省郴州市北湖区'},
        {'code':'431003','title':'湖南省郴州市苏仙区'},
        {'code':'431021','title':'湖南省郴州市桂阳县'},
        {'code':'431022','title':'湖南省郴州市宜章县'},
        {'code':'431023','title':'湖南省郴州市永兴县'},
        {'code':'431024','title':'湖南省郴州市嘉禾县'},
        {'code':'431025','title':'湖南省郴州市临武县'},
        {'code':'431026','title':'湖南省郴州市汝城县'},
        {'code':'431027','title':'湖南省郴州市桂东县'},
        {'code':'431028','title':'湖南省郴州市安仁县'},
        {'code':'431081','title':'湖南省郴州市资兴市'},
        {'code':'431100','title':'湖南省永州市'},
        {'code':'431101','title':'湖南省永州市市辖区'},
        {'code':'431102','title':'湖南省永州市芝山区'},
        {'code':'431103','title':'湖南省永州市冷水滩区'},
        {'code':'431121','title':'湖南省永州市祁阳县'},
        {'code':'431122','title':'湖南省永州市东安县'},
        {'code':'431123','title':'湖南省永州市双牌县'},
        {'code':'431124','title':'湖南省永州市道县'},
        {'code':'431125','title':'湖南省永州市江永县'},
        {'code':'431126','title':'湖南省永州市宁远县'},
        {'code':'431127','title':'湖南省永州市蓝山县'},
        {'code':'431128','title':'湖南省永州市新田县'},
        {'code':'431129','title':'湖南省永州市江华瑶族自治县'},
        {'code':'431200','title':'湖南省怀化市'},
        {'code':'431201','title':'湖南省怀化市市辖区'},
        {'code':'431202','title':'湖南省怀化市鹤城区'},
        {'code':'431221','title':'湖南省怀化市中方县'},
        {'code':'431222','title':'湖南省怀化市沅陵县'},
        {'code':'431223','title':'湖南省怀化市辰溪县'},
        {'code':'431224','title':'湖南省怀化市溆浦县'},
        {'code':'431225','title':'湖南省怀化市会同县'},
        {'code':'431226','title':'湖南省怀化市麻阳苗族自治县'},
        {'code':'431227','title':'湖南省怀化市新晃侗族自治县'},
        {'code':'431228','title':'湖南省怀化市芷江侗族自治县'},
        {'code':'431229','title':'湖南省怀化市靖州苗族侗族自治县'},
        {'code':'431230','title':'湖南省怀化市通道侗族自治县'},
        {'code':'431281','title':'湖南省怀化市洪江市'},
        {'code':'432500','title':'湖南省娄底地区'},
        {'code':'432501','title':'湖南省娄底地区娄底市'},
        {'code':'432502','title':'湖南省娄底地区冷水江市'},
        {'code':'432503','title':'湖南省娄底地区涟源市'},
        {'code':'432522','title':'湖南省娄底地区双峰县'},
        {'code':'432524','title':'湖南省娄底地区新化县'},
        {'code':'433000','title':'湖南省怀化市'},
        {'code':'433001','title':'湖南省怀化市'},
        {'code':'433100','title':'湖南省湘西土家族苗族自治州'},
        {'code':'433101','title':'湖南省湘西土家族苗族自治州吉首市'},
        {'code':'433122','title':'湖南省湘西土家族苗族自治州泸溪县'},
        {'code':'433123','title':'湖南省湘西土家族苗族自治州凤凰县'},
        {'code':'433124','title':'湖南省湘西土家族苗族自治州花垣县'},
        {'code':'433125','title':'湖南省湘西土家族苗族自治州保靖县'},
        {'code':'433126','title':'湖南省湘西土家族苗族自治州古丈县'},
        {'code':'433127','title':'湖南省湘西土家族苗族自治州永顺县'},
        {'code':'433130','title':'湖南省湘西土家族苗族自治州龙山县'},
        {'code':'440000','title':'广东省'},
        {'code':'440100','title':'广东省广州市'},
        {'code':'440101','title':'广东省广州市市辖区'},
        {'code':'440102','title':'广东省广州市东山区'},
        {'code':'440103','title':'广东省广州市荔湾区'},
        {'code':'440104','title':'广东省广州市越秀区'},
        {'code':'440105','title':'广东省广州市海珠区'},
        {'code':'440106','title':'广东省广州市天河区'},
        {'code':'440107','title':'广东省广州市芳村区'},
        {'code':'440111','title':'广东省广州市白云区'},
        {'code':'440112','title':'广东省广州市黄埔区'},
        {'code':'440181','title':'广东省广州市番禺市'},
        {'code':'440182','title':'广东省广州市花都市'},
        {'code':'440183','title':'广东省广州市增城市'},
        {'code':'440184','title':'广东省广州市从化市'},
        {'code':'440200','title':'广东省韶关市'},
        {'code':'440201','title':'广东省韶关市市辖区'},
        {'code':'440202','title':'广东省韶关市北江区'},
        {'code':'440203','title':'广东省韶关市武江区'},
        {'code':'440204','title':'广东省韶关市浈江区'},
        {'code':'440221','title':'广东省韶关市曲江县'},
        {'code':'440222','title':'广东省韶关市始兴县'},
        {'code':'440224','title':'广东省韶关市仁化县'},
        {'code':'440229','title':'广东省韶关市翁源县'},
        {'code':'440232','title':'广东省韶关市乳源瑶族自治县'},
        {'code':'440233','title':'广东省韶关市新丰县'},
        {'code':'440281','title':'广东省韶关市乐昌市'},
        {'code':'440282','title':'广东省韶关市南雄市'},
        {'code':'440300','title':'广东省深圳市'},
        {'code':'440301','title':'广东省深圳市市辖区'},
        {'code':'440303','title':'广东省深圳市罗湖区'},
        {'code':'440304','title':'广东省深圳市福田区'},
        {'code':'440305','title':'广东省深圳市南山区'},
        {'code':'440306','title':'广东省深圳市宝安区'},
        {'code':'440307','title':'广东省深圳市龙岗区'},
        {'code':'440308','title':'广东省深圳市盐田区'},
        {'code':'440400','title':'广东省珠海市'},
        {'code':'440401','title':'广东省珠海市市辖区'},
        {'code':'440402','title':'广东省珠海市香洲区'},
        {'code':'440421','title':'广东省珠海市斗门县'},
        {'code':'440500','title':'广东省汕头市'},
        {'code':'440501','title':'广东省汕头市市辖区'},
        {'code':'440506','title':'广东省汕头市达濠区'},
        {'code':'440507','title':'广东省汕头市龙湖区'},
        {'code':'440508','title':'广东省汕头市金园区'},
        {'code':'440509','title':'广东省汕头市升西区'},
        {'code':'440510','title':'广东省汕头市河浦区'},
        {'code':'440523','title':'广东省汕头市南澳县'},
        {'code':'440582','title':'广东省汕头市潮阳市'},
        {'code':'440583','title':'广东省汕头市澄海市'},
        {'code':'440600','title':'广东省佛山市'},
        {'code':'440601','title':'广东省佛山市市辖区'},
        {'code':'440602','title':'广东省佛山市城区'},
        {'code':'440603','title':'广东省佛山市石湾区'},
        {'code':'440681','title':'广东省佛山市顺德市'},
        {'code':'440682','title':'广东省佛山市南海市'},
        {'code':'440683','title':'广东省佛山市三水市'},
        {'code':'440684','title':'广东省佛山市高明市'},
        {'code':'440700','title':'广东省江门市'},
        {'code':'440701','title':'广东省江门市市辖区'},
        {'code':'440703','title':'广东省江门市蓬江区'},
        {'code':'440704','title':'广东省江门市江海区'},
        {'code':'440781','title':'广东省江门市台山市'},
        {'code':'440782','title':'广东省江门市新会市'},
        {'code':'440783','title':'广东省江门市开西市'},
        {'code':'440784','title':'广东省江门市鹤山市'},
        {'code':'440785','title':'广东省江门市恩西市'},
        {'code':'440800','title':'广东省湛江市'},
        {'code':'440801','title':'广东省湛江市市辖区'},
        {'code':'440802','title':'广东省湛江市赤坎区'},
        {'code':'440803','title':'广东省湛江市霞山区'},
        {'code':'440804','title':'广东省湛江市坡头区'},
        {'code':'440811','title':'广东省湛江市麻章区'},
        {'code':'440823','title':'广东省湛江市遂溪县'},
        {'code':'440825','title':'广东省湛江市徐闻县'},
        {'code':'440881','title':'广东省湛江市廉江市'},
        {'code':'440882','title':'广东省湛江市雷州市'},
        {'code':'440883','title':'广东省湛江市吴川市'},
        {'code':'440900','title':'广东省茂名市'},
        {'code':'440901','title':'广东省茂名市市辖区'},
        {'code':'440902','title':'广东省茂名市茂南区'},
        {'code':'440923','title':'广东省茂名市电白县'},
        {'code':'440981','title':'广东省茂名市高州市'},
        {'code':'440982','title':'广东省茂名市化州市'},
        {'code':'440983','title':'广东省茂名市信宜市'},
        {'code':'441200','title':'广东省肇庆市'},
        {'code':'441201','title':'广东省肇庆市市辖区'},
        {'code':'441202','title':'广东省肇庆市端州区'},
        {'code':'441203','title':'广东省肇庆市鼎湖区'},
        {'code':'441223','title':'广东省肇庆市广宁县'},
        {'code':'441224','title':'广东省肇庆市怀集县'},
        {'code':'441225','title':'广东省肇庆市封开县'},
        {'code':'441226','title':'广东省肇庆市德庆县'},
        {'code':'441283','title':'广东省肇庆市高要市'},
        {'code':'441284','title':'广东省肇庆市四会市'},
        {'code':'441300','title':'广东省惠州市'},
        {'code':'441301','title':'广东省惠州市市辖区'},
        {'code':'441302','title':'广东省惠州市惠城区'},
        {'code':'441322','title':'广东省惠州市博罗县'},
        {'code':'441323','title':'广东省惠州市惠东县'},
        {'code':'441324','title':'广东省惠州市龙门县'},
        {'code':'441381','title':'广东省惠州市惠阳市'},
        {'code':'441400','title':'广东省梅州市'},
        {'code':'441401','title':'广东省梅州市市辖区'},
        {'code':'441402','title':'广东省梅州市梅江区'},
        {'code':'441421','title':'广东省梅州市梅县'},
        {'code':'441422','title':'广东省梅州市大埔县'},
        {'code':'441423','title':'广东省梅州市丰顺县'},
        {'code':'441424','title':'广东省梅州市五华县'},
        {'code':'441426','title':'广东省梅州市西远县'},
        {'code':'441427','title':'广东省梅州市蕉岭县'},
        {'code':'441481','title':'广东省梅州市兴宁市'},
        {'code':'441500','title':'广东省汕尾市'},
        {'code':'441501','title':'广东省汕尾市市辖区'},
        {'code':'441502','title':'广东省汕尾市城区'},
        {'code':'441521','title':'广东省汕尾市海丰县'},
        {'code':'441523','title':'广东省汕尾市陆河县'},
        {'code':'441581','title':'广东省汕尾市陆丰市'},
        {'code':'441600','title':'广东省河源市'},
        {'code':'441601','title':'广东省河源市市辖区'},
        {'code':'441602','title':'广东省河源市源城区'},
        {'code':'441621','title':'广东省河源市紫金县'},
        {'code':'441622','title':'广东省河源市龙川县'},
        {'code':'441623','title':'广东省河源市连西县'},
        {'code':'441624','title':'广东省河源市和西县'},
        {'code':'441625','title':'广东省河源市东源县'},
        {'code':'441700','title':'广东省阳江市'},
        {'code':'441701','title':'广东省阳江市市辖区'},
        {'code':'441702','title':'广东省阳江市江城区'},
        {'code':'441721','title':'广东省阳江市阳西县'},
        {'code':'441723','title':'广东省阳江市阳东县'},
        {'code':'441781','title':'广东省阳江市阳春市'},
        {'code':'441800','title':'广东省清远市'},
        {'code':'441801','title':'广东省清远市市辖区'},
        {'code':'441802','title':'广东省清远市清城区'},
        {'code':'441821','title':'广东省清远市佛冈县'},
        {'code':'441823','title':'广东省清远市阳山县'},
        {'code':'441825','title':'广东省清远市连山壮族瑶族自治县'},
        {'code':'441826','title':'广东省清远市连南瑶族自治县'},
        {'code':'441827','title':'广东省清远市清新县'},
        {'code':'441881','title':'广东省清远市英德市'},
        {'code':'441882','title':'广东省清远市连州市'},
        {'code':'441900','title':'广东省东莞市'},
        {'code':'441901','title':'广东省东莞市市辖区'},
        {'code':'442000','title':'广东省中山市'},
        {'code':'442001','title':'广东省中山市市辖区'},
        {'code':'445100','title':'广东省潮州市'},
        {'code':'445101','title':'广东省潮州市市辖区'},
        {'code':'445102','title':'广东省潮州市湘桥区'},
        {'code':'445121','title':'广东省潮州市潮安县'},
        {'code':'445122','title':'广东省潮州市饶西县'},
        {'code':'445200','title':'广东省揭阳市'},
        {'code':'445201','title':'广东省揭阳市市辖区'},
        {'code':'445202','title':'广东省揭阳市榕城区'},
        {'code':'445221','title':'广东省揭阳市揭东县'},
        {'code':'445222','title':'广东省揭阳市揭西县'},
        {'code':'445224','title':'广东省揭阳市惠来县'},
        {'code':'445281','title':'广东省揭阳市普宁市'},
        {'code':'445300','title':'广东省云浮市'},
        {'code':'445301','title':'广东省云浮市市辖区'},
        {'code':'445302','title':'广东省云浮市云城区'},
        {'code':'445321','title':'广东省云浮市新兴县'},
        {'code':'445322','title':'广东省云浮市郁南县'},
        {'code':'445323','title':'广东省云浮市云安县'},
        {'code':'445381','title':'广东省云浮市罗定市'},
        {'code':'450000','title':'广西壮族自治区'},
        {'code':'450100','title':'广西壮族自治区南宁市'},
        {'code':'450101','title':'广西壮族自治区南宁市市辖区'},
        {'code':'450102','title':'广西壮族自治区南宁市兴宁区'},
        {'code':'450103','title':'广西壮族自治区南宁市新城区'},
        {'code':'450104','title':'广西壮族自治区南宁市城北区'},
        {'code':'450105','title':'广西壮族自治区南宁市江南区'},
        {'code':'450106','title':'广西壮族自治区南宁市永新区'},
        {'code':'450111','title':'广西壮族自治区南宁市市郊区'},
        {'code':'450121','title':'广西壮族自治区南宁市邕宁县'},
        {'code':'450122','title':'广西壮族自治区南宁市武鸣县'},
        {'code':'450200','title':'广西壮族自治区柳州市'},
        {'code':'450201','title':'广西壮族自治区柳州市市辖区'},
        {'code':'450202','title':'广西壮族自治区柳州市城中区'},
        {'code':'450203','title':'广西壮族自治区柳州市鱼峰区'},
        {'code':'450204','title':'广西壮族自治区柳州市柳南区'},
        {'code':'450205','title':'广西壮族自治区柳州市柳北区'},
        {'code':'450211','title':'广西壮族自治区柳州市市郊区'},
        {'code':'450221','title':'广西壮族自治区柳州市柳江县'},
        {'code':'450222','title':'广西壮族自治区柳州市柳城县'},
        {'code':'450300','title':'广西壮族自治区桂林市'},
        {'code':'450301','title':'广西壮族自治区桂林市市辖区'},
        {'code':'450302','title':'广西壮族自治区桂林市秀峰区'},
        {'code':'450303','title':'广西壮族自治区桂林市叠彩区'},
        {'code':'450304','title':'广西壮族自治区桂林市象山区'},
        {'code':'450305','title':'广西壮族自治区桂林市七星区'},
        {'code':'450311','title':'广西壮族自治区桂林市雁山区'},
        {'code':'450321','title':'广西壮族自治区桂林市阳朔县'},
        {'code':'450322','title':'广西壮族自治区桂林市临桂县'},
        {'code':'450323','title':'广西壮族自治区桂林市灵川县'},
        {'code':'450324','title':'广西壮族自治区桂林市全州县'},
        {'code':'450325','title':'广西壮族自治区桂林市兴安县'},
        {'code':'450326','title':'广西壮族自治区桂林市永福县'},
        {'code':'450327','title':'广西壮族自治区桂林市灌阳县'},
        {'code':'450328','title':'广西壮族自治区桂林市龙胜各族自治县'},
        {'code':'450329','title':'广西壮族自治区桂林市资源县'},
        {'code':'450330','title':'广西壮族自治区桂林市西乐县'},
        {'code':'450331','title':'广西壮族自治区桂林市荔浦县'},
        {'code':'450332','title':'广西壮族自治区桂林市恭城瑶族自治县'},
        {'code':'450400','title':'广西壮族自治区梧州市'},
        {'code':'450401','title':'广西壮族自治区梧州市市辖区'},
        {'code':'450403','title':'广西壮族自治区梧州市万秀区'},
        {'code':'450404','title':'广西壮族自治区梧州市蝶山区'},
        {'code':'450411','title':'广西壮族自治区梧州市市郊区'},
        {'code':'450421','title':'广西壮族自治区梧州市苍梧县'},
        {'code':'450422','title':'广西壮族自治区梧州市藤县'},
        {'code':'450423','title':'广西壮族自治区梧州市蒙山县'},
        {'code':'450481','title':'广西壮族自治区梧州市岑溪市'},
        {'code':'450500','title':'广西壮族自治区北海市'},
        {'code':'450501','title':'广西壮族自治区北海市市辖区'},
        {'code':'450502','title':'广西壮族自治区北海市海城区'},
        {'code':'450503','title':'广西壮族自治区北海市银海区'},
        {'code':'450512','title':'广西壮族自治区北海市铁山港区'},
        {'code':'450521','title':'广西壮族自治区北海市合浦县'},
        {'code':'450600','title':'广西壮族自治区防城港市'},
        {'code':'450601','title':'广西壮族自治区防城港市市辖区'},
        {'code':'450602','title':'广西壮族自治区防城港市港口区'},
        {'code':'450603','title':'广西壮族自治区防城港市防城区'},
        {'code':'450621','title':'广西壮族自治区防城港市上思县'},
        {'code':'450681','title':'广西壮族自治区防城港市东兴市'},
        {'code':'450700','title':'广西壮族自治区钦州市'},
        {'code':'450701','title':'广西壮族自治区钦州市市辖区'},
        {'code':'450702','title':'广西壮族自治区钦州市钦南区'},
        {'code':'450703','title':'广西壮族自治区钦州市钦北区'},
        {'code':'450721','title':'广西壮族自治区钦州市灵山县'},
        {'code':'450722','title':'广西壮族自治区钦州市浦北县'},
        {'code':'450800','title':'广西壮族自治区贵港市'},
        {'code':'450801','title':'广西壮族自治区贵港市市辖区'},
        {'code':'450802','title':'广西壮族自治区贵港市港北区'},
        {'code':'450803','title':'广西壮族自治区贵港市港南区'},
        {'code':'450821','title':'广西壮族自治区贵港市西南县'},
        {'code':'450881','title':'广西壮族自治区贵港市桂西市'},
        {'code':'450900','title':'广西壮族自治区玉林市'},
        {'code':'450901','title':'广西壮族自治区玉林市市辖区'},
        {'code':'450902','title':'广西壮族自治区玉林市玉州区'},
        {'code':'450921','title':'广西壮族自治区玉林市容县'},
        {'code':'450922','title':'广西壮族自治区玉林市陆川县'},
        {'code':'450923','title':'广西壮族自治区玉林市博白县'},
        {'code':'450924','title':'广西壮族自治区玉林市兴业县'},
        {'code':'450981','title':'广西壮族自治区玉林市北流市'},
        {'code':'452100','title':'广西壮族自治区南宁地区'},
        {'code':'452101','title':'广西壮族自治区南宁地区凭祥市'},
        {'code':'452122','title':'广西壮族自治区南宁地区横县'},
        {'code':'452123','title':'广西壮族自治区南宁地区宾阳县'},
        {'code':'452124','title':'广西壮族自治区南宁地区上林县'},
        {'code':'452126','title':'广西壮族自治区南宁地区隆安县'},
        {'code':'452127','title':'广西壮族自治区南宁地区马山县'},
        {'code':'452128','title':'广西壮族自治区南宁地区扶绥县'},
        {'code':'452129','title':'广西壮族自治区南宁地区崇左县'},
        {'code':'452130','title':'广西壮族自治区南宁地区大新县'},
        {'code':'452131','title':'广西壮族自治区南宁地区天等县'},
        {'code':'452132','title':'广西壮族自治区南宁地区宁明县'},
        {'code':'452133','title':'广西壮族自治区南宁地区龙州县'},
        {'code':'452200','title':'广西壮族自治区柳州地区'},
        {'code':'452201','title':'广西壮族自治区柳州地区合山市'},
        {'code':'452223','title':'广西壮族自治区柳州地区鹿寨县'},
        {'code':'452224','title':'广西壮族自治区柳州地区象州县'},
        {'code':'452225','title':'广西壮族自治区柳州地区武宣县'},
        {'code':'452226','title':'广西壮族自治区柳州地区来宾县'},
        {'code':'452227','title':'广西壮族自治区柳州地区融安县'},
        {'code':'452228','title':'广西壮族自治区柳州地区三江侗族自治县'},
        {'code':'452229','title':'广西壮族自治区柳州地区融水苗族自治县'},
        {'code':'452230','title':'广西壮族自治区柳州地区金秀瑶族自治县'},
        {'code':'452231','title':'广西壮族自治区柳州地区忻城县'},
        {'code':'452400','title':'广西壮族自治区贺州地区'},
        {'code':'452402','title':'广西壮族自治区贺州地区贺州市'},
        {'code':'452424','title':'广西壮族自治区贺州地区昭西县'},
        {'code':'452427','title':'广西壮族自治区贺州地区钟山县'},
        {'code':'452428','title':'广西壮族自治区贺州地区富川瑶族自治县'},
        {'code':'452600','title':'广西壮族自治区百色地区'},
        {'code':'452601','title':'广西壮族自治区百色地区百色市'},
        {'code':'452622','title':'广西壮族自治区百色地区田阳县'},
        {'code':'452623','title':'广西壮族自治区百色地区田东县'},
        {'code':'452624','title':'广西壮族自治区百色地区西果县'},
        {'code':'452625','title':'广西壮族自治区百色地区德保县'},
        {'code':'452626','title':'广西壮族自治区百色地区靖西县'},
        {'code':'452627','title':'广西壮族自治区百色地区那坡县'},
        {'code':'452628','title':'广西壮族自治区百色地区凌云县'},
        {'code':'452629','title':'广西壮族自治区百色地区乐业县'},
        {'code':'452630','title':'广西壮族自治区百色地区田林县'},
        {'code':'452631','title':'广西壮族自治区百色地区隆林各族自治县'},
        {'code':'452632','title':'广西壮族自治区百色地区西林县'},
        {'code':'452700','title':'广西壮族自治区河池地区'},
        {'code':'452701','title':'广西壮族自治区河池地区河池市'},
        {'code':'452702','title':'广西壮族自治区河池地区宜州市'},
        {'code':'452723','title':'广西壮族自治区河池地区罗城仫佬族自治县'},
        {'code':'452724','title':'广西壮族自治区河池地区环江毛南族自治县'},
        {'code':'452725','title':'广西壮族自治区河池地区南丹县'},
        {'code':'452726','title':'广西壮族自治区河池地区天峨县'},
        {'code':'452727','title':'广西壮族自治区河池地区凤山县'},
        {'code':'452728','title':'广西壮族自治区河池地区东兰县'},
        {'code':'452729','title':'广西壮族自治区河池地区巴马瑶族自治县'},
        {'code':'452730','title':'广西壮族自治区河池地区都安瑶族自治县'},
        {'code':'452731','title':'广西壮族自治区河池地区大化瑶族自治县'},
        {'code':'460000','title':'海南省'},
        {'code':'460001','title':'海南省三亚市通什市'},
        {'code':'460002','title':'海南省三亚市琼海市'},
        {'code':'460003','title':'海南省三亚市儋州市'},
        {'code':'460004','title':'海南省三亚市琼山市'},
        {'code':'460005','title':'海南省三亚市文昌市'},
        {'code':'460006','title':'海南省三亚市万宁市'},
        {'code':'460007','title':'海南省三亚市东方市'},
        {'code':'460025','title':'海南省三亚市定安县'},
        {'code':'460026','title':'海南省三亚市屯昌县'},
        {'code':'460027','title':'海南省三亚市澄迈县'},
        {'code':'460028','title':'海南省三亚市临高县'},
        {'code':'460030','title':'海南省三亚市白沙黎族自治县'},
        {'code':'460031','title':'海南省三亚市昌江黎族自治县'},
        {'code':'460033','title':'海南省三亚市乐东黎族自治县'},
        {'code':'460034','title':'海南省三亚市陵水黎族自治县'},
        {'code':'460035','title':'海南省三亚市保亭黎族苗族自治县'},
        {'code':'460036','title':'海南省三亚市琼中黎族苗族自治县'},
        {'code':'460037','title':'海南省西沙群岛'},
        {'code':'460038','title':'海南省南沙群岛'},
        {'code':'460039','title':'海南省中沙群岛的岛礁及其海域'},
        {'code':'460100','title':'海南省海口市'},
        {'code':'460101','title':'海南省海口市市辖区'},
        {'code':'460102','title':'海南省海口市振东区'},
        {'code':'460103','title':'海南省海口市新华区'},
        {'code':'460104','title':'海南省海口市秀英区'},
        {'code':'460200','title':'海南省三亚市'},
        {'code':'460201','title':'海南省三亚市市辖区'},
        {'code':'500000','title':'重庆市'},
        {'code':'500100','title':'重庆市市辖区'},
        {'code':'500101','title':'重庆市万州区'},
        {'code':'500102','title':'重庆市涪陵区'},
        {'code':'500103','title':'重庆市渝中区'},
        {'code':'500104','title':'重庆市大渡口区'},
        {'code':'500105','title':'重庆市江北区'},
        {'code':'500106','title':'重庆市沙坪坝区'},
        {'code':'500107','title':'重庆市九龙坡区'},
        {'code':'500108','title':'重庆市南岸区'},
        {'code':'500109','title':'重庆市北碚区'},
        {'code':'500110','title':'重庆市万盛区'},
        {'code':'500111','title':'重庆市双桥区'},
        {'code':'500112','title':'重庆市渝北区'},
        {'code':'500113','title':'重庆市巴南区'},
        {'code':'500200','title':'重庆市县'},
        {'code':'500221','title':'重庆市长寿县'},
        {'code':'500222','title':'重庆市綦江县'},
        {'code':'500223','title':'重庆市潼南县'},
        {'code':'500224','title':'重庆市铜梁县'},
        {'code':'500225','title':'重庆市大足县'},
        {'code':'500226','title':'重庆市荣昌县'},
        {'code':'500227','title':'重庆市璧山县'},
        {'code':'500228','title':'重庆市梁西县'},
        {'code':'500229','title':'重庆市城口县'},
        {'code':'500230','title':'重庆市丰都县'},
        {'code':'500231','title':'重庆市垫江县'},
        {'code':'500232','title':'重庆市武隆县'},
        {'code':'500233','title':'重庆市忠县'},
        {'code':'500234','title':'重庆市开县'},
        {'code':'500235','title':'重庆市云阳县'},
        {'code':'500236','title':'重庆市奉节县'},
        {'code':'500237','title':'重庆市巫山县'},
        {'code':'500238','title':'重庆市巫溪县'},
        {'code':'500239','title':'重庆市黔江土家族苗族自治县'},
        {'code':'500240','title':'重庆市石柱土家族自治县'},
        {'code':'500241','title':'重庆市秀山土家族苗族自治县'},
        {'code':'500242','title':'重庆市酉阳土家族苗族自治县'},
        {'code':'500243','title':'重庆市彭水苗族土家族自治县'},
        {'code':'500300','title':'重庆市(市)'},
        {'code':'500381','title':'重庆市江津市'},
        {'code':'500382','title':'重庆市合川市'},
        {'code':'500383','title':'重庆市永川市'},
        {'code':'500384','title':'重庆市南川市'},
        {'code':'510000','title':'四川省'},
        {'code':'510100','title':'四川省成都市'},
        {'code':'510101','title':'四川省成都市市辖区'},
        {'code':'510104','title':'四川省成都市锦江区'},
        {'code':'510105','title':'四川省成都市青羊区'},
        {'code':'510106','title':'四川省成都市金牛区'},
        {'code':'510107','title':'四川省成都市武侯区'},
        {'code':'510108','title':'四川省成都市成华区'},
        {'code':'510112','title':'四川省成都市龙泉驿区'},
        {'code':'510113','title':'四川省成都市青白江区'},
        {'code':'510121','title':'四川省成都市金堂县'},
        {'code':'510122','title':'四川省成都市双流县'},
        {'code':'510123','title':'四川省成都市温江县'},
        {'code':'510124','title':'四川省成都市郫县'},
        {'code':'510125','title':'四川省成都市新都县'},
        {'code':'510129','title':'四川省成都市大邑县'},
        {'code':'510131','title':'四川省成都市蒲江县'},
        {'code':'510132','title':'四川省成都市新津县'},
        {'code':'510181','title':'四川省成都市都江堰市'},
        {'code':'510182','title':'四川省成都市彭州市'},
        {'code':'510183','title':'四川省成都市邛崃市'},
        {'code':'510184','title':'四川省成都市崇州市'},
        {'code':'510300','title':'四川省自贡市'},
        {'code':'510301','title':'四川省自贡市市辖区'},
        {'code':'510302','title':'四川省自贡市自流井区'},
        {'code':'510303','title':'四川省自贡市贡井区'},
        {'code':'510304','title':'四川省自贡市大安区'},
        {'code':'510311','title':'四川省自贡市沿滩区'},
        {'code':'510321','title':'四川省自贡市荣县'},
        {'code':'510322','title':'四川省自贡市富顺县'},
        {'code':'510400','title':'四川省攀枝花市'},
        {'code':'510401','title':'四川省攀枝花市市辖区'},
        {'code':'510402','title':'四川省攀枝花市东区'},
        {'code':'510403','title':'四川省攀枝花市西区'},
        {'code':'510411','title':'四川省攀枝花市仁和区'},
        {'code':'510421','title':'四川省攀枝花市米易县'},
        {'code':'510422','title':'四川省攀枝花市盐边县'},
        {'code':'510500','title':'四川省泸州市'},
        {'code':'510501','title':'四川省泸州市市辖区'},
        {'code':'510502','title':'四川省泸州市江阳区'},
        {'code':'510503','title':'四川省泸州市纳溪区'},
        {'code':'510504','title':'四川省泸州市龙马潭区'},
        {'code':'510521','title':'四川省泸州市泸县'},
        {'code':'510522','title':'四川省泸州市合江县'},
        {'code':'510524','title':'四川省泸州市叙永县'},
        {'code':'510525','title':'四川省泸州市古蔺县'},
        {'code':'510600','title':'四川省德阳市'},
        {'code':'510601','title':'四川省德阳市市辖区'},
        {'code':'510603','title':'四川省德阳市旌阳区'},
        {'code':'510623','title':'四川省德阳市中江县'},
        {'code':'510626','title':'四川省德阳市罗江县'},
        {'code':'510681','title':'四川省德阳市广汉市'},
        {'code':'510682','title':'四川省德阳市什邡市'},
        {'code':'510683','title':'四川省德阳市绵竹市'},
        {'code':'510700','title':'四川省绵阳市'},
        {'code':'510701','title':'四川省绵阳市市辖区'},
        {'code':'510703','title':'四川省绵阳市涪城区'},
        {'code':'510704','title':'四川省绵阳市游仙区'},
        {'code':'510722','title':'四川省绵阳市三台县'},
        {'code':'510723','title':'四川省绵阳市盐亭县'},
        {'code':'510724','title':'四川省绵阳市安县'},
        {'code':'510725','title':'四川省绵阳市梓潼县'},
        {'code':'510726','title':'四川省绵阳市北川县'},
        {'code':'510727','title':'四川省绵阳市西武县'},
        {'code':'510781','title':'四川省绵阳市江油市'},
        {'code':'510800','title':'四川省广元市'},
        {'code':'510801','title':'四川省广元市市辖区'},
        {'code':'510802','title':'四川省广元市市中区'},
        {'code':'510811','title':'四川省广元市元坝区'},
        {'code':'510812','title':'四川省广元市朝天区'},
        {'code':'510821','title':'四川省广元市旺苍县'},
        {'code':'510822','title':'四川省广元市青川县'},
        {'code':'510823','title':'四川省广元市剑阁县'},
        {'code':'510824','title':'四川省广元市苍溪县'},
        {'code':'510900','title':'四川省遂宁市'},
        {'code':'510901','title':'四川省遂宁市市辖区'},
        {'code':'510902','title':'四川省遂宁市市中区'},
        {'code':'510921','title':'四川省遂宁市蓬溪县'},
        {'code':'510922','title':'四川省遂宁市射洪县'},
        {'code':'510923','title':'四川省遂宁市大英县'},
        {'code':'511000','title':'四川省内江市'},
        {'code':'511001','title':'四川省内江市市辖区'},
        {'code':'511002','title':'四川省内江市市中区'},
        {'code':'511011','title':'四川省内江市东兴区'},
        {'code':'511024','title':'四川省内江市威远县'},
        {'code':'511025','title':'四川省内江市资中县'},
        {'code':'511028','title':'四川省内江市隆昌县'},
        {'code':'511100','title':'四川省乐山市'},
        {'code':'511101','title':'四川省乐山市市辖区'},
        {'code':'511102','title':'四川省乐山市市中区'},
        {'code':'511111','title':'四川省乐山市沙湾区'},
        {'code':'511112','title':'四川省乐山市五通桥区'},
        {'code':'511113','title':'四川省乐山市金口河区'},
        {'code':'511123','title':'四川省乐山市犍为县'},
        {'code':'511124','title':'四川省乐山市井研县'},
        {'code':'511126','title':'四川省乐山市夹江县'},
        {'code':'511129','title':'四川省乐山市沐川县'},
        {'code':'511132','title':'四川省乐山市峨边彝族自治县'},
        {'code':'511133','title':'四川省乐山市马边彝族自治县'},
        {'code':'511181','title':'四川省乐山市峨眉山市'},
        {'code':'511300','title':'四川省南充市'},
        {'code':'511301','title':'四川省南充市市辖区'},
        {'code':'511302','title':'四川省南充市顺庆区'},
        {'code':'511303','title':'四川省南充市高坪区'},
        {'code':'511304','title':'四川省南充市嘉陵区'},
        {'code':'511321','title':'四川省南充市南部县'},
        {'code':'511322','title':'四川省南充市营山县'},
        {'code':'511323','title':'四川省南充市蓬安县'},
        {'code':'511324','title':'四川省南充市仪陇县'},
        {'code':'511325','title':'四川省南充市西充县'},
        {'code':'511381','title':'四川省南充市阆中市'},
        {'code':'511500','title':'四川省宜宾市'},
        {'code':'511501','title':'四川省宜宾市市辖区'},
        {'code':'511502','title':'四川省宜宾市翠屏区'},
        {'code':'511521','title':'四川省宜宾市宜宾县'},
        {'code':'511522','title':'四川省宜宾市南溪县'},
        {'code':'511523','title':'四川省宜宾市江安县'},
        {'code':'511524','title':'四川省宜宾市长宁县'},
        {'code':'511525','title':'四川省宜宾市高县'},
        {'code':'511526','title':'四川省宜宾市珙县'},
        {'code':'511527','title':'四川省宜宾市筠连县'},
        {'code':'511528','title':'四川省宜宾市兴文县'},
        {'code':'511529','title':'四川省宜宾市屏山县'},
        {'code':'511600','title':'四川省广安市'},
        {'code':'511601','title':'四川省广安市市辖区'},
        {'code':'511602','title':'四川省广安市广安区'},
        {'code':'511621','title':'四川省广安市岳池县'},
        {'code':'511622','title':'四川省广安市武胜县'},
        {'code':'511623','title':'四川省广安市邻水县'},
        {'code':'511681','title':'四川省广安市华蓥市'},
        {'code':'513000','title':'四川省达川地区'},
        {'code':'513001','title':'四川省达川地区达川市'},
        {'code':'513002','title':'四川省达川地区万源市'},
        {'code':'513021','title':'四川省达川地区达县'},
        {'code':'513022','title':'四川省达川地区宣汉县'},
        {'code':'513023','title':'四川省达川地区开江县'},
        {'code':'513029','title':'四川省达川地区大竹县'},
        {'code':'513030','title':'四川省达川地区渠县'},
        {'code':'513100','title':'四川省雅安地区'},
        {'code':'513101','title':'四川省雅安地区雅安市'},
        {'code':'513122','title':'四川省雅安地区名山县'},
        {'code':'513123','title':'四川省雅安地区荥经县'},
        {'code':'513124','title':'四川省雅安地区汉源县'},
        {'code':'513125','title':'四川省雅安地区石棉县'},
        {'code':'513126','title':'四川省雅安地区天全县'},
        {'code':'513127','title':'四川省雅安地区芦山县'},
        {'code':'513128','title':'四川省雅安地区宝兴县'},
        {'code':'513200','title':'四川省阿坝藏族羌族自治州'},
        {'code':'513221','title':'四川省阿坝藏族羌族自治州汶川县'},
        {'code':'513222','title':'四川省阿坝藏族羌族自治州理县'},
        {'code':'513223','title':'四川省阿坝藏族羌族自治州茂县'},
        {'code':'513224','title':'四川省阿坝藏族羌族自治州松潘县'},
        {'code':'513225','title':'四川省阿坝藏族羌族自治州九寨沟县'},
        {'code':'513226','title':'四川省阿坝藏族羌族自治州金川县'},
        {'code':'513227','title':'四川省阿坝藏族羌族自治州小金县'},
        {'code':'513228','title':'四川省阿坝藏族羌族自治州黑水县'},
        {'code':'513229','title':'四川省阿坝藏族羌族自治州马尔康县'},
        {'code':'513230','title':'四川省阿坝藏族羌族自治州壤塘县'},
        {'code':'513231','title':'四川省阿坝藏族羌族自治州阿坝县'},
        {'code':'513232','title':'四川省阿坝藏族羌族自治州若尔盖县'},
        {'code':'513233','title':'四川省阿坝藏族羌族自治州红原县'},
        {'code':'513300','title':'四川省甘孜藏族自治州'},
        {'code':'513321','title':'四川省甘孜藏族自治州康定县'},
        {'code':'513322','title':'四川省甘孜藏族自治州泸定县'},
        {'code':'513323','title':'四川省甘孜藏族自治州丹巴县'},
        {'code':'513324','title':'四川省甘孜藏族自治州九龙县'},
        {'code':'513325','title':'四川省甘孜藏族自治州雅江县'},
        {'code':'513326','title':'四川省甘孜藏族自治州道孚县'},
        {'code':'513327','title':'四川省甘孜藏族自治州炉霍县'},
        {'code':'513328','title':'四川省甘孜藏族自治州甘孜县'},
        {'code':'513329','title':'四川省甘孜藏族自治州新龙县'},
        {'code':'513330','title':'四川省甘孜藏族自治州德格县'},
        {'code':'513331','title':'四川省甘孜藏族自治州白玉县'},
        {'code':'513332','title':'四川省甘孜藏族自治州石渠县'},
        {'code':'513333','title':'四川省甘孜藏族自治州色达县'},
        {'code':'513334','title':'四川省甘孜藏族自治州理塘县'},
        {'code':'513335','title':'四川省甘孜藏族自治州巴塘县'},
        {'code':'513336','title':'四川省甘孜藏族自治州乡城县'},
        {'code':'513337','title':'四川省甘孜藏族自治州稻城县'},
        {'code':'513338','title':'四川省甘孜藏族自治州得荣县'},
        {'code':'513400','title':'四川省凉山彝族自治州'},
        {'code':'513401','title':'四川省凉山彝族自治州西昌市'},
        {'code':'513422','title':'四川省凉山彝族自治州木里藏族自治县'},
        {'code':'513423','title':'四川省凉山彝族自治州盐源县'},
        {'code':'513424','title':'四川省凉山彝族自治州德昌县'},
        {'code':'513425','title':'四川省凉山彝族自治州会理县'},
        {'code':'513426','title':'四川省凉山彝族自治州会东县'},
        {'code':'513427','title':'四川省凉山彝族自治州宁南县'},
        {'code':'513428','title':'四川省凉山彝族自治州普格县'},
        {'code':'513429','title':'四川省凉山彝族自治州布拖县'},
        {'code':'513430','title':'四川省凉山彝族自治州金阳县'},
        {'code':'513431','title':'四川省凉山彝族自治州昭觉县'},
        {'code':'513432','title':'四川省凉山彝族自治州喜德县'},
        {'code':'513433','title':'四川省凉山彝族自治州冕宁县'},
        {'code':'513434','title':'四川省凉山彝族自治州越西县'},
        {'code':'513435','title':'四川省凉山彝族自治州甘洛县'},
        {'code':'513436','title':'四川省凉山彝族自治州美姑县'},
        {'code':'513437','title':'四川省凉山彝族自治州雷波县'},
        {'code':'513700','title':'四川省巴中地区'},
        {'code':'513701','title':'四川省巴中地区巴中市'},
        {'code':'513721','title':'四川省巴中地区通江县'},
        {'code':'513722','title':'四川省巴中地区南江县'},
        {'code':'513723','title':'四川省巴中地区西昌县'},
        {'code':'513800','title':'四川省眉山地区'},
        {'code':'513821','title':'四川省眉山地区眉山县'},
        {'code':'513822','title':'四川省眉山地区仁寿县'},
        {'code':'513823','title':'四川省眉山地区彭山县'},
        {'code':'513824','title':'四川省眉山地区洪雅县'},
        {'code':'513825','title':'四川省眉山地区丹棱县'},
        {'code':'513826','title':'四川省眉山地区青神县'},
        {'code':'513900','title':'四川省眉山地区资阳地区'},
        {'code':'513901','title':'四川省眉山地区资阳市'},
        {'code':'513902','title':'四川省眉山地区简阳市'},
        {'code':'513921','title':'四川省眉山地区安岳县'},
        {'code':'513922','title':'四川省眉山地区乐至县'},
        {'code':'520000','title':'贵州省'},
        {'code':'520100','title':'贵州省贵阳市'},
        {'code':'520101','title':'贵州省贵阳市市辖区'},
        {'code':'520102','title':'贵州省贵阳市南明区'},
        {'code':'520103','title':'贵州省贵阳市云岩区'},
        {'code':'520111','title':'贵州省贵阳市花溪区'},
        {'code':'520112','title':'贵州省贵阳市乌当区'},
        {'code':'520113','title':'贵州省贵阳市白云区'},
        {'code':'520121','title':'贵州省贵阳市开阳县'},
        {'code':'520122','title':'贵州省贵阳市息烽县'},
        {'code':'520123','title':'贵州省贵阳市修文县'},
        {'code':'520181','title':'贵州省贵阳市清镇市'},
        {'code':'520200','title':'贵州省六盘水市'},
        {'code':'520201','title':'贵州省六盘水市钟山区'},
        {'code':'520202','title':'贵州省六盘水市盘县特区'},
        {'code':'520203','title':'贵州省六盘水市六枝特区'},
        {'code':'520221','title':'贵州省六盘水市水城县'},
        {'code':'520300','title':'贵州省遵义市'},
        {'code':'520301','title':'贵州省遵义市市辖区'},
        {'code':'520302','title':'贵州省遵义市红花岗区'},
        {'code':'520321','title':'贵州省遵义市遵义县'},
        {'code':'520322','title':'贵州省遵义市桐梓县'},
        {'code':'520323','title':'贵州省遵义市绥阳县'},
        {'code':'520324','title':'贵州省遵义市正安县'},
        {'code':'520325','title':'贵州省遵义市道真仡佬族苗族自治县'},
        {'code':'520326','title':'贵州省遵义市务川仡佬族苗族自治县'},
        {'code':'520327','title':'贵州省遵义市凤冈县'},
        {'code':'520328','title':'贵州省遵义市湄潭县'},
        {'code':'520329','title':'贵州省遵义市余庆县'},
        {'code':'520330','title':'贵州省遵义市习水县'},
        {'code':'520381','title':'贵州省遵义市赤水市'},
        {'code':'520382','title':'贵州省遵义市仁怀市'},
        {'code':'522200','title':'贵州省铜仁地区'},
        {'code':'522201','title':'贵州省铜仁地区铜仁市'},
        {'code':'522222','title':'贵州省铜仁地区江口县'},
        {'code':'522223','title':'贵州省铜仁地区玉屏侗族自治县'},
        {'code':'522224','title':'贵州省铜仁地区石阡县'},
        {'code':'522225','title':'贵州省铜仁地区思南县'},
        {'code':'522226','title':'贵州省铜仁地区印江土家族苗族自治县'},
        {'code':'522227','title':'贵州省铜仁地区德江县'},
        {'code':'522228','title':'贵州省铜仁地区沿河土家族自治县'},
        {'code':'522229','title':'贵州省铜仁地区松桃苗族自治县'},
        {'code':'522230','title':'贵州省铜仁地区万山特区'},
        {'code':'522300','title':'贵州省黔西南布依族苗族自治州'},
        {'code':'522301','title':'贵州省黔西南布依族苗族自治州兴义市'},
        {'code':'522322','title':'贵州省黔西南布依族苗族自治州兴仁县'},
        {'code':'522323','title':'贵州省黔西南布依族苗族自治州普安县'},
        {'code':'522324','title':'贵州省黔西南布依族苗族自治州晴隆县'},
        {'code':'522325','title':'贵州省黔西南布依族苗族自治州贞丰县'},
        {'code':'522326','title':'贵州省黔西南布依族苗族自治州望谟县'},
        {'code':'522327','title':'贵州省黔西南布依族苗族自治州册亨县'},
        {'code':'522328','title':'贵州省黔西南布依族苗族自治州安龙县'},
        {'code':'522400','title':'贵州省毕节地区'},
        {'code':'522401','title':'贵州省毕节地区毕节市'},
        {'code':'522422','title':'贵州省毕节地区大方县'},
        {'code':'522423','title':'贵州省毕节地区黔西县'},
        {'code':'522424','title':'贵州省毕节地区金沙县'},
        {'code':'522425','title':'贵州省毕节地区织金县'},
        {'code':'522426','title':'贵州省毕节地区纳雍县'},
        {'code':'522427','title':'贵州省毕节地区威宁彝族回族苗族自治县'},
        {'code':'522428','title':'贵州省毕节地区赫章县'},
        {'code':'522500','title':'贵州省安顺地区'},
        {'code':'522501','title':'贵州省安顺地区安顺市'},
        {'code':'522526','title':'贵州省安顺地区西坝县'},
        {'code':'522527','title':'贵州省安顺地区普定县'},
        {'code':'522528','title':'贵州省安顺地区关岭布依族苗族自治县'},
        {'code':'522529','title':'贵州省安顺地区镇宁布依族苗族自治县'},
        {'code':'522530','title':'贵州省安顺地区紫云苗族布依族自治县'},
        {'code':'522600','title':'贵州省黔东南苗族侗族自治州'},
        {'code':'522601','title':'贵州省黔东南苗族侗族自治州凯里市'},
        {'code':'522622','title':'贵州省黔东南苗族侗族自治州黄西县'},
        {'code':'522623','title':'贵州省黔东南苗族侗族自治州施秉县'},
        {'code':'522624','title':'贵州省黔东南苗族侗族自治州三穗县'},
        {'code':'522625','title':'贵州省黔东南苗族侗族自治州镇远县'},
        {'code':'522626','title':'贵州省黔东南苗族侗族自治州岑巩县'},
        {'code':'522627','title':'贵州省黔东南苗族侗族自治州天柱县'},
        {'code':'522628','title':'贵州省黔东南苗族侗族自治州锦屏县'},
        {'code':'522629','title':'贵州省黔东南苗族侗族自治州剑河县'},
        {'code':'522630','title':'贵州省黔东南苗族侗族自治州台江县'},
        {'code':'522631','title':'贵州省黔东南苗族侗族自治州黎西县'},
        {'code':'522632','title':'贵州省黔东南苗族侗族自治州榕江县'},
        {'code':'522633','title':'贵州省黔东南苗族侗族自治州从江县'},
        {'code':'522634','title':'贵州省黔东南苗族侗族自治州雷山县'},
        {'code':'522635','title':'贵州省黔东南苗族侗族自治州麻江县'},
        {'code':'522636','title':'贵州省黔东南苗族侗族自治州丹寨县'},
        {'code':'522700','title':'贵州省黔南布依族苗族自治州'},
        {'code':'522701','title':'贵州省黔南布依族苗族自治州都匀市'},
        {'code':'522702','title':'贵州省黔南布依族苗族自治州福泉市'},
        {'code':'522722','title':'贵州省黔南布依族苗族自治州荔波县'},
        {'code':'522723','title':'贵州省黔南布依族苗族自治州贵定县'},
        {'code':'522725','title':'贵州省黔南布依族苗族自治州瓮安县'},
        {'code':'522726','title':'贵州省黔南布依族苗族自治州独山县'},
        {'code':'522727','title':'贵州省黔南布依族苗族自治州西塘县'},
        {'code':'522728','title':'贵州省黔南布依族苗族自治州罗甸县'},
        {'code':'522729','title':'贵州省黔南布依族苗族自治州长顺县'},
        {'code':'522730','title':'贵州省黔南布依族苗族自治州龙里县'},
        {'code':'522731','title':'贵州省黔南布依族苗族自治州惠水县'},
        {'code':'522732','title':'贵州省黔南布依族苗族自治州三都水族自治县'},
        {'code':'530000','title':'云南省'},
        {'code':'530100','title':'云南省昆明市'},
        {'code':'530101','title':'云南省昆明市市辖区'},
        {'code':'530102','title':'云南省昆明市五华区'},
        {'code':'530103','title':'云南省昆明市盘龙区'},
        {'code':'530111','title':'云南省昆明市官渡区'},
        {'code':'530112','title':'云南省昆明市西山区'},
        {'code':'530113','title':'云南省昆明市东川区'},
        {'code':'530121','title':'云南省昆明市呈贡县'},
        {'code':'530122','title':'云南省昆明市西宁县'},
        {'code':'530124','title':'云南省昆明市富民县'},
        {'code':'530125','title':'云南省昆明市宜良县'},
        {'code':'530126','title':'云南省昆明市石林彝族自治县'},
        {'code':'530127','title':'云南省昆明市嵩明县'},
        {'code':'530128','title':'云南省昆明市禄劝彝族苗族自治县'},
        {'code':'530129','title':'云南省昆明市寻甸回族彝族自治县'},
        {'code':'530181','title':'云南省昆明市安宁市'},
        {'code':'530300','title':'云南省曲靖市'},
        {'code':'530301','title':'云南省曲靖市市辖区'},
        {'code':'530302','title':'云南省曲靖市麒麟区'},
        {'code':'530321','title':'云南省曲靖市马龙县'},
        {'code':'530322','title':'云南省曲靖市陆良县'},
        {'code':'530323','title':'云南省曲靖市师宗县'},
        {'code':'530324','title':'云南省曲靖市罗西县'},
        {'code':'530325','title':'云南省曲靖市富源县'},
        {'code':'530326','title':'云南省曲靖市会泽县'},
        {'code':'530328','title':'云南省曲靖市沾益县'},
        {'code':'530381','title':'云南省曲靖市宣威市'},
        {'code':'530400','title':'云南省玉溪市'},
        {'code':'530401','title':'云南省玉溪市市辖区'},
        {'code':'530402','title':'云南省玉溪市红塔区'},
        {'code':'530421','title':'云南省玉溪市江川县'},
        {'code':'530422','title':'云南省玉溪市澄江县'},
        {'code':'530423','title':'云南省玉溪市通海县'},
        {'code':'530424','title':'云南省玉溪市华宁县'},
        {'code':'530425','title':'云南省玉溪市易门县'},
        {'code':'530426','title':'云南省玉溪市峨山彝族自治县'},
        {'code':'530427','title':'云南省玉溪市新西彝族傣族自治县'},
        {'code':'530428','title':'云南省玉溪市元江哈尼族彝族傣族自治县'},
        {'code':'532100','title':'云南省昭通地区'},
        {'code':'532101','title':'云南省昭通地区昭通市'},
        {'code':'532122','title':'云南省昭通地区鲁甸县'},
        {'code':'532123','title':'云南省昭通地区巧家县'},
        {'code':'532124','title':'云南省昭通地区盐津县'},
        {'code':'532125','title':'云南省昭通地区大关县'},
        {'code':'532126','title':'云南省昭通地区永善县'},
        {'code':'532127','title':'云南省昭通地区绥江县'},
        {'code':'532128','title':'云南省昭通地区镇雄县'},
        {'code':'532129','title':'云南省昭通地区彝良县'},
        {'code':'532130','title':'云南省昭通地区威信县'},
        {'code':'532131','title':'云南省昭通地区水富县'},
        {'code':'532300','title':'云南省楚雄彝族自治州'},
        {'code':'532301','title':'云南省楚雄彝族自治州楚雄市'},
        {'code':'532322','title':'云南省楚雄彝族自治州双柏县'},
        {'code':'532323','title':'云南省楚雄彝族自治州牟定县'},
        {'code':'532324','title':'云南省楚雄彝族自治州南华县'},
        {'code':'532325','title':'云南省楚雄彝族自治州姚安县'},
        {'code':'532326','title':'云南省楚雄彝族自治州大姚县'},
        {'code':'532327','title':'云南省楚雄彝族自治州永仁县'},
        {'code':'532328','title':'云南省楚雄彝族自治州元谋县'},
        {'code':'532329','title':'云南省楚雄彝族自治州武定县'},
        {'code':'532331','title':'云南省楚雄彝族自治州禄丰县'},
        {'code':'532500','title':'云南省红河哈尼族彝族自治州'},
        {'code':'532501','title':'云南省红河哈尼族彝族自治州个旧市'},
        {'code':'532502','title':'云南省红河哈尼族彝族自治州开远市'},
        {'code':'532522','title':'云南省红河哈尼族彝族自治州蒙自县'},
        {'code':'532523','title':'云南省红河哈尼族彝族自治州屏边苗族自治县'},
        {'code':'532524','title':'云南省红河哈尼族彝族自治州建水县'},
        {'code':'532525','title':'云南省红河哈尼族彝族自治州石屏县'},
        {'code':'532526','title':'云南省红河哈尼族彝族自治州弥勒县'},
        {'code':'532527','title':'云南省红河哈尼族彝族自治州泸西县'},
        {'code':'532528','title':'云南省红河哈尼族彝族自治州元阳县'},
        {'code':'532529','title':'云南省红河哈尼族彝族自治州红河县'},
        {'code':'532530','title':'云南省红河哈尼族彝族自治州金西苗族瑶族傣族自治县'},
        {'code':'532531','title':'云南省红河哈尼族彝族自治州绿春县'},
        {'code':'532532','title':'云南省红河哈尼族彝族自治州河口瑶族自治县'},
        {'code':'532600','title':'云南省文山壮族苗族自治州'},
        {'code':'532621','title':'云南省文山壮族苗族自治州文山县'},
        {'code':'532622','title':'云南省文山壮族苗族自治州砚山县'},
        {'code':'532623','title':'云南省文山壮族苗族自治州西畴县'},
        {'code':'532624','title':'云南省文山壮族苗族自治州麻栗坡县'},
        {'code':'532625','title':'云南省文山壮族苗族自治州马关县'},
        {'code':'532626','title':'云南省文山壮族苗族自治州丘北县'},
        {'code':'532627','title':'云南省文山壮族苗族自治州广南县'},
        {'code':'532628','title':'云南省文山壮族苗族自治州富宁县'},
        {'code':'532700','title':'云南省思茅地区'},
        {'code':'532701','title':'云南省思茅地区思茅市'},
        {'code':'532722','title':'云南省思茅地区普洱哈尼族彝族自治县'},
        {'code':'532723','title':'云南省思茅地区墨江哈尼族自治县'},
        {'code':'532724','title':'云南省思茅地区景东彝族自治县'},
        {'code':'532725','title':'云南省思茅地区景谷傣族彝族自治县'},
        {'code':'532726','title':'云南省思茅地区镇沅彝族哈尼族拉祜族自治县'},
        {'code':'532727','title':'云南省思茅地区江城哈尼族彝族自治县'},
        {'code':'532728','title':'云南省思茅地区孟连傣族拉祜族佤族自治县'},
        {'code':'532729','title':'云南省思茅地区澜沧拉祜族自治县'},
        {'code':'532730','title':'云南省思茅地区西盟佤族自治县'},
        {'code':'532800','title':'云南省西双版纳傣族自治州'},
        {'code':'532801','title':'云南省西双版纳傣族自治州景洪市'},
        {'code':'532822','title':'云南省西双版纳傣族自治州勐海县'},
        {'code':'532823','title':'云南省西双版纳傣族自治州勐腊县'},
        {'code':'532900','title':'云南省大理白族自治州'},
        {'code':'532901','title':'云南省大理白族自治州大理市'},
        {'code':'532922','title':'云南省大理白族自治州漾濞彝族自治县'},
        {'code':'532923','title':'云南省大理白族自治州祥云县'},
        {'code':'532924','title':'云南省大理白族自治州宾川县'},
        {'code':'532925','title':'云南省大理白族自治州弥渡县'},
        {'code':'532926','title':'云南省大理白族自治州南涧彝族自治县'},
        {'code':'532927','title':'云南省大理白族自治州巍山彝族回族自治县'},
        {'code':'532928','title':'云南省大理白族自治州永西县'},
        {'code':'532929','title':'云南省大理白族自治州云龙县'},
        {'code':'532930','title':'云南省大理白族自治州洱源县'},
        {'code':'532931','title':'云南省大理白族自治州剑川县'},
        {'code':'532932','title':'云南省大理白族自治州鹤庆县'},
        {'code':'533000','title':'云南省保山地区'},
        {'code':'533001','title':'云南省保山地区保山市'},
        {'code':'533022','title':'云南省保山地区施甸县'},
        {'code':'533023','title':'云南省保山地区腾冲县'},
        {'code':'533024','title':'云南省保山地区龙陵县'},
        {'code':'533025','title':'云南省保山地区昌宁县'},
        {'code':'533100','title':'云南省德宏傣族景颇族自治州'},
        {'code':'533101','title':'云南省德宏傣族景颇族自治州畹町市'},
        {'code':'533102','title':'云南省德宏傣族景颇族自治州瑞丽市'},
        {'code':'533103','title':'云南省德宏傣族景颇族自治州潞西市'},
        {'code':'533122','title':'云南省德宏傣族景颇族自治州梁河县'},
        {'code':'533123','title':'云南省德宏傣族景颇族自治州盈江县'},
        {'code':'533124','title':'云南省德宏傣族景颇族自治州陇川县'},
        {'code':'533200','title':'云南省丽江地区'},
        {'code':'533221','title':'云南省丽江地区丽江纳西族自治县'},
        {'code':'533222','title':'云南省丽江地区永胜县'},
        {'code':'533223','title':'云南省丽江地区华坪县'},
        {'code':'533224','title':'云南省丽江地区宁蒗彝族自治县'},
        {'code':'533300','title':'云南省怒江傈僳族自治州'},
        {'code':'533321','title':'云南省怒江傈僳族自治州泸水县'},
        {'code':'533323','title':'云南省怒江傈僳族自治州福贡县'},
        {'code':'533324','title':'云南省怒江傈僳族自治州贡山独龙族怒族自治县'},
        {'code':'533325','title':'云南省怒江傈僳族自治州兰坪白族普米族自治县'},
        {'code':'533400','title':'云南省迪庆藏族自治州'},
        {'code':'533421','title':'云南省迪庆藏族自治州中甸县'},
        {'code':'533422','title':'云南省迪庆藏族自治州德钦县'},
        {'code':'533423','title':'云南省迪庆藏族自治州维西傈僳族自治县'},
        {'code':'533500','title':'云南省临沧地区'},
        {'code':'533521','title':'云南省临沧地区临沧县'},
        {'code':'533522','title':'云南省临沧地区凤庆县'},
        {'code':'533523','title':'云南省临沧地区云县'},
        {'code':'533524','title':'云南省临沧地区永德县'},
        {'code':'533525','title':'云南省临沧地区镇康县'},
        {'code':'533526','title':'云南省临沧地区双江拉祜族佤族布朗族傣族自治县'},
        {'code':'533527','title':'云南省临沧地区耿马傣族佤族自治县'},
        {'code':'533528','title':'云南省临沧地区沧源佤族自治县'},
        {'code':'540000','title':'西藏自治区'},
        {'code':'540100','title':'西藏自治区拉萨市'},
        {'code':'540101','title':'西藏自治区拉萨市市辖区'},
        {'code':'540102','title':'西藏自治区拉萨市城关区'},
        {'code':'540121','title':'西藏自治区拉萨市林周县'},
        {'code':'540122','title':'西藏自治区拉萨市当雄县'},
        {'code':'540123','title':'西藏自治区拉萨市尼木县'},
        {'code':'540124','title':'西藏自治区拉萨市曲水县'},
        {'code':'540125','title':'西藏自治区拉萨市堆龙德庆县'},
        {'code':'540126','title':'西藏自治区拉萨市达孜县'},
        {'code':'540127','title':'西藏自治区拉萨市墨竹工卡县'},
        {'code':'542100','title':'西藏自治区昌都地区'},
        {'code':'542121','title':'西藏自治区昌都地区昌都县'},
        {'code':'542122','title':'西藏自治区昌都地区江达县'},
        {'code':'542123','title':'西藏自治区昌都地区贡觉县'},
        {'code':'542124','title':'西藏自治区昌都地区类乌齐县'},
        {'code':'542125','title':'西藏自治区昌都地区丁青县'},
        {'code':'542126','title':'西藏自治区昌都地区察雅县'},
        {'code':'542127','title':'西藏自治区昌都地区八宿县'},
        {'code':'542128','title':'西藏自治区昌都地区左贡县'},
        {'code':'542129','title':'西藏自治区昌都地区芒康县'},
        {'code':'542132','title':'西藏自治区昌都地区洛隆县'},
        {'code':'542133','title':'西藏自治区昌都地区边坝县'},
        {'code':'542134','title':'西藏自治区昌都地区盐井县'},
        {'code':'542135','title':'西藏自治区昌都地区碧土县'},
        {'code':'542136','title':'西藏自治区昌都地区妥坝县'},
        {'code':'542137','title':'西藏自治区昌都地区生达县'},
        {'code':'542200','title':'西藏自治区山南地区'},
        {'code':'542221','title':'西藏自治区山南地区乃东县'},
        {'code':'542222','title':'西藏自治区山南地区扎囊县'},
        {'code':'542223','title':'西藏自治区山南地区贡嘎县'},
        {'code':'542224','title':'西藏自治区山南地区桑日县'},
        {'code':'542225','title':'西藏自治区山南地区琼结县'},
        {'code':'542226','title':'西藏自治区山南地区曲松县'},
        {'code':'542227','title':'西藏自治区山南地区措美县'},
        {'code':'542228','title':'西藏自治区山南地区洛扎县'},
        {'code':'542229','title':'西藏自治区山南地区加查县'},
        {'code':'542231','title':'西藏自治区山南地区隆子县'},
        {'code':'542232','title':'西藏自治区山南地区错那县'},
        {'code':'542233','title':'西藏自治区山南地区浪卡子县'},
        {'code':'542300','title':'西藏自治区日喀则地区'},
        {'code':'542301','title':'西藏自治区日喀则地区日喀则市'},
        {'code':'542322','title':'西藏自治区日喀则地区南木林县'},
        {'code':'542323','title':'西藏自治区日喀则地区江孜县'},
        {'code':'542324','title':'西藏自治区日喀则地区定日县'},
        {'code':'542325','title':'西藏自治区日喀则地区萨迦县'},
        {'code':'542326','title':'西藏自治区日喀则地区拉孜县'},
        {'code':'542327','title':'西藏自治区日喀则地区昂仁县'},
        {'code':'542328','title':'西藏自治区日喀则地区谢通门县'},
        {'code':'542329','title':'西藏自治区日喀则地区白朗县'},
        {'code':'542330','title':'西藏自治区日喀则地区仁布县'},
        {'code':'542331','title':'西藏自治区日喀则地区康马县'},
        {'code':'542332','title':'西藏自治区日喀则地区定结县'},
        {'code':'542333','title':'西藏自治区日喀则地区仲巴县'},
        {'code':'542334','title':'西藏自治区日喀则地区亚东县'},
        {'code':'542335','title':'西藏自治区日喀则地区吉隆县'},
        {'code':'542336','title':'西藏自治区日喀则地区聂拉木县'},
        {'code':'542337','title':'西藏自治区日喀则地区萨嘎县'},
        {'code':'542338','title':'西藏自治区日喀则地区岗巴县'},
        {'code':'542400','title':'西藏自治区那曲地区'},
        {'code':'542421','title':'西藏自治区那曲地区那曲县'},
        {'code':'542422','title':'西藏自治区那曲地区嘉黎县'},
        {'code':'542423','title':'西藏自治区那曲地区比如县'},
        {'code':'542424','title':'西藏自治区那曲地区聂荣县'},
        {'code':'542425','title':'西藏自治区那曲地区安多县'},
        {'code':'542426','title':'西藏自治区那曲地区申扎县'},
        {'code':'542427','title':'西藏自治区那曲地区索县'},
        {'code':'542428','title':'西藏自治区那曲地区班戈县'},
        {'code':'542429','title':'西藏自治区那曲地区巴青县'},
        {'code':'542430','title':'西藏自治区那曲地区尼玛县'},
        {'code':'542500','title':'西藏自治区阿里地区'},
        {'code':'542521','title':'西藏自治区阿里地区普兰县'},
        {'code':'542522','title':'西藏自治区阿里地区札达县'},
        {'code':'542523','title':'西藏自治区阿里地区噶尔县'},
        {'code':'542524','title':'西藏自治区阿里地区日土县'},
        {'code':'542525','title':'西藏自治区阿里地区革吉县'},
        {'code':'542526','title':'西藏自治区阿里地区改则县'},
        {'code':'542527','title':'西藏自治区阿里地区措勤县'},
        {'code':'542528','title':'西藏自治区阿里地区隆格尔县'},
        {'code':'542600','title':'西藏自治区林芝地区'},
        {'code':'542621','title':'西藏自治区林芝地区林芝县'},
        {'code':'542622','title':'西藏自治区林芝地区工布江达县'},
        {'code':'542623','title':'西藏自治区林芝地区米林县'},
        {'code':'542624','title':'西藏自治区林芝地区墨脱县'},
        {'code':'542625','title':'西藏自治区林芝地区波密县'},
        {'code':'542626','title':'西藏自治区林芝地区察隅县'},
        {'code':'542627','title':'西藏自治区林芝地区朗县'},
        {'code':'610000','title':'陕西省'},
        {'code':'610100','title':'陕西省西安市'},
        {'code':'610101','title':'陕西省西安市市辖区'},
        {'code':'610102','title':'陕西省西安市新城区'},
        {'code':'610103','title':'陕西省西安市碑林区'},
        {'code':'610104','title':'陕西省西安市莲湖区'},
        {'code':'610111','title':'陕西省西安市灞桥区'},
        {'code':'610112','title':'陕西省西安市未央区'},
        {'code':'610113','title':'陕西省西安市雁塔区'},
        {'code':'610114','title':'陕西省西安市阎良区'},
        {'code':'610115','title':'陕西省西安市临潼区'},
        {'code':'610121','title':'陕西省西安市长安县'},
        {'code':'610122','title':'陕西省西安市蓝田县'},
        {'code':'610124','title':'陕西省西安市周至县'},
        {'code':'610125','title':'陕西省西安市户县'},
        {'code':'610126','title':'陕西省西安市高陵县'},
        {'code':'610200','title':'陕西省铜川市'},
        {'code':'610201','title':'陕西省铜川市市辖区'},
        {'code':'610202','title':'陕西省铜川市城区'},
        {'code':'610203','title':'陕西省铜川市郊区'},
        {'code':'610221','title':'陕西省铜川市耀县'},
        {'code':'610222','title':'陕西省铜川市宜君县'},
        {'code':'610300','title':'陕西省宝鸡市'},
        {'code':'610301','title':'陕西省宝鸡市市辖区'},
        {'code':'610302','title':'陕西省宝鸡市渭滨区'},
        {'code':'610303','title':'陕西省宝鸡市金台区'},
        {'code':'610321','title':'陕西省宝鸡市宝鸡县'},
        {'code':'610322','title':'陕西省宝鸡市凤翔县'},
        {'code':'610323','title':'陕西省宝鸡市岐山县'},
        {'code':'610324','title':'陕西省宝鸡市扶风县'},
        {'code':'610326','title':'陕西省宝鸡市眉县'},
        {'code':'610327','title':'陕西省宝鸡市陇县'},
        {'code':'610328','title':'陕西省宝鸡市千阳县'},
        {'code':'610329','title':'陕西省宝鸡市麟游县'},
        {'code':'610330','title':'陕西省宝鸡市凤县'},
        {'code':'610331','title':'陕西省宝鸡市太白县'},
        {'code':'610400','title':'陕西省咸阳市'},
        {'code':'610401','title':'陕西省咸阳市市辖区'},
        {'code':'610402','title':'陕西省咸阳市秦都区'},
        {'code':'610403','title':'陕西省咸阳市杨陵区'},
        {'code':'610404','title':'陕西省咸阳市渭城区'},
        {'code':'610422','title':'陕西省咸阳市三原县'},
        {'code':'610423','title':'陕西省咸阳市泾阳县'},
        {'code':'610424','title':'陕西省咸阳市乾县'},
        {'code':'610425','title':'陕西省咸阳市礼泉县'},
        {'code':'610426','title':'陕西省咸阳市永寿县'},
        {'code':'610427','title':'陕西省咸阳市彬县'},
        {'code':'610428','title':'陕西省咸阳市长武县'},
        {'code':'610429','title':'陕西省咸阳市旬邑县'},
        {'code':'610430','title':'陕西省咸阳市淳化县'},
        {'code':'610431','title':'陕西省咸阳市武功县'},
        {'code':'610481','title':'陕西省咸阳市兴西市'},
        {'code':'610500','title':'陕西省渭南市'},
        {'code':'610501','title':'陕西省渭南市市辖区'},
        {'code':'610502','title':'陕西省渭南市临渭区'},
        {'code':'610521','title':'陕西省渭南市华县'},
        {'code':'610522','title':'陕西省渭南市潼关县'},
        {'code':'610523','title':'陕西省渭南市大荔县'},
        {'code':'610524','title':'陕西省渭南市合阳县'},
        {'code':'610525','title':'陕西省渭南市澄城县'},
        {'code':'610526','title':'陕西省渭南市蒲城县'},
        {'code':'610527','title':'陕西省渭南市白水县'},
        {'code':'610528','title':'陕西省渭南市富西县'},
        {'code':'610581','title':'陕西省渭南市韩城市'},
        {'code':'610582','title':'陕西省渭南市华阴市'},
        {'code':'610600','title':'陕西省延安市'},
        {'code':'610601','title':'陕西省延安市市辖区'},
        {'code':'610602','title':'陕西省延安市宝塔区'},
        {'code':'610621','title':'陕西省延安市延长县'},
        {'code':'610622','title':'陕西省延安市延川县'},
        {'code':'610623','title':'陕西省延安市子长县'},
        {'code':'610624','title':'陕西省延安市安塞县'},
        {'code':'610625','title':'陕西省延安市志丹县'},
        {'code':'610626','title':'陕西省延安市吴旗县'},
        {'code':'610627','title':'陕西省延安市甘泉县'},
        {'code':'610628','title':'陕西省延安市富县'},
        {'code':'610629','title':'陕西省延安市洛川县'},
        {'code':'610630','title':'陕西省延安市宜川县'},
        {'code':'610631','title':'陕西省延安市黄龙县'},
        {'code':'610632','title':'陕西省延安市黄陵县'},
        {'code':'610700','title':'陕西省汉中市'},
        {'code':'610701','title':'陕西省汉中市市辖区'},
        {'code':'610702','title':'陕西省汉中市汉台区'},
        {'code':'610721','title':'陕西省汉中市南郑县'},
        {'code':'610722','title':'陕西省汉中市城固县'},
        {'code':'610723','title':'陕西省汉中市洋县'},
        {'code':'610724','title':'陕西省汉中市西乡县'},
        {'code':'610725','title':'陕西省汉中市勉县'},
        {'code':'610726','title':'陕西省汉中市宁强县'},
        {'code':'610727','title':'陕西省汉中市略阳县'},
        {'code':'610728','title':'陕西省汉中市镇巴县'},
        {'code':'610729','title':'陕西省汉中市留坝县'},
        {'code':'610730','title':'陕西省汉中市佛坪县'},
        {'code':'612400','title':'陕西省安康地区'},
        {'code':'612401','title':'陕西省安康地区安康市'},
        {'code':'612422','title':'陕西省安康地区汉阴县'},
        {'code':'612423','title':'陕西省安康地区石泉县'},
        {'code':'612424','title':'陕西省安康地区宁陕县'},
        {'code':'612425','title':'陕西省安康地区紫阳县'},
        {'code':'612426','title':'陕西省安康地区岚皋县'},
        {'code':'612427','title':'陕西省安康地区西利县'},
        {'code':'612428','title':'陕西省安康地区镇坪县'},
        {'code':'612429','title':'陕西省安康地区旬阳县'},
        {'code':'612430','title':'陕西省安康地区白河县'},
        {'code':'612500','title':'陕西省商洛地区'},
        {'code':'612501','title':'陕西省商洛地区商州市'},
        {'code':'612522','title':'陕西省商洛地区洛南县'},
        {'code':'612523','title':'陕西省商洛地区丹凤县'},
        {'code':'612524','title':'陕西省商洛地区商南县'},
        {'code':'612525','title':'陕西省商洛地区山阳县'},
        {'code':'612526','title':'陕西省商洛地区镇安县'},
        {'code':'612527','title':'陕西省商洛地区柞水县'},
        {'code':'612700','title':'陕西省榆林地区'},
        {'code':'612701','title':'陕西省榆林地区榆林市'},
        {'code':'612722','title':'陕西省榆林地区神木县'},
        {'code':'612723','title':'陕西省榆林地区府谷县'},
        {'code':'612724','title':'陕西省榆林地区横山县'},
        {'code':'612725','title':'陕西省榆林地区靖边县'},
        {'code':'612726','title':'陕西省榆林地区定边县'},
        {'code':'612727','title':'陕西省榆林地区绥德县'},
        {'code':'612728','title':'陕西省榆林地区米脂县'},
        {'code':'612729','title':'陕西省榆林地区佳县'},
        {'code':'612730','title':'陕西省榆林地区吴堡县'},
        {'code':'612731','title':'陕西省榆林地区清涧县'},
        {'code':'612732','title':'陕西省榆林地区子洲县'},
        {'code':'620000','title':'甘肃省'},
        {'code':'620100','title':'甘肃省兰州市'},
        {'code':'620101','title':'甘肃省兰州市市辖区'},
        {'code':'620102','title':'甘肃省兰州市城关区'},
        {'code':'620103','title':'甘肃省兰州市七里河区'},
        {'code':'620104','title':'甘肃省兰州市西固区'},
        {'code':'620105','title':'甘肃省兰州市安宁区'},
        {'code':'620111','title':'甘肃省兰州市红古区'},
        {'code':'620121','title':'甘肃省兰州市永登县'},
        {'code':'620122','title':'甘肃省兰州市皋兰县'},
        {'code':'620123','title':'甘肃省兰州市榆中县'},
        {'code':'620200','title':'甘肃省嘉峪关市'},
        {'code':'620201','title':'甘肃省嘉峪关市市辖区'},
        {'code':'620300','title':'甘肃省嘉峪关市金昌市'},
        {'code':'620301','title':'甘肃省嘉峪关市市辖区'},
        {'code':'620302','title':'甘肃省嘉峪关市金川区'},
        {'code':'620321','title':'甘肃省嘉峪关市永昌县'},
        {'code':'620400','title':'甘肃省白银市'},
        {'code':'620401','title':'甘肃省白银市市辖区'},
        {'code':'620402','title':'甘肃省白银市白银区'},
        {'code':'620403','title':'甘肃省白银市西川区'},
        {'code':'620421','title':'甘肃省白银市靖远县'},
        {'code':'620422','title':'甘肃省白银市会宁县'},
        {'code':'620423','title':'甘肃省白银市景泰县'},
        {'code':'620500','title':'甘肃省天水市'},
        {'code':'620501','title':'甘肃省天水市市辖区'},
        {'code':'620502','title':'甘肃省天水市秦城区'},
        {'code':'620503','title':'甘肃省天水市北道区'},
        {'code':'620521','title':'甘肃省天水市清水县'},
        {'code':'620522','title':'甘肃省天水市秦安县'},
        {'code':'620523','title':'甘肃省天水市甘谷县'},
        {'code':'620524','title':'甘肃省天水市武山县'},
        {'code':'620525','title':'甘肃省天水市张家川回族自治县'},
        {'code':'622100','title':'甘肃省酒泉地区'},
        {'code':'622101','title':'甘肃省酒泉地区玉门市'},
        {'code':'622102','title':'甘肃省酒泉地区酒泉市'},
        {'code':'622103','title':'甘肃省酒泉地区敦煌市'},
        {'code':'622123','title':'甘肃省酒泉地区金塔县'},
        {'code':'622124','title':'甘肃省酒泉地区肃北蒙古族自治县'},
        {'code':'622125','title':'甘肃省酒泉地区阿克塞哈萨克族自治县'},
        {'code':'622126','title':'甘肃省酒泉地区安西县'},
        {'code':'622200','title':'甘肃省张掖地区'},
        {'code':'622201','title':'甘肃省张掖地区张掖市'},
        {'code':'622222','title':'甘肃省张掖地区肃南裕固族自治县'},
        {'code':'622223','title':'甘肃省张掖地区民乐县'},
        {'code':'622224','title':'甘肃省张掖地区临泽县'},
        {'code':'622225','title':'甘肃省张掖地区高台县'},
        {'code':'622226','title':'甘肃省张掖地区山丹县'},
        {'code':'622300','title':'甘肃省武威地区'},
        {'code':'622301','title':'甘肃省武威地区武威市'},
        {'code':'622322','title':'甘肃省武威地区民勤县'},
        {'code':'622323','title':'甘肃省武威地区古浪县'},
        {'code':'622326','title':'甘肃省武威地区天祝藏族自治县'},
        {'code':'622400','title':'甘肃省定西地区'},
        {'code':'622421','title':'甘肃省定西地区定西县'},
        {'code':'622424','title':'甘肃省定西地区通渭县'},
        {'code':'622425','title':'甘肃省定西地区陇西县'},
        {'code':'622426','title':'甘肃省定西地区渭源县'},
        {'code':'622427','title':'甘肃省定西地区临洮县'},
        {'code':'622428','title':'甘肃省定西地区漳县'},
        {'code':'622429','title':'甘肃省定西地区岷县'},
        {'code':'622600','title':'甘肃省陇南地区'},
        {'code':'622621','title':'甘肃省陇南地区武都县'},
        {'code':'622623','title':'甘肃省陇南地区宕昌县'},
        {'code':'622624','title':'甘肃省陇南地区成县'},
        {'code':'622625','title':'甘肃省陇南地区康县'},
        {'code':'622626','title':'甘肃省陇南地区文县'},
        {'code':'622627','title':'甘肃省陇南地区西和县'},
        {'code':'622628','title':'甘肃省陇南地区礼县'},
        {'code':'622629','title':'甘肃省陇南地区两当县'},
        {'code':'622630','title':'甘肃省陇南地区徽县'},
        {'code':'622700','title':'甘肃省西凉地区'},
        {'code':'622701','title':'甘肃省西凉地区西凉市'},
        {'code':'622722','title':'甘肃省西凉地区泾川县'},
        {'code':'622723','title':'甘肃省西凉地区灵台县'},
        {'code':'622724','title':'甘肃省西凉地区崇信县'},
        {'code':'622725','title':'甘肃省西凉地区华亭县'},
        {'code':'622726','title':'甘肃省西凉地区庄浪县'},
        {'code':'622727','title':'甘肃省西凉地区静宁县'},
        {'code':'622800','title':'甘肃省庆阳地区'},
        {'code':'622801','title':'甘肃省庆阳地区西峰市'},
        {'code':'622821','title':'甘肃省庆阳地区庆阳县'},
        {'code':'622822','title':'甘肃省庆阳地区环县'},
        {'code':'622823','title':'甘肃省庆阳地区华池县'},
        {'code':'622824','title':'甘肃省庆阳地区合水县'},
        {'code':'622825','title':'甘肃省庆阳地区正宁县'},
        {'code':'622826','title':'甘肃省庆阳地区宁县'},
        {'code':'622827','title':'甘肃省庆阳地区镇原县'},
        {'code':'622900','title':'甘肃省临夏回族自治州'},
        {'code':'622901','title':'甘肃省临夏回族自治州临夏市'},
        {'code':'622921','title':'甘肃省临夏回族自治州临夏县'},
        {'code':'622922','title':'甘肃省临夏回族自治州康乐县'},
        {'code':'622923','title':'甘肃省临夏回族自治州永靖县'},
        {'code':'622924','title':'甘肃省临夏回族自治州广河县'},
        {'code':'622925','title':'甘肃省临夏回族自治州和政县'},
        {'code':'622926','title':'甘肃省临夏回族自治州东乡族自治县'},
        {'code':'622927','title':'甘肃省临夏回族自治州积石山保安族东乡族撒拉族自治县'},
        {'code':'623000','title':'甘肃省甘南藏族自治州'},
        {'code':'623001','title':'甘肃省甘南藏族自治州合作市'},
        {'code':'623021','title':'甘肃省甘南藏族自治州临潭县'},
        {'code':'623022','title':'甘肃省甘南藏族自治州卓尼县'},
        {'code':'623023','title':'甘肃省甘南藏族自治州舟曲县'},
        {'code':'623024','title':'甘肃省甘南藏族自治州迭部县'},
        {'code':'623025','title':'甘肃省甘南藏族自治州玛曲县'},
        {'code':'623026','title':'甘肃省甘南藏族自治州碌曲县'},
        {'code':'623027','title':'甘肃省甘南藏族自治州夏河县'},
        {'code':'630000','title':'青海省'},
        {'code':'630100','title':'青海省西宁市'},
        {'code':'630101','title':'青海省西宁市市辖区'},
        {'code':'630102','title':'青海省西宁市城东区'},
        {'code':'630103','title':'青海省西宁市城中区'},
        {'code':'630104','title':'青海省西宁市城西区'},
        {'code':'630105','title':'青海省西宁市城北区'},
        {'code':'630121','title':'青海省西宁市大通回族土族自治县'},
        {'code':'632100','title':'青海省海东地区'},
        {'code':'632121','title':'青海省海东地区西安县'},
        {'code':'632122','title':'青海省海东地区民和回族土族自治县'},
        {'code':'632123','title':'青海省海东地区乐都县'},
        {'code':'632124','title':'青海省海东地区湟中县'},
        {'code':'632125','title':'青海省海东地区湟源县'},
        {'code':'632126','title':'青海省海东地区互助土族自治县'},
        {'code':'632127','title':'青海省海东地区化隆回族自治县'},
        {'code':'632128','title':'青海省海东地区循化撒拉族自治县'},
        {'code':'632200','title':'青海省海北藏族自治州'},
        {'code':'632221','title':'青海省海北藏族自治州门源回族自治县'},
        {'code':'632222','title':'青海省海北藏族自治州祁连县'},
        {'code':'632223','title':'青海省海北藏族自治州海晏县'},
        {'code':'632224','title':'青海省海北藏族自治州刚察县'},
        {'code':'632300','title':'青海省黄南藏族自治州'},
        {'code':'632321','title':'青海省黄南藏族自治州同仁县'},
        {'code':'632322','title':'青海省黄南藏族自治州尖扎县'},
        {'code':'632323','title':'青海省黄南藏族自治州泽库县'},
        {'code':'632324','title':'青海省黄南藏族自治州河南蒙古族自治县'},
        {'code':'632500','title':'青海省海南藏族自治州'},
        {'code':'632521','title':'青海省海南藏族自治州共和县'},
        {'code':'632522','title':'青海省海南藏族自治州同德县'},
        {'code':'632523','title':'青海省海南藏族自治州贵德县'},
        {'code':'632524','title':'青海省海南藏族自治州兴海县'},
        {'code':'632525','title':'青海省海南藏族自治州贵南县'},
        {'code':'632600','title':'青海省果洛藏族自治州'},
        {'code':'632621','title':'青海省果洛藏族自治州玛沁县'},
        {'code':'632622','title':'青海省果洛藏族自治州班玛县'},
        {'code':'632623','title':'青海省果洛藏族自治州甘德县'},
        {'code':'632624','title':'青海省果洛藏族自治州达日县'},
        {'code':'632625','title':'青海省果洛藏族自治州久治县'},
        {'code':'632626','title':'青海省果洛藏族自治州玛多县'},
        {'code':'632700','title':'青海省玉树藏族自治州'},
        {'code':'632721','title':'青海省玉树藏族自治州玉树县'},
        {'code':'632722','title':'青海省玉树藏族自治州杂多县'},
        {'code':'632723','title':'青海省玉树藏族自治州称多县'},
        {'code':'632724','title':'青海省玉树藏族自治州治多县'},
        {'code':'632725','title':'青海省玉树藏族自治州囊谦县'},
        {'code':'632726','title':'青海省玉树藏族自治州曲麻莱县'},
        {'code':'632800','title':'青海省海西蒙古族藏族自治州'},
        {'code':'632801','title':'青海省海西蒙古族藏族自治州格尔木市'},
        {'code':'632802','title':'青海省海西蒙古族藏族自治州德令哈市'},
        {'code':'632821','title':'青海省海西蒙古族藏族自治州乌兰县'},
        {'code':'632822','title':'青海省海西蒙古族藏族自治州都兰县'},
        {'code':'632823','title':'青海省海西蒙古族藏族自治州天峻县'},
        {'code':'640000','title':'宁夏回族自治区'},
        {'code':'640100','title':'宁夏回族自治区银川市'},
        {'code':'640101','title':'宁夏回族自治区银川市市辖区'},
        {'code':'640102','title':'宁夏回族自治区银川市城区'},
        {'code':'640103','title':'宁夏回族自治区银川市新城区'},
        {'code':'640111','title':'宁夏回族自治区银川市郊区'},
        {'code':'640121','title':'宁夏回族自治区银川市永宁县'},
        {'code':'640122','title':'宁夏回族自治区银川市贺兰县'},
        {'code':'640200','title':'宁夏回族自治区石嘴山市'},
        {'code':'640201','title':'宁夏回族自治区石嘴山市市辖区'},
        {'code':'640202','title':'宁夏回族自治区石嘴山市大武口区'},
        {'code':'640203','title':'宁夏回族自治区石嘴山市石嘴山区'},
        {'code':'640204','title':'宁夏回族自治区石嘴山市石炭井区'},
        {'code':'640221','title':'宁夏回族自治区石嘴山市西罗县'},
        {'code':'640222','title':'宁夏回族自治区石嘴山市陶乐县'},
        {'code':'640223','title':'宁夏回族自治区石嘴山市惠农县'},
        {'code':'640300','title':'宁夏回族自治区吴忠市'},
        {'code':'640301','title':'宁夏回族自治区吴忠市市辖区'},
        {'code':'640302','title':'宁夏回族自治区吴忠市利通区'},
        {'code':'640321','title':'宁夏回族自治区吴忠市中卫县'},
        {'code':'640322','title':'宁夏回族自治区吴忠市中宁县'},
        {'code':'640323','title':'宁夏回族自治区吴忠市盐池县'},
        {'code':'640324','title':'宁夏回族自治区吴忠市同心县'},
        {'code':'640381','title':'宁夏回族自治区吴忠市青铜峡市'},
        {'code':'640382','title':'宁夏回族自治区吴忠市灵武市'},
        {'code':'642200','title':'宁夏回族自治区固原地区'},
        {'code':'642221','title':'宁夏回族自治区固原地区固原县'},
        {'code':'642222','title':'宁夏回族自治区固原地区海原县'},
        {'code':'642223','title':'宁夏回族自治区固原地区西吉县'},
        {'code':'642224','title':'宁夏回族自治区固原地区隆德县'},
        {'code':'642225','title':'宁夏回族自治区固原地区泾源县'},
        {'code':'642226','title':'宁夏回族自治区固原地区彭阳县'},
        {'code':'650000','title':'新疆维吾尔自治区'},
        {'code':'650100','title':'新疆维吾尔自治区乌鲁木齐市'},
        {'code':'650101','title':'新疆维吾尔自治区乌鲁木齐市市辖区'},
        {'code':'650102','title':'新疆维吾尔自治区乌鲁木齐市天山区'},
        {'code':'650103','title':'新疆维吾尔自治区乌鲁木齐市沙依巴克区'},
        {'code':'650104','title':'新疆维吾尔自治区乌鲁木齐市新市区'},
        {'code':'650105','title':'新疆维吾尔自治区乌鲁木齐市水磨沟区'},
        {'code':'650106','title':'新疆维吾尔自治区乌鲁木齐市头屯河区'},
        {'code':'650107','title':'新疆维吾尔自治区乌鲁木齐市南山矿区'},
        {'code':'650108','title':'新疆维吾尔自治区乌鲁木齐市东山区'},
        {'code':'650121','title':'新疆维吾尔自治区乌鲁木齐市乌鲁木齐县'},
        {'code':'650200','title':'新疆维吾尔自治区克拉玛依市'},
        {'code':'650201','title':'新疆维吾尔自治区克拉玛依市市辖区'},
        {'code':'650202','title':'新疆维吾尔自治区克拉玛依市独山子区'},
        {'code':'650203','title':'新疆维吾尔自治区克拉玛依市克拉玛依区'},
        {'code':'650204','title':'新疆维吾尔自治区克拉玛依市白碱滩区'},
        {'code':'650205','title':'新疆维吾尔自治区克拉玛依市乌尔禾区'},
        {'code':'652100','title':'新疆维吾尔自治区吐鲁番地区'},
        {'code':'652101','title':'新疆维吾尔自治区吐鲁番地区吐鲁番市'},
        {'code':'652122','title':'新疆维吾尔自治区吐鲁番地区鄯善县'},
        {'code':'652123','title':'新疆维吾尔自治区吐鲁番地区托克逊县'},
        {'code':'652200','title':'新疆维吾尔自治区哈密地区'},
        {'code':'652201','title':'新疆维吾尔自治区哈密地区哈密市'},
        {'code':'652222','title':'新疆维吾尔自治区哈密地区巴里坤哈萨克自治县'},
        {'code':'652223','title':'新疆维吾尔自治区哈密地区伊吾县'},
        {'code':'652300','title':'新疆维吾尔自治区昌吉回族自治州'},
        {'code':'652301','title':'新疆维吾尔自治区昌吉回族自治州昌吉市'},
        {'code':'652302','title':'新疆维吾尔自治区昌吉回族自治州阜康市'},
        {'code':'652303','title':'新疆维吾尔自治区昌吉回族自治州米泉市'},
        {'code':'652323','title':'新疆维吾尔自治区昌吉回族自治州呼图壁县'},
        {'code':'652324','title':'新疆维吾尔自治区昌吉回族自治州玛纳斯县'},
        {'code':'652325','title':'新疆维吾尔自治区昌吉回族自治州奇台县'},
        {'code':'652327','title':'新疆维吾尔自治区昌吉回族自治州吉木萨尔县'},
        {'code':'652328','title':'新疆维吾尔自治区昌吉回族自治州木垒哈萨克自治县'},
        {'code':'652700','title':'新疆维吾尔自治区博尔塔拉蒙古自治州'},
        {'code':'652701','title':'新疆维吾尔自治区博尔塔拉蒙古自治州博乐市'},
        {'code':'652722','title':'新疆维吾尔自治区博尔塔拉蒙古自治州精河县'},
        {'code':'652723','title':'新疆维吾尔自治区博尔塔拉蒙古自治州温泉县'},
        {'code':'652800','title':'新疆维吾尔自治区巴音郭楞蒙古自治州'},
        {'code':'652801','title':'新疆维吾尔自治区巴音郭楞蒙古自治州库尔勒市'},
        {'code':'652822','title':'新疆维吾尔自治区巴音郭楞蒙古自治州轮台县'},
        {'code':'652823','title':'新疆维吾尔自治区巴音郭楞蒙古自治州尉犁县'},
        {'code':'652824','title':'新疆维吾尔自治区巴音郭楞蒙古自治州若羌县'},
        {'code':'652825','title':'新疆维吾尔自治区巴音郭楞蒙古自治州且末县'},
        {'code':'652826','title':'新疆维吾尔自治区巴音郭楞蒙古自治州焉耆回族自治县'},
        {'code':'652827','title':'新疆维吾尔自治区巴音郭楞蒙古自治州和静县'},
        {'code':'652828','title':'新疆维吾尔自治区巴音郭楞蒙古自治州和硕县'},
        {'code':'652829','title':'新疆维吾尔自治区巴音郭楞蒙古自治州博湖县'},
        {'code':'652900','title':'新疆维吾尔自治区阿克苏地区'},
        {'code':'652901','title':'新疆维吾尔自治区阿克苏地区阿克苏市'},
        {'code':'652922','title':'新疆维吾尔自治区阿克苏地区温宿县'},
        {'code':'652923','title':'新疆维吾尔自治区阿克苏地区库车县'},
        {'code':'652924','title':'新疆维吾尔自治区阿克苏地区沙雅县'},
        {'code':'652925','title':'新疆维吾尔自治区阿克苏地区新和县'},
        {'code':'652926','title':'新疆维吾尔自治区阿克苏地区拜城县'},
        {'code':'652927','title':'新疆维吾尔自治区阿克苏地区乌什县'},
        {'code':'652928','title':'新疆维吾尔自治区阿克苏地区阿瓦提县'},
        {'code':'652929','title':'新疆维吾尔自治区阿克苏地区柯坪县'},
        {'code':'653000','title':'新疆维吾尔自治区克孜勒苏柯尔克孜自治州'},
        {'code':'653001','title':'新疆维吾尔自治区克孜勒苏柯尔克孜自治州阿图什市'},
        {'code':'653022','title':'新疆维吾尔自治区克孜勒苏柯尔克孜自治州阿克陶县'},
        {'code':'653023','title':'新疆维吾尔自治区克孜勒苏柯尔克孜自治州阿合奇县'},
        {'code':'653024','title':'新疆维吾尔自治区克孜勒苏柯尔克孜自治州乌恰县'},
        {'code':'653100','title':'新疆维吾尔自治区喀什地区'},
        {'code':'653101','title':'新疆维吾尔自治区喀什地区喀什市'},
        {'code':'653121','title':'新疆维吾尔自治区喀什地区疏附县'},
        {'code':'653122','title':'新疆维吾尔自治区喀什地区疏勒县'},
        {'code':'653123','title':'新疆维吾尔自治区喀什地区英吉沙县'},
        {'code':'653124','title':'新疆维吾尔自治区喀什地区泽普县'},
        {'code':'653125','title':'新疆维吾尔自治区喀什地区莎车县'},
        {'code':'653126','title':'新疆维吾尔自治区喀什地区叶城县'},
        {'code':'653127','title':'新疆维吾尔自治区喀什地区麦盖提县'},
        {'code':'653128','title':'新疆维吾尔自治区喀什地区岳普湖县'},
        {'code':'653129','title':'新疆维吾尔自治区喀什地区伽师县'},
        {'code':'653130','title':'新疆维吾尔自治区喀什地区巴楚县'},
        {'code':'653131','title':'新疆维吾尔自治区喀什地区塔什库尔干塔吉克自治县'},
        {'code':'653200','title':'新疆维吾尔自治区和田地区'},
        {'code':'653201','title':'新疆维吾尔自治区和田地区和田市'},
        {'code':'653221','title':'新疆维吾尔自治区和田地区和田县'},
        {'code':'653222','title':'新疆维吾尔自治区和田地区墨玉县'},
        {'code':'653223','title':'新疆维吾尔自治区和田地区皮山县'},
        {'code':'653224','title':'新疆维吾尔自治区和田地区洛浦县'},
        {'code':'653225','title':'新疆维吾尔自治区和田地区策勒县'},
        {'code':'653226','title':'新疆维吾尔自治区和田地区于田县'},
        {'code':'653227','title':'新疆维吾尔自治区和田地区民丰县'},
        {'code':'654000','title':'新疆维吾尔自治区伊犁哈萨克自治州'},
        {'code':'654001','title':'新疆维吾尔自治区伊犁哈萨克自治州奎屯市'},
        {'code':'654100','title':'新疆维吾尔自治区伊犁哈萨克自治州伊犁地区'},
        {'code':'654101','title':'新疆维吾尔自治区伊犁哈萨克自治州伊宁市'},
        {'code':'654121','title':'新疆维吾尔自治区伊犁哈萨克自治州伊宁县'},
        {'code':'654122','title':'新疆自治区伊犁哈萨克自治州察布查尔锡伯自治县'},
        {'code':'654123','title':'新疆维吾尔自治区伊犁哈萨克自治州霍城县'},
        {'code':'654124','title':'新疆维吾尔自治区伊犁哈萨克自治州巩留县'},
        {'code':'654125','title':'新疆维吾尔自治区伊犁哈萨克自治州新源县'},
        {'code':'654126','title':'新疆维吾尔自治区伊犁哈萨克自治州昭苏县'},
        {'code':'654127','title':'新疆维吾尔自治区伊犁哈萨克自治州特克斯县'},
        {'code':'654128','title':'新疆维吾尔自治区伊犁哈萨克自治州尼勒克县'},
        {'code':'654200','title':'新疆维吾尔自治区塔城地区'},
        {'code':'654201','title':'新疆维吾尔自治区塔城地区塔城市'},
        {'code':'654202','title':'新疆维吾尔自治区塔城地区乌苏市'},
        {'code':'654221','title':'新疆维吾尔自治区塔城地区额敏县'},
        {'code':'654223','title':'新疆维吾尔自治区塔城地区沙湾县'},
        {'code':'654224','title':'新疆维吾尔自治区塔城地区托里县'},
        {'code':'654225','title':'新疆维吾尔自治区塔城地区裕民县'},
        {'code':'654226','title':'新疆维吾尔自治区塔城地区和布克赛尔蒙古自治县'},
        {'code':'654300','title':'新疆维吾尔自治区阿勒泰地区'},
        {'code':'654301','title':'新疆维吾尔自治区阿勒泰地区阿勒泰市'},
        {'code':'654321','title':'新疆维吾尔自治区阿勒泰地区布尔津县'},
        {'code':'654322','title':'新疆维吾尔自治区阿勒泰地区富蕴县'},
        {'code':'654323','title':'新疆维吾尔自治区阿勒泰地区福海县'},
        {'code':'654324','title':'新疆维吾尔自治区阿勒泰地区哈巴河县'},
        {'code':'654325','title':'新疆维吾尔自治区阿勒泰地区青河县'},
        {'code':'654326','title':'新疆维吾尔自治区阿勒泰地区吉木乃县'},
        {'code':'659000','title':'新疆维吾尔自治区直辖县级行政单位'},
        {'code':'659001','title':'新疆维吾尔自治区石河子市'}];

        return nativePlace.filter(x=>x.code==code)[0]?.title; 
    },
}

export default idCard;